import { useNavigate } from "react-router-dom";
import { IMAGES } from "../assets/images";

export const PageNotFoundView = () => {
  const navigate = useNavigate();

  return (
    <div className="loginBg">
      <div className="container-fluid">
        <div className="align-self-center pad-0 form-section">
          <div className="mainLoginbox text-center pageNotFoundBox">
            <img src={IMAGES.PageNotFoundImg} alt="" />
            <h1>Error 404</h1>
            <h6>Page Not Found</h6>
            <button
              className="formButton"
              onClick={() => navigate("/projects")}
            >
              Go Back Dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
