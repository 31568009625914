const BaseUrl = process.env.REACT_APP_BASE_URL + "/";

export const AuthApiEndpoints = {
  AUTH_LOGIN_API: BaseUrl + "organization/login",
  AUTH_SIGNUP_API: BaseUrl + "organization/registration",
  PROFILE_UPDATE: BaseUrl + "organization/update",
  AUTH_CHECK_ORG_CODE: BaseUrl + "organization/checkAccessCode",
  GET_CATEGORY_LIST: BaseUrl + "organization/categoryFetch",
  OTP_VERIFY: BaseUrl + "organization/verifyOtp",
  RESET_OTP: BaseUrl + "organization/sendOtp",
  FORGOT_RESET_PASSWORD: BaseUrl + "organization/forgetPassword",
  CHANGE_PASSWORD_API: BaseUrl + "organization/changePassword",
  CONTACT_US: BaseUrl + "organization/contactUsAdd",
  GET_EXPORT_PROJECTS_API: BaseUrl + "organization/getProjectIdsDetails/",
  REFRESH_TOKEN_API:BaseUrl+"organization/refreshToken",
  GET_PROFILE:BaseUrl+"organization/getDetails",
  INTERESTED_CUSTOMER:BaseUrl+"organization/interestedCustomerEmailAdd",
};

export const ProjectApiEndpoints = {
  GET_TYPES_BY_RECORD_TYPES: BaseUrl + "project/types",
  ADD_PROJECT_API: BaseUrl + "project/add",
  UPDATE_PROJECT_API: BaseUrl + "project/update",
  DELETE_PROJECT_API: BaseUrl + "project/delete/",
  GET_PROJECT_LIST_API: BaseUrl + "project/ListingForWeb",
};
export const DomainApiEndpoints = {
  GET_DOMAIN_LIST_API: BaseUrl + "organization/typeFetch/",
  ADD_DOMAIN_API: BaseUrl + "organization/typeAdd",
  DELETE_DOMAIN_API: BaseUrl + "organization/typeDelete/",
};
export const PlatformApiEndpoints = {
  GET_PLATFORM_LIST_API: BaseUrl + "organization/typeFetch/",
  ADD_PLATFORM_API: BaseUrl + "organization/typeAdd",
  DELETE_PLATFORM_API: BaseUrl + "organization/typeDelete/",
};
export const TechnologyApiEndpoints = {
  GET_TECHNOLOGY_LIST_API: BaseUrl + "organization/typeFetch/",
  ADD_TECHNOLOGY_API: BaseUrl + "organization/typeAdd",
  DELETE_TECHNOLOGY_API: BaseUrl + "organization/typeDelete/",
};

export const SubscriptionApiEndpoints = {
  GET_SUBSCRIPTIONS_API: BaseUrl + "organization/subscriptionGet",
  GET_CURRENT_SUBSCRIPTIONS_API: BaseUrl + "organization/currentSubscription",
  GET_SUB_PAYMENT_ID_API: BaseUrl + "organization/createPaymentSubscriptionId",
  SEND_PAYMENT_OBJECT_API: BaseUrl + "organization/savePaymentObject",
  CANCEL_SUBSCRIPTION_API: BaseUrl + "",
};

export const razorpayCheckoutScript =
  "https://checkout.razorpay.com/v1/checkout.js";

export const AWSCredentialsApiEndpoint = {
  GET_CREDENTIALS_API: BaseUrl + "organization/configKey",
};

export const PortfolioApiEndpoints={
  GET_PORTFOLIO_LIST_API: BaseUrl + "organization/getSharedPortfolioLinks",
  DELETE_PORTFOLIO:BaseUrl+"organization/getSharedPortfolioLinkDelete/",
  UPDATE_PORTFOLIO:BaseUrl+"organization/getSharedPortfolioLinkUpdate",

}
export const ratingCustomerCountEndpoints={
  GET_RATING_CUSTOMER_COUNT_API :BaseUrl+"organization/ratingAndCustomerCount",
}

export const UsersApiEndpoints = {
  GET_USERS_LIST_API: BaseUrl + "organization/ListInviteUser",
  ADD_USER_API: BaseUrl + "organization/addEditInviteUser",
  DELETE_USER_API: BaseUrl + "organization/deleteInviteUser/",
};
