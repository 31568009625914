import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
export const Switch = ({ id, checked, onChange }) => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id="button-tooltip-2">
          {checked === true ? "Inactive" : "Active"}
        </Tooltip>
      }
    >
      <div className="switchTogle">
        <input type="checkbox" id={id} checked={checked} onClick={onChange} />
        <label htmlFor={id}>Toggle</label>
      </div>
    </OverlayTrigger>
  );
};
