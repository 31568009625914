import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toastr } from "react-redux-toastr";
import { useDropzone } from "react-dropzone";
import * as Yup from "yup";
import loadImage from "blueimp-load-image";
import { HeaderView } from "../components/Header";
import {
  Label,
  Error,
  ButtonLoader,
  ModalLayout,
  SidebarView,
  DomainDropdown,
  PlatformDropdown,
  TechnologyDropdown,
  Thumbnails,
  CountryDropdown,
  DurationDropdown,
  TeamSizeDropdown,
} from "../components";
import {
  FolderTypes,
  capitalizeFirstLetter,
  capitalizeFirstLetterEachWord,
  extractKeyNameFromUrl,
  mbToBytes,
  timestamp,
  trimValue,
  urlRegex,
  videoFormatValidation,
} from "../utils/helper";

import { SVGIMAGES } from "../assets/svgImages";
import { IMAGES } from "../assets/images";
import { updateProject } from "../store/actions/projectActions";
import { SimpleSliderView } from "../components/Slider";
import {
  deleteFile,
  fileUpload,
  multiFileUpload,
  profileUpload,
  videoFileUpload,
} from "../utils/S3-helper";
import { UplaodProgress } from "../components/ProgressBar";
import ImageCrop from "../components/ImageCrop";
const isWindowsOrLinux = /Windows|Linux/.test(navigator.userAgent);
export const EditProjectView = () => {
  const CarouselRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const collapseSidebar = useSelector(({ auth }) => auth?.collapseSidebar);
  const projectDetail = useSelector(
    ({ projects }) => projects?.selectedProject
  );
  const domainTypes = useSelector(({ projects }) => projects?.domainTypes);
  const platformTypes = useSelector(({ projects }) => projects?.platformTypes);
  const subscriptions = useSelector(
    ({ subscription }) => subscription?.subscriptions
  );
  const currentSubscription = useSelector(
    ({ subscription }) => subscription?.currentSubscription
  );

  const technologyTypes = useSelector(
    ({ projects }) => projects?.technologyTypes
  );

  const [viewImgModal, setViewImgModal] = useState(false);
  const [selectedDomainType, setSelectedDomainType] = useState({});
  const [selectedPlatformType, setSelectedPlatformType] = useState([]);
  const [selectedPlatformData, setSelectedPlatformData] = useState([]);
  const [selectedTechnologyType, setSelectedTechnologyType] = useState([]);
  const [videoView, setVideoView] = useState(false);
  const [newTechnologyTypes, setNewTechnologyTypes] = useState([]);
  const [newPlatformTypes, setNewPlatformTypes] = useState([]);
  const [newDomainTypes, setNewDomainTypes] = useState([]);
  const [selectedScreenShots, setSelectedScreenShots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [videoURL, setVideoURL] = useState(null);
  const [src, setSrc] = useState();
  const [showCropModal, setShowCropModal] = useState(false);

  const projectPlatform = projectDetail?.platformId?.map((platfrom) =>
    platfrom?.title?.toLowerCase()
  );

  const [clientCountry, setClientCountry] = useState("");
  const [clientSelectedCountry, setClientSelectedCountry] = useState("");

  const [showVideoProgressBar, setShowVideoProgressBar] = useState(false);
  const [videoProgressPercentage, setVideoProgressPercentage] = useState(0);
  const [imageProgress, setImageProgress] = useState(0);
  const [showImageProgressBar, setShowImageProgressBar] = useState(false);
  const [editLogo,setEditLogo]=useState(false)

  const viewImage = (index) => {
    setSelectedImageIndex(index);
    setViewImgModal(true);
  };
  const getFeatureLimit = (featureType) => {
    const subscription = subscriptions.find(
      (sub) => sub?._id === currentSubscription?.subscriptionId
    );
    const feature = subscription?.features?.find(
      (feature) => feature.type === featureType
    );
    return feature ? parseInt(feature.limit) : 0;
  };
  const getSubscriptionName = () => {
    const subscription = subscriptions.find(
      (sub) => sub?._id === currentSubscription?.subscriptionId
    );
    return subscription ? subscription.name : "Unknown Subscription";
  };

  const currentPlanName = getSubscriptionName();
  const ScreenshotLimit = getFeatureLimit("screenShot");

  const VideoMBLimit = getFeatureLimit("videoSize");
  const imageSizeLimit = getFeatureLimit("ImageSize");
  const fileSizeLimit = mbToBytes(imageSizeLimit);
  const videoSizeLimit = mbToBytes(VideoMBLimit);
  const REQUIRED_MIN_WIDTH = 256;
  const REQUIRED_MIN_HEIGHT = 256;

  useEffect(() => {
    if (projectDetail) {
      Formik.setValues(projectDetail);

      setSelectedDomainType([projectDetail?.domainId[0]?._id]);
      const domainId = projectDetail?.domainId?.map((value) => value?._id);
      const platformId = projectDetail?.platformId?.map((value) => value?._id);
      const technologyId = projectDetail?.technologyId?.map(
        (value) => value?._id
      );
      Formik.setFieldValue("domainId", domainId);
      Formik.setFieldValue("technologyId", technologyId);
      Formik.setFieldValue("platformId", platformId);
      Formik.setFieldValue("id", projectDetail?._id);

      const plateFormIds = projectDetail?.platformId?.map((item) => item?._id);
      const technologyIds = projectDetail?.technologyId?.map(
        (item) => item?._id
      );
      setVideoURL(projectDetail?.videoLink);
      setSelectedPlatformType(plateFormIds);
      setSelectedTechnologyType(technologyIds);
      setSelectedScreenShots(projectDetail?.screenShots);
      setClientSelectedCountry(projectDetail?.clientCountry);
      Formik.setFieldValue("projectLogo", projectDetail?.logo);
      setSelectedPlatformData(projectPlatform);
      Formik.setFieldValue("platformType", projectPlatform);
    }
    // eslint-disable-next-line
  }, [projectDetail]);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: { "image/*": [] },
    minSize: 1,
    maxSize: fileSizeLimit,
    maxFiles: 20,
    noKeyboard: true,
    noClick: true,
    isLocal: true,
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length > 0) {
        const fileList = Array.from(acceptedFiles);

        if (selectedScreenShots.length + fileList.length > ScreenshotLimit) {
          toastr.error(
            "Error",
            `You can only add up to ${ScreenshotLimit} images`
          );
          return;
        }

        setSelectedScreenShots((prev) => {
          const newImageData = [...prev, ...fileList];

          Formik?.setFieldValue("projectImages", newImageData);
          return newImageData;
        });
      } else {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              toastr.error("Error", "Error: File is too large");
            }
            if (err.code === "file-too-small") {
              toastr.error("Error", "Error: File is too small");
            }
          });
        });
      }
    },
  });

  const dropzone = useDropzone({
    accept: { "image/*": [] },
    minSize: 1,
    maxSize: fileSizeLimit, // 5 MB
    maxFiles: 1,
    noKeyboard: true,
    noClick: true,
    onDrop: async (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setSrc(file);
        setShowCropModal(true);
        // loadImage(
        //   file,
        //   (img, data) => {
        //     if (data) {
        //       const { originalWidth, originalHeight } = data;

        //       if (
        //         originalWidth >= REQUIRED_MIN_WIDTH &&
        //         originalHeight >= REQUIRED_MIN_HEIGHT
        //       ) {
        //         if (originalWidth === originalHeight) {
        //           // Image meets the size and aspect ratio requirements
        //           Formik.setFieldValue("projectLogo", file);
        //         } else {
        //           toastr.error(
        //             "Error",
        //             `If the logo exceeds ${REQUIRED_MIN_WIDTH}x${REQUIRED_MIN_HEIGHT}, it must have equal width and height.`
        //           );
        //         }
        //       } else {
        //         toastr.error(
        //           "Error",
        //           `Logo must be at least ${REQUIRED_MIN_WIDTH}x${REQUIRED_MIN_HEIGHT} pixels.`
        //         );
        //       }
        //     }
        //   },
        //   { orientation: true, meta: true }
        // );
      } else {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              toastr.error("Error", `File is too large`);
            }
            if (err.code === "file-too-small") {
              toastr.error("Error", `No file selected`);
            }
          });
        });
      }
    },
  });

  const handleRemove = (index) => {
    const newImageData = selectedScreenShots.filter((_, i) => i !== index);
    setSelectedScreenShots(newImageData);
    if (newImageData.length === 0) {
      Formik?.setFieldValue("screenShots", []);
      Formik?.setFieldValue("projectImages", []);
    } else {
      const formikField = newImageData.every((item) => typeof item === "string")
        ? "screenShots"
        : "projectImages";
      Formik?.setFieldValue(formikField, newImageData);
    }
  };

  const Videodropzone = useDropzone({
    accept: { "video/*": [] },
    minSize: 1,
    maxSize: videoSizeLimit, //100 mb
    maxFiles: 1,
    noKeyboard: true,
    noClick: true,
    onDrop: async (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length > 0) {
        const fileList = Array.from(acceptedFiles);

        Formik.setFieldValue("projectVideo", fileList[0]);
        Formik.setFieldValue("videoLink", fileList[0].name);
        setVideoURL(URL.createObjectURL(fileList[0]));
      } else {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              toastr.error("Error", `Error: File is Large`);
            }
            if (err.code === "file-too-small") {
              toastr.error("Error", `No File Selected`);
            }
          });
        });
      }
    },
  });

  const reorderImages = (newOrder) => {
    setSelectedScreenShots(newOrder);
  };

  const onVideoProgress = (progress) => {
    setShowVideoProgressBar(true);
    // console.log(`Upload progress: ${progress}%`);
    setVideoProgressPercentage(progress);
  };

  const onImageProgress = (progress) => {
    setShowImageProgressBar(true);
    // console.log(`Image upload progress: ${progress}%`);
    setImageProgress(progress);
  };
  let overallImageProgress = 0;
  let overallVideoProgress = 0;

  const updateImageProgress = async (fileProgress, totalFiles) => {
    overallImageProgress += fileProgress;
    const overallPercentage = Math.round(
      (overallImageProgress / totalFiles) * 100
    );
    onImageProgress(overallPercentage);
  };

  const updateVideoProgress = (fileProgress, totalFiles) => {
    overallVideoProgress += fileProgress;
    const overallPercentage = Math.round(
      (overallVideoProgress / totalFiles) * 100
    );
    onVideoProgress(overallPercentage);
  };

  const Formik = useFormik({
    initialValues: {
      name: "",
      projectLogo: "",
      description: "",
      projectImages: [],
      domainId: "",
      domainDetails: [],
      website: "",
      platformId: [],
      platformDetails: [],
      platformType: [],
      technologyId: [],
      technologyDetails: [],
      duration: "",
      teamSize: "",
      iosAppStoreLink: "",
      androidPlayStoreLink: "",
      clientName: "",
      clientCountry: "",
      videoLink: "",
      projectVideo: null,
    },

    validationSchema: Yup.object({
      name: Yup.string()
      .trim()
      .min(2, "Name must be at least 2 characters long")
      .required("Name is required!"),
      projectLogo: Yup.mixed()
        .test("fileType", "Incorrect file type", (file) => {
          if (!file?.type) {
            return true;
          }
          return ["image/png", "image/jpg", "image/jpeg"].includes(file.type);
        })
        .required("Logo is required!"),
        description: Yup.string()
        .trim()
        .min(200, "Description must be at least 200 characters long")
        .required("Description is required!"),
      projectImages: Yup.array()
        .of(Yup.string())
        .min(1, "At least one image is required!"),
      domainId: Yup.array()
        .of(Yup.string())
        .test("domainValidation", "Domain is required!", function (value) {
          const { domainDetails } = this.parent;
          return (
            (value && value.length > 0) ||
            (domainDetails && domainDetails.length > 0)
          );
        }),
      domainDetails: Yup.array()
        .of(
          Yup.object({
            title: Yup.string().required("Title is required"),
            remark: Yup.string(),
          })
        )
        .test("domainValidation", "Domain is required!", function (value) {
          const { domainId } = this.parent;
          return (
            (value && value.length > 0) || (domainId && domainId.length > 0)
          );
        }),
      website: Yup.string().trim().matches(urlRegex, "Invalid URL!"),
      platformId: Yup.array()
        .of(Yup.string())
        .test("platformValidation", "Platform is required!", function (value) {
          const { platformDetails } = this.parent;
          return (
            (value && value.length > 0) ||
            (platformDetails && platformDetails.length > 0)
          );
        }),
      platformDetails: Yup.array()
        .of(
          Yup.object({
            title: Yup.string().required("Title is required"),
            remark: Yup.string(),
          })
        )
        .test("platformValidation", "Platform is required!", function (value) {
          const { platformId } = this.parent;
          return (
            (value && value.length > 0) || (platformId && platformId.length > 0)
          );
        }),
      technologyId: Yup.array()
        .of(Yup.string())
        .test(
          "technologyValidation",
          "Technology is required!",
          function (value) {
            const { technologyDetails } = this.parent;
            return (
              (value && value.length > 0) ||
              (technologyDetails && technologyDetails.length > 0)
            );
          }
        ),
      technologyDetails: Yup.array()
        .of(
          Yup.object({
            title: Yup.string().required("Title is required"),
            remark: Yup.string(),
          })
        )
        .test(
          "technologyValidation",
          "Technology is required!",
          function (value) {
            const { technologyId } = this.parent;
            return (
              (value && value.length > 0) ||
              (technologyId && technologyId.length > 0)
            );
          }
        ),
      duration: Yup.string().trim().required("Duration is required!"),
      teamSize: Yup.string().trim().required("Team size is required!"),
      iosAppStoreLink: Yup.string().trim().matches(urlRegex, "Invalid URL!"),
      androidPlayStoreLink: Yup.string()
        .trim()
        .matches(urlRegex, "Invalid URL!"),
      clientName: Yup.string().trim(),
      clientCountry: Yup.string().trim(),
      videoLink: videoFormatValidation,
      projectVideo: videoFormatValidation,
    }),
    onSubmit: async (values, { setSubmitting }) => {
      await Formik.validateForm();
      if (Formik.isValid && Formik.dirty) {
        setIsLoading(true);

        try {
          const {
            domainId,
            platformId,
            technologyId,
            projectLogo,
            videoLink,
            projectVideo,
            projectImages,
            platformType,
            domainDetails,
            ...otherValues
          } = values;
          const newValues = { ...otherValues };

          // Clean up unnecessary values
          const keysToDelete = [
            "createdDate",
            "deleted",
            "__v",
            "_id",
            "updatedDate",
            "deletedAt",
            "logo",
            "screenShots",
            "platformType",
          ];
          keysToDelete.forEach((key) => delete newValues[key]);

          let params = { ...newValues };

          const technologyDetails =
            newTechnologyTypes?.length > 0 ? newTechnologyTypes : null;
          const platformDetails =
            newPlatformTypes?.length > 0 ? newPlatformTypes : null;
            const randomNum = Math.floor(Math.random() * 1e6);
            const extension = '.png'
          if (projectLogo && editLogo===true) {
            const keyName = `${FolderTypes.logo + timestamp}_${
              randomNum+extension
            }`;
          
            const res = await profileUpload(keyName, projectLogo);
            if (res) params.logo = res;
          }

          const videoFiles = 1;

          if (projectVideo && typeof projectVideo === "object") {
            setShowVideoProgressBar(true);
            const keyName = `${FolderTypes.videos + timestamp}_${
              projectVideo?.name
            }`;
            const res = await videoFileUpload(
              keyName,
              projectVideo,
              onVideoProgress,
              updateVideoProgress,
              videoFiles
            );
            if (res) params.videoLink = res;
          }

          if (!projectVideo && videoLink) {
            params.videoLink = videoLink;
          }

          // Handle new project images and merge with existing screenshots
          let existingScreenshots = params.screenShots || [];
          if (selectedScreenShots && Array.isArray(selectedScreenShots)) {
            const newProjectImages = selectedScreenShots.filter(
              (file) => typeof file === "object"
            );
            const existingImages = selectedScreenShots.filter(
              (file) => typeof file === "string"
            );
            const totalFiles = newProjectImages?.length;

            if (newProjectImages.length > 0) {
              setShowImageProgressBar(true);
              const uploadedImages = await Promise.all(
                newProjectImages.map(async (file) => {
                  const keyName = `${FolderTypes.projectImages + timestamp}_${
                    file?.name
                  }`;
                  return await multiFileUpload(
                    keyName,
                    file,
                    onImageProgress,
                    updateImageProgress,
                    totalFiles
                  );
                })
              );
              existingScreenshots = [...existingImages, ...uploadedImages];
            } else {
              existingScreenshots = existingImages;
            }

            params.screenShots = existingScreenshots;
          }

          if (domainId?.length > 0) {
            params.domainId = domainId;
          }

          if (platformId?.length > 0) {
            params.platformId = platformId;
          }

          if (technologyId?.length > 0) {
            params.technologyId = technologyId;
          }

          if (technologyDetails?.length > 0) {
            params.technologyDetails = technologyDetails;
          }
          if (platformDetails?.length > 0) {
            params.platformDetails = platformDetails;
          }
          if (domainDetails?.length > 0) {
            params.domainDetails = domainDetails;
          }

          // Remove empty links
          if (!otherValues.iosAppStoreLink) {
            delete params.iosAppStoreLink;
          }
          if (!otherValues.androidPlayStoreLink) {
            delete params.androidPlayStoreLink;
          }

          // Delete media logic start

          // Prepare lists of new and old media
          const oldAssets = {
            logo: projectDetail?.logo,
            videoLink: projectDetail?.videoLink,
            screenShots: projectDetail?.screenShots || [],
          };
          const newAssets = {
            logo: projectLogo,
            videoLink: projectVideo ? projectVideo : videoLink,
            screenShots: selectedScreenShots || [],
          };

          console.log("oldAssets", oldAssets);
          console.log("newAssets", newAssets);
          // Determine which assets need to be deleted
          const assetsToDelete = [];

          if (oldAssets.logo && oldAssets.logo !== newAssets.logo) {
            const logoKeyName = extractKeyNameFromUrl(oldAssets.logo);
            assetsToDelete.push(logoKeyName);
          }

          if (
            oldAssets.videoLink &&
            oldAssets.videoLink !== newAssets.videoLink
          ) {
            const videoKeyName = extractKeyNameFromUrl(oldAssets.videoLink);
            assetsToDelete.push(videoKeyName);
          }

          oldAssets.screenShots.forEach((url) => {
            if (!newAssets.screenShots.includes(url)) {
              const screenShotKeyName = extractKeyNameFromUrl(url);
              assetsToDelete.push(screenShotKeyName);
            }
          });

          // console.log('assetsToDelete', assetsToDelete);
          // Remove assets from AWS server
          for (const keyName of assetsToDelete) {
            try {
              await deleteFile(keyName);
            } catch (error) {
              console.error(`Error deleting asset ${keyName}:`, error);
            }
          }
          // Delete media logic end

          await dispatch(updateProject(params)).then((res) => {
            if (res?.status === 200 && res?.data?.success) {
              toastr.success("Success", res?.data?.message);
              navigate(-1);
            } else {
              toastr.error("Error", res?.data?.message);
            }
          });
        } catch (error) {
          setIsLoading(false);
          console.log("error", error);
          toastr.error("Error", error.message);
        } finally {
          setIsLoading(false);
        }
      }
      setSubmitting(false);
    },
  });

  
  const getCropImage = (file) => {
    Formik.setFieldValue("projectLogo", file);
    setShowCropModal(false);
    setEditLogo(true)
  };

  const closeShowModal = () => {
    setShowCropModal(false);
  };



  return (
    <>
      <div className={collapseSidebar ? "mainBody sortingMain" : "mainBody"}>
        <HeaderView />
        <div className="wrapper">
          <SidebarView />
          <div className="main-container">
            <div className="content">
              <div className="row mb-3">
                <div className="col-xxl-12">
                  <div className="form-body">
                    <div className="row">
                      <div className="col-xxl-12">
                        <div className="greetingsText mb-5">
                          <div className="greetingsText-heading">
                            <nav aria-label="breadcrumb">
                              <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                  <button onClick={() => navigate("/projects")}>
                                    Project
                                  </button>
                                </li>
                                <li
                                  className="breadcrumb-item active"
                                  aria-current="page"
                                >
                                  Edit
                                </li>
                              </ol>
                            </nav>
                            <h3>Project Details</h3>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-2 ">
                        <div className="addProjectlogo">
                          <div className="upload-img-box">
                            <div className="circle">
                              <img
                                src={
                                  Formik?.values?.projectLogo
                                    ? typeof Formik?.values?.projectLogo ===
                                      "object"
                                      ? URL.createObjectURL(
                                          Formik?.values?.projectLogo
                                        )
                                      : Formik?.values?.projectLogo
                                    : IMAGES.UploadLogo
                                }
                                alt=""
                              />
                            </div>
                            <div className="p-image ml-auto">
                              <label htmlFor="logoSelect">
                                <div>
                                  <img src={SVGIMAGES.EditIcon} alt="" />
                                </div>
                              </label>
                              <input
                                disabled={isLoading}
                                {...dropzone.getInputProps({
                                  className: "file-upload",
                                  id: "logoSelect",
                                  accept: "image/*",
                                  onClick: !isWindowsOrLinux ? () => dropzone.open() : undefined,
                                })}
                                accept= "image/*"
                                className="file-upload"
                                id="logoSelect"
                                name="projectLogo"
                                type="file"
                                onBlur={Formik.handleBlur}
                              />
                            </div>
                          </div>

                          <Label
                            disabled={isLoading}
                            label={"Project Logo"}
                            htmlFor={"projectLogo"}
                            required
                          />
                          {Formik.touched.logo && Formik.errors.logo && (
                            <Error error={"Required!"} />
                          )}
                        </div>
                      </div>
                      <div className="col-xxl-10">
                        <form
                          className="row g-3"
                          onSubmit={Formik.handleSubmit}
                        >
                          <div className="col-lg-4 col-md-6">
                            <Label label={"Name"} htmlFor={"name"} required />
                            <input
                              placeholder="Enter Name"
                              type="text"
                              className="custom-input-field"
                              id="name"
                              name="name"
                              onChange={(e) => {
                                const capitalizedValue = capitalizeFirstLetter(
                                  e.target.value
                                );
                                Formik.setFieldValue("name", capitalizedValue);
                              }}
                              value={Formik.values.name}
                              onBlur={(e) => {
                                Formik.handleBlur(e);
                                Formik.setFieldValue(
                                  "name",
                                  capitalizeFirstLetter(trimValue(e.target.value))
                                );
                              }}
                              maxLength={60}
                            />
                            {Formik.touched.name && Formik.errors.name && (
                              <Error error={Formik.errors.name} />
                            )}
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <Label
                              label={"Domain"}
                              htmlFor={"domainId"}
                              required
                            />

                            <DomainDropdown
                              Formik={Formik}
                              domainTypes={domainTypes}
                              setNewDomainTypes={setNewDomainTypes}
                              sortedData={selectedDomainType}
                              setSortedData={setSelectedDomainType}
                            />

                            {Formik.touched.domainId &&
                              Formik.values.domainId?.length < 1 &&
                              newDomainTypes?.length < 1 && (
                                <Error error={Formik.errors.domainId} />
                              )}
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <Label
                              label={"Platform"}
                              htmlFor={"platformId"}
                              required
                            />

                            <PlatformDropdown
                              Formik={Formik}
                              platformTypes={platformTypes}
                              sortedData={selectedPlatformType}
                              setSortedData={setSelectedPlatformType}
                              setSelectedPlatformData={setSelectedPlatformData}
                              setNewPlatformTypes={setNewPlatformTypes}
                            />
                            {Formik.touched.platformId &&
                              Formik.values.platformId?.length < 1 &&
                              newPlatformTypes?.length < 1 && (
                                <Error error={Formik.errors.platformId} />
                              )}
                          </div>

                          <div className="col-lg-4 col-md-6">
                            <Label
                              label={"Technology"}
                              htmlFor={"technologyId"}
                              required
                            />

                            <TechnologyDropdown
                              Formik={Formik}
                              technologyTypes={technologyTypes}
                              setNewTechnologyTypes={setNewTechnologyTypes}
                              sortedData={selectedTechnologyType}
                              setSortedData={setSelectedTechnologyType}
                            />

                            {Formik.touched.technologyId &&
                              Formik.values.technologyId?.length < 1 &&
                              newTechnologyTypes?.length < 1 && (
                                <Error error={Formik.errors.technologyId} />
                              )}
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <Label
                              label={"Duration"}
                              htmlFor={"duration"}
                              required
                            />
                            <DurationDropdown Formik={Formik} />

                            {Formik.touched.duration &&
                              Formik.errors.duration && (
                                <Error error={Formik.errors.duration} />
                              )}
                          </div>
                          {/* <div className="col-lg-4 col-md-6">
                            <Label
                              label={"Team Size"}
                              htmlFor={"teamSize"}
                              required
                            />
                            <input
                              type="text"
                              className="custom-input-field"
                              id="teamSize"
                              name="teamSize"
                              placeholder="Enter Team Size"
                              onChange={Formik.handleChange}
                              value={Formik.values.teamSize}
                              onBlur={Formik.handleBlur}
                            />
                            {Formik.touched.teamSize &&
                              Formik.errors.teamSize && (
                                <Error error={Formik.errors.teamSize} />
                              )}
                          </div> */}
                          <div className="col-lg-4 col-md-6">
                            <Label
                              label={"Team Size"}
                              htmlFor={"team-size"}
                              required
                            />
                            <TeamSizeDropdown Formik={Formik} />

                            {Formik.touched.teamSize &&
                              Formik.errors.teamSize && (
                                <Error error={Formik.errors.teamSize} />
                              )}
                          </div>

                          <div className="col-lg-4 col-md-6">
                            <Label
                              label={
                                <span>
                                  Client Name
                                  <small>(Optional)</small>
                                </span>
                              }
                              htmlFor={"clientName"}
                              className="custom-form-label-weight"
                            />
                            <input
                              type="text"
                              className="custom-input-field"
                              id="clientName"
                              name="clientName"
                              placeholder="Enter Name"
                               onChange={(e) => {
                                const capitalizedValue = capitalizeFirstLetterEachWord(
                                  e.target.value
                                );
                                Formik.setFieldValue('clientName', capitalizedValue);
                              }}
                              value={Formik.values.clientName}
                              onBlur={(e) => {
                                Formik.handleBlur(e);
                                Formik.setFieldValue(
                                  "clientName",
                                  capitalizeFirstLetterEachWord(trimValue(e.target.value))
                                );
                              }}
                              maxLength={60}
                            />
                            {Formik.touched.clientName &&
                              Formik.errors.clientName && (
                                <Error error={Formik.errors.clientName} />
                              )}
                          </div>

                          <div className="col-lg-4 col-md-6 customCountryDS">
                            <Label
                              label={
                                <span>
                                  Client Country
                                  <small>(Optional)</small>
                                </span>
                              }
                              htmlFor={"Country"}
                              className="custom-form-label-weight"
                            />
                            <CountryDropdown
                              Formik={Formik}
                              setClientCountry={setClientCountry}
                              clientCountry={clientCountry}
                              clientSelectedCountry={clientSelectedCountry}
                            />
                            {Formik.touched.clientCountry &&
                              Formik.errors.clientCountry && (
                                <Error error={Formik.errors.clientCountry} />
                              )}
                          </div>
                          {selectedPlatformData?.includes("web") && (
                            <div className="col-lg-4 col-md-6">
                              <Label
                                label={
                                  <span>
                                    Website
                                    <small>(Optional)</small>
                                  </span>
                                }
                                htmlFor={"website"}
                                className="custom-form-label-weight"
                              />
                              <input
                                type="text"
                                className="custom-input-field"
                                id="website"
                                name="website"
                                placeholder="Enter Website Link"
                                onChange={Formik.handleChange}
                                value={Formik.values.website}
                                onBlur={Formik.handleBlur}
                              />
                              {Formik.touched.website &&
                                Formik.errors.website && (
                                  <Error error={Formik.errors.website} />
                                )}
                            </div>
                          )}
                          {selectedPlatformData?.includes("ios") && (
                            <div className="col-lg-4 col-md-6">
                              <Label
                                label={
                                  <span>
                                    IOS App Store
                                    <small>(Optional)</small>
                                  </span>
                                }
                                htmlFor={"iOS-App-Store"}
                                className="custom-form-label-weight"
                              />
                              <input
                                type="text"
                                className="custom-input-field"
                                id="iOS-App-Store"
                                name="iosAppStoreLink"
                                placeholder="Enter Your Link"
                                onChange={Formik.handleChange}
                                value={Formik.values.iosAppStoreLink}
                                onBlur={Formik.handleBlur}
                              />
                              {Formik.touched.iosAppStoreLink &&
                                Formik.errors.iosAppStoreLink && (
                                  <Error
                                    error={Formik.errors.iosAppStoreLink}
                                  />
                                )}
                            </div>
                          )}
                          {selectedPlatformData?.includes("android") && (
                            <div className="col-lg-4 col-md-6">
                              <Label
                                label={
                                  <span>
                                    Android Play Store
                                    <small>(Optional)</small>
                                  </span>
                                }
                                htmlFor={"android-play-store"}
                                className="custom-form-label-weight"
                              />
                              <input
                                type="text"
                                className="custom-input-field"
                                id="android-play-store"
                                name="androidPlayStoreLink"
                                placeholder="Enter Your Link"
                                onChange={Formik.handleChange}
                                value={Formik.values.androidPlayStoreLink}
                                onBlur={Formik.handleBlur}
                              />
                              {Formik.touched.androidPlayStoreLink &&
                                Formik.errors.androidPlayStoreLink && (
                                  <Error
                                    error={Formik.errors.androidPlayStoreLink}
                                  />
                                )}
                            </div>
                          )}

                          <div className="col-md-12">
                            <div className="col-lg-8 col-md-12">
                              <Label
                                label={"Description"}
                                htmlFor={"description"}
                                required
                              />
                              <textarea
                                type="text"
                                className="custom-input-field"
                                placeholder="Enter Description"
                                id="description"
                                rows="6"
                                name="description"
                                onChange={(e) => {
                                  const capitalizedValue =
                                    capitalizeFirstLetter(e.target.value);
                                  Formik.setFieldValue(
                                    "description",
                                    capitalizedValue
                                  );
                                }}
                                value={Formik.values.description}
                                onBlur={(e) => {
                                  Formik.handleBlur(e);
                                  Formik.setFieldValue(
                                    "description",
                                    capitalizeFirstLetter(trimValue(e.target.value))
                                  );
                                }}
                                maxLength={5000}
                              ></textarea>
                              {Formik.touched.description &&
                                Formik.errors.description && (
                                  <Error error={Formik.errors.description} />
                                )}
                            </div>
                          </div>

                          <div className="col-md-12 ">
                            <div className="col-lg-8 col-md-12">
                              <Label
                                label={
                                  <span>
                                    Project Video{" "}
                                    <span className="required-validation">
                                      {" "}
                                      *{" "}
                                    </span>
                                    <small>
                                      {currentPlanName === "Free"
                                        ? `(For free plan, you can upload up to ${VideoMBLimit}MB video)`
                                        : `(You can upload up to ${VideoMBLimit}MB video.)`}
                                    </small>
                                  </span>
                                }
                                htmlFor={"video"}
                                className="custom-form-label-weight"
                              />
                              <div className="d-flex gap-3">
                                <div className="videoBrowse">
                                  <input
                                    disabled={isLoading}
                                    type="text"
                                    className="custom-input-field"
                                    id="video"
                                    name="videoLink"
                                    placeholder="Enter Your Link"
                                    onChange={Formik.handleChange}
                                    value={Formik.values.videoLink}
                                    onBlur={Formik.handleBlur}
                                  />

                                  <p className="cusSubmsgText">
                                    (Supported format: .mp4, .mov)
                                  </p>
                                  {Formik.touched.videoLink &&
                                    Formik.errors.videoLink && (
                                      <Error error={Formik.errors.videoLink} />
                                    )}
                                  <div className="v-image">
                                    <Label
                                      htmlFor={"videoImage"}
                                      label={"Browse"}
                                    />
                                    <input
                                      disabled={isLoading}
                                      {...Videodropzone?.getInputProps({
                                        className: "file-input file-upload",
                                        id: "videoImage",
                                        accept: "video/*",
                                        onClick: !isWindowsOrLinux ? () => Videodropzone.open() : undefined,
                                      })}
                                      className="file-input file-upload"
                                      id="videoImage"
                                      type="file"
                                      name="projectVideo"
                                    />
                                  </div>

                                  {Formik.values.videoLink && (
                                    <button
                                      disabled={isLoading}
                                      type="button"
                                      className="removeinputFluid"
                                    >
                                      <img
                                        src={SVGIMAGES.CloseIcon}
                                        alt=""
                                        onClick={() => {
                                          Formik.setFieldValue("videoLink", "");
                                          Formik.setFieldValue(
                                            "projectVideo",
                                            ""
                                          );
                                          setVideoURL("");
                                        }}
                                      />
                                    </button>
                                  )}

                                  {showVideoProgressBar && (
                                    <UplaodProgress
                                      progress={videoProgressPercentage}
                                    />
                                  )}
                                </div>

                                {videoURL && (
                                  <div className="videoTheme">
                                    <video
                                      src={videoURL}
                                      className="img-fluid imgmainThum"
                                    ></video>
                                    <div className="overflowClose" />
                                    <button
                                      disabled={isLoading}
                                      type="button"
                                      onClick={() => setVideoView(true)}
                                    >
                                      <img
                                        src={SVGIMAGES.VideoPlayIcon}
                                        alt=""
                                      />
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="col-lg-8 col-md-12">
                              <Label
                                label={
                                  <span>
                                    Images{" "}
                                    <span className="required-validation">
                                      {" "}
                                      *{" "}
                                    </span>
                                    <small>
                                      {currentPlanName === "Free"
                                        ? `(For Free plan upload up to ${ScreenshotLimit} images, each up to ${imageSizeLimit} MB.)`
                                        : `(You can upload up to ${ScreenshotLimit} images, each up to ${imageSizeLimit} MB.)`}
                                    </small>
                                  </span>
                                }
                                htmlFor={"projectImages"}
                                className="custom-form-label-weight"
                              />
                              <div
                                className="multipluimg"
                                {...getRootProps({ className: "multipluimg" })}
                              >
                                {selectedScreenShots?.length < 1 ? (
                                  <>
                                    <h2>
                                      Drag and Drop files to upload
                                      <br />
                                      or{" "}
                                    </h2>
                                    <div className="p-image ml-auto">
                                      <Label
                                        htmlFor={"projectImages"}
                                        label={"Browse"}
                                      />
                                      <input
                                        disabled={isLoading}
                                        {...getInputProps({
                                          className: "file-input",
                                          id: "projectImages",
                                          accept: "image/*",
                                        
                                          onClick: !isWindowsOrLinux ? () =>open() : undefined,
                                          
                                        })}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <Thumbnails
                                    getInputProps={getInputProps}
                                    imageData={selectedScreenShots}
                                    disabled={isLoading}
                                    handleRemoveImage={handleRemove}
                                    addImage={() => open()}
                                    viewImage={viewImage}
                                    reorderImages={reorderImages}
                                  />
                                )}
                              </div>

                              {showImageProgressBar && (
                                <UplaodProgress progress={imageProgress} />
                              )}

                              {Formik.touched.projectImages &&
                                Formik.values.projectImages?.length < 1 && (
                                  <Error
                                    error={"Project images are required!"}
                                  />
                                )}
                            </div>
                          </div>
                          <div className="col-md-12 mt-4">
                            <div className="col-md-4">
                              <button
                                type="submit"
                                className="custom-btn"
                                disabled={isLoading}
                              >
                                {" "}
                                {isLoading ? (
                                  <ButtonLoader />
                                ) : (
                                  "Update Project"
                                )}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {viewImgModal && (
        <Modal
          className="common_modal customLargeWidth"
          show={viewImgModal}
          centered
        >
          <Modal.Header>
            <h2>Screenshot View</h2>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                setViewImgModal(false);
              }}
            >
              <img src={SVGIMAGES.CloseButton} alt="Close Button" />
            </button>
          </Modal.Header>
          <ModalBody>
            <SimpleSliderView
              initialSlide={selectedImageIndex}
              CarouselRef={CarouselRef}
            >
              {selectedScreenShots?.map((image, index) => {
                return (
                  <div className="col-md-12" key={index}>
                    <div className="slideDesign">
                      <div className="testProfile">
                        <img
                          draggable={false}
                          src={
                            typeof image === "string"
                              ? image
                              : URL.createObjectURL(image)
                          }
                          alt={image.alt || `Image ${index + 1}`}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </SimpleSliderView>
          </ModalBody>
        </Modal>
      )}

      {videoView && (
        <ModalLayout
          show={videoView}
          setShow={setVideoView}
          centered
          title={"Video"}
          className={"customLargeWidth "}
        >
          <video className="w-100 videocusHeight" controls autoPlay>
            <source src={videoURL} type="video/mp4" allowFullScreen />
          </video>
        </ModalLayout>
      )}

{showCropModal && (
        <ModalLayout
          show={showCropModal}
          setShow={setShowCropModal}
          centered
          title={"Crop Image"}
          
        >
          {src && (
            <ImageCrop
              src={src ? URL.createObjectURL(src) : ""}
              getCropImage={getCropImage}
              setShowCropModal={setShowCropModal}
              cancelModal={closeShowModal}
            />
          )}
        </ModalLayout>
      )}
    </>
  );
};
