export { LoginView } from "./Login";
export { SignupView } from "./Signup";
export { SignupOtpView } from "./Signup-otp";
export { ForgotPasswordView } from "./Forgot-password";
export { ForgotOtpView } from "./Forgot-password-otp";
export { ResetPasswordView } from "./Reset-password";
export { SuccessfullyView } from "./Successfully";
export { ProjectView } from "./Project";
export { AddProjectView } from "./Add-Project";
export { EditProjectView } from "./Edit-Project";
export { ChangePasswordView } from "./Change-Password";
export { ProfileView } from "./Profile";
export { DomainView } from "./Domain";
export { PlatformView } from "./Platform";
export { TechnologyView } from "./Technology";
export { PageNotFoundView } from "./Page-Not-Found";
export { UnderMaintenanceView } from "./Under-Maintenance";
export { SharedPortfolioView } from "./Shared-Portfolio";
export {UsersView} from "./Users"
export { WebHeaderView } from "./public-web/web-header";
export { WebFooterView } from "./public-web/web-footer";
export { HomeView } from "./public-web/home";
export { PrivacyPolicyView } from "./public-web/privacy-policy";
export { TermsConditionsView } from "./public-web/terms-conditions";
export { ContactView } from "./public-web/contact";
export { FaqsView } from "./public-web/faqs";
export { AboutUsView } from "./public-web/about-us";
export {PricingView } from "./public-web/pricing";
export { HomeExport } from "./export-project/home";
export { HomeExportDetail } from "./export-project/project-detail";
