export const IMAGES = {   

  VideoURL: require("./dummy_video.mp4"),
  Logo: require("./logo.png"),
  EyeIcon: require("./eye_icon.png"),
  EyeOffIcon: require("./eyeOff_icon.png"),
  EmailIcon: require("./email_icon.png"),
  KeyIcon: require("./key_icon.png"),
  UserIcon: require("./user_icon.png"),
  CodeIcon: require("./code_icon.png"),
  UploadLogo: require("./upload_logo.png"),
  CameraIcon: require("./camera_icon.png"),
  ChangePasswordImg: require("./change_password.png"),
  ProfileUserImg: require("./profile_img.png"),
  MenuToggleImg: require("./menuToggle.png"),
  ProjectIcon: require("./project_icon.png"),
  DomainIcon: require("./domian_icon.png"),
  PlatformIcon: require("./platform_icon.png"),
  TechnologyIcon: require("./technology_icon.png"),
  PlusIcon: require("./plus_icon.png"),
  DeleteIcon: require("./delete_icon.png"),
  ProjectLogo: require("./project_logo.png"),
  PreviousIcon: require("./previous_icon.png"),
  NextIcon: require("./next_icon.png"),
  EditIcon: require("./edit_icon.png"),
  Screenshot1: require("./screenshot1.png"),
  Screenshot2: require("./screenshot2.png"),
  CloseIcon: require("./close_icon.png"),
  CloseButton: require("./closeButton.png"),
  DeleteModaleIcon: require("./deleteModal_icon.png"),
  LogoutModaleIcon: require("./logoutModal_icon.png"),
  DropDownIcon: require("./dropDown.png"),
  ChangePasswordAdImg: require("./chnage-passwordAd.png"),
  ProfileImgImg: require("./profileImg.png"),
  RightButtonIcon: require("./rightIcon.png"),
  FreePlanIcon: require("./freePlan_icon.png"),
  BasicPlanIcon: require("./basicPlan_icon.png"),
  PremiumPlanIcon: require("./premiumPlan_icon.png"),
  GoldPlanIcon: require("./goldPlan_icon.png"),
  VideoPlayIcon: require("./video_paly_icon.png"),
  PageNotFoundImg: require("./pageNotFound.svg").default, 
  UnderMaintenanceImg: require("./under_maintenance.png"),
  NoDataFoundImg: require("./no-data-found.png"),
  refreshImage:require("./refresh.png"),
  Home01: require("./home_Vorsion1.png"),
  Home02: require("./home_Vorsion2.png"),

 
  // SliderMobile : require('./webImage/slideMobile.png'),
  SliderMobile : require('./webImage/slideMobile.svg').default, 
  
  SendIcon : require('./webImage/sendIcon.svg').default, 
  PlayIcon : require('./webImage/play_icon.svg').default, 
  CheckboxIcon : require('./webImage/ticksquareActiveIcon.svg').default, 
  StarColorIcon : require('./webImage/starColor.svg').default,
  StarGrayIcon : require('./webImage/starGray.svg').default,    
  CLoseButton : require('./webImage/close-btn-icon.svg').default, 
  ToggelMenuIcon : require('./webImage/toggle-menu.svg').default,     
  RightSubIcon : require('./webImage/rightSubIcon.svg').default, 

  SubsImg : require('./webImage/subsImg.jpg'),
  UserelipImg01 : require('./webImage/elip-user01.png'),
  UserelipImg02 : require('./webImage/elip-user02.png'),
  UserelipImg03 : require('./webImage/elip-user03.png'),
  PortfolioLinkIcon : require('./webImage/portfolio_link.png'),
  OfflineAccessIcon : require('./webImage/offline_access_icon.png'),
  SecureReliableIcon : require('./webImage/secure_reliable_icon.png'),
  QuickSyncingIcon : require('./webImage/quick_syncing_icon.png'),
  ProfessionalPresentationsIcon : require('./webImage/professional_presentations_icon.png'),
  UniversalCompatibilityIcon : require('./webImage/universal_compatibility_icon.png'),
  HandAppMobile : require('./webImage/handAppMobile.png'),
  GridLine : require('./webImage/gridLine.png'), 

  LogoWeb : require('./logo.png'),
  TIcon : require('./webImage/down-arrow-gray.png'), 
  TwitterIcon : require('./webImage/twitter_icon.svg').default,  
  FacebookIcon : require('./webImage/facebook_icon.svg').default, 
  InSIcon : require('./webImage/ins_icon.svg').default,  
  linkedInIcon : require('./webImage/linkedIn_icon.svg').default, 
  GoogleIcon : require('./webImage/google_icon.svg').default,    
  GooglePlay : require('./webImage/googleplay.svg').default, 
  AppStore : require('./webImage/appstore.svg').default,
  MacStore : require('./webImage/mac.svg').default, 
  Windows : require('./webImage/windows.svg').default, 

  ClientIMG1:require("./webImage/customer_img1.png"),
  ClientIMG2:require("./webImage/customer_img2.png"),
  ClientIMG3:require("./webImage/customer_img3.png"),
  ClientIMG4:require("./webImage/customer_img4.png"),
  ClientIMG5:require("./webImage/customer_img5.png"),
  QuoteIcon : require('./webImage/quote_icon.svg').default,  

// payment modal
SuccessIcon : require("./successful-tick.png"),
ErrorIcon:require("./paymentfailed-img.png"),

// payment modal
ExportLogo : require("./exportProjectImage/logo.png"),
HearthIcon : require("./exportProjectImage/hearth.svg").default,
HearthBorderIcon : require("./exportProjectImage/hearth_border.svg").default,

Agpal : require("./exportProjectImage/portfolio_agpal.png"),
Difx : require("./exportProjectImage/portfolio_difx.png"),

Everstore : require("./exportProjectImage/portfolio_everstore.png"),
EverstoreImage1 : require("./exportProjectImage/everstoreScreen01.png"),
EverstoreImage2 : require("./exportProjectImage/everstoreScreen02.png"),
EverstoreImage3 : require("./exportProjectImage/everstoreScreen03.png"),

FindYourIdeal : require("./exportProjectImage/portfolio_find_your_ideal.png"), 

BAckButtonIcon : require("./exportProjectImage/backIcon.svg").default,

BAckButtonIcon1 : require("./dummuuuuu.png"),
ExpiredIconPlan : require("./expired_icon.svg").default,
AboutUs:require("./webImage/aboutUs.png"),
MissionIcon:require("./webImage/mission_icon.png"),
TeamIcon:require("./webImage/team_icon.png"),


};

