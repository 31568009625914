import { useEffect, useMemo, useState } from "react";
import ReactSelect from "react-select";

export const CategoryDropdown = ({
  sortedData,
  isDisabled,
  setSortedData,
  categoryTypes,
  setNewCategoryTypes,
  Formik,
}) => {
  const [domainFilter, setDomainFilter] = useState("Select Category");

  //   const CategoryOptions = categoryTypes?.map((domain) => ({ value: domain?._id, label: domain?.title }))
  const CategoryOptions = useMemo(
    () =>
      categoryTypes?.map((domain) => ({
        value: domain?._id,
        label: domain?.title,
      })),
    [categoryTypes]
  );

  const mappedDomainIds = (data) => {
    let newData = [data];
    return newData?.map((domain) => domain?.value);
  };

  const mappedNewOptions = (data) => {
    return data?.__isNew__
      ? [{ title: data?.value?.toLowerCase(), remark: " " }]
      : [];
  };

  const handleChange = (selectedOptions) => {
    if (!selectedOptions || selectedOptions.length === 0) {
      setDomainFilter(null);
      setSortedData([]);
      setNewCategoryTypes([]);
      Formik.setFieldValue("categoryId", []);
    } else {
      setDomainFilter(selectedOptions);
      setSortedData(mappedDomainIds(selectedOptions));
      setNewCategoryTypes(mappedNewOptions(selectedOptions));
      Formik.setFieldValue(
        "categoryId",
        mappedDomainIds(selectedOptions) || []
      );
    }
  };

  useEffect(() => {
    if (
      sortedData &&
      sortedData.length > 0 &&
      CategoryOptions &&
      CategoryOptions.length > 0
    ) {
      const matchingOptions = CategoryOptions.filter((option) =>
        sortedData.includes(option.value)
      );
      setDomainFilter(matchingOptions);
    } else {
      setDomainFilter([]);
    }
  }, [sortedData, CategoryOptions]);

  return (
    <>
      <ReactSelect
        isSearchable
        isClearable
        isDisabled={isDisabled}
        className="react-select-container"
        placeholder="Select Category"
        classNamePrefix="react-select"
        onChange={handleChange}
        value={domainFilter}
        options={CategoryOptions}
      />
    </>
  );
};
