import React, { useEffect } from "react";
import { WebHeaderView } from "./web-header";
import { WebFooterView } from "./web-footer";
import { useNavigate } from "react-router-dom";
import { IMAGES } from "../../assets/images";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getSubscriptions } from "../../store/actions/subscriptionActions";
import { SVGIMAGES } from "../../assets/svgImages";
import { SUBSCRIPTION_TYPES } from "../../constants/Enums";

export const PricingView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subscriptions = useSelector(({ subscription }) => subscription?.subscriptions);
  useEffect(() => {
    dispatch(getSubscriptions());
  }, []);
  console.log("subscriptions---", subscriptions);

  const getPriceClassName = (subscription) => {
    if (subscription?.type === SUBSCRIPTION_TYPES.FREE) {
      return "h1Freeplan";
    } else if (subscription?.type === SUBSCRIPTION_TYPES.BASIC) {
      return "h1Basicplan";
    } else if (subscription?.type === SUBSCRIPTION_TYPES.PREMIUM) {
      return "h1Premiumplan";
    } else if (subscription?.type === SUBSCRIPTION_TYPES.GOLD) {
      return "h1goldplan";
    }
    return "";
  };
  const getPlanDetails = (subscription) => {
    if (subscription?.type === SUBSCRIPTION_TYPES.FREE) {
      return {
        icon: SVGIMAGES.FreePlanIcon,
        name: "Free Plan",
        duration: "For 3 Months",
        buttonName: "Try For Free",
      };
    } else if (subscription?.type === SUBSCRIPTION_TYPES.BASIC) {
      return {
        icon: SVGIMAGES.BasicPlanIcon,
        name: "Basic Plan",
        duration: "Month",
        buttonName: "Get Basic",
      };
    } else if (subscription?.type === SUBSCRIPTION_TYPES.PREMIUM) {
      return {
        icon: SVGIMAGES.PremiumPlanIcon,
        name: "Premium Plan",
        duration: "Year",
        buttonName: "Get Premium",
      };
    } else if (subscription?.type === SUBSCRIPTION_TYPES.GOLD) {
      return {
        icon: IMAGES.GoldPlanIcon,
        name: "Gold Plan",
        duration: "Year",
        buttonName: "Get Gold",
      };
    }
    return {
      icon: null,
      name: "Unknown Plan",
    };
  };
  const featureDisplayNames = {
    project: "Project Limit",
    screenshot: "Image Limit",
    videosize: "Video Size Limit",
    imagesize: "Image Size Limit",
    sharelinkcreatecount: "Share Portfolio Limit",
  };

  const getButtonClassName = (subscription) => {
    if (subscription?.type === SUBSCRIPTION_TYPES.FREE) {
      return "btnFreeplan";
    } else if (subscription?.type === SUBSCRIPTION_TYPES.BASIC) {
      return "btnBasicplan";
    } else if (subscription?.type === SUBSCRIPTION_TYPES.PREMIUM) {
      return "btnPremiumplan";
    } else if (subscription?.type === SUBSCRIPTION_TYPES.GOLD) {
      return "btngoldplan";
    }
    return "";
  };
  return (
    <>
      <WebHeaderView />
      <div className="cusSlider pricingSlide">
        <div className="container-fluid">
          <ul>
            <li>
              <button onClick={() => navigate("/")}>Home</button>
            </li>
            <li>|</li>
            <li>Pricing</li>
          </ul>
          <h1>Pricing</h1>
        </div>
      </div>

      <div className="pricingpage">
        <div className="container-fluid">
          <h2>✨ Enjoy Peace of Mind with Our Payment Plans! ✨</h2>
          <p>Choose the subscription plan that suits you and your business best.</p>
          <ul className="freeToPay">
            <li>🔒 No Credit Card Needed</li>
            <li>🔒 No Debit Card Required </li>
            <li>🔒 No Auto Payments</li>
          </ul>
          <div className="row col-xxl-10 col-xl-11 m-auto">
            {subscriptions?.map((subscription) => {
              return (
                <div className="col-lg-3 col-md-6">
                  <div class="subscriAuth">
                    <h3>
                      {subscription?.type && (
                        <img
                          src={getPlanDetails(subscription)?.icon}
                          alt=""
                        />
                      )}
                      {getPlanDetails(subscription)?.name}
                    </h3>
                    <h1 className={getPriceClassName(subscription)}>
                      {`${subscription?.price > 0 ? "$ " + subscription?.price : "Free"}`}
                      <span className={"yearSpanClass"}>/{`${getPlanDetails(subscription)?.duration}`} </span>
                    </h1>
                    <hr />
                    {subscription?.features?.length > 0 &&
                      subscription.features.map((feature, index) => {
                        const displayName = featureDisplayNames[feature.type.toLowerCase()];
                        const limit = feature.limit < 10 ? `0${feature.limit}` : feature.limit;

                        return (
                          <h6
                            className="text-capitalize"
                            key={`feature ${index + 1}`}
                          >
                            {`${displayName} : ${limit}`}
                          </h6>
                        );
                      })}

                    <button
                      className={getButtonClassName(subscription)}
                      onClick={() => navigate("/login")}
                    >
                      {getPlanDetails(subscription)?.buttonName}{" "}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <WebFooterView />
    </>
  );
};
