import { useEffect, useState } from "react";
import ReactSelect from "react-select";


export const DurationDropdown = ({ Formik }) => {
  const [durationOptions] = useState([
    { value: "less_than_1_month", label: "Less than 1 month" },
    { value: "1_to_3_months", label: "1 to 3 months" },
    { value: "3_to_6_months", label: "3 to 6 months" },
    { value: "6_to_12_months", label: "6 to 12 months" },
    { value: "more_than_12_months", label: "More than 12 months" }
  ]);

  const [selectedDuration, setSelectedDuration] = useState(null);

  useEffect(() => {
    if (Formik.values.duration) {
      const matchedOption = durationOptions.find(option => option.label === Formik.values.duration);
      setSelectedDuration(matchedOption || null);
    }
  }, [Formik.values.duration, durationOptions]);

  const handleChange = (selectedOption) => {
    setSelectedDuration(selectedOption);
    Formik.setFieldValue('duration', selectedOption ? selectedOption.label : '');
  };

  return (
   
     
      <ReactSelect
        className="react-select-container"
        classNamePrefix="react-select"
        placeholder="Select Duration"
        options={durationOptions}
        value={selectedDuration}
        onChange={handleChange}
        // onBlur={() => Formik.setFieldTouched('duration', true)}
        isClearable
        isSearchable={false}

      />
      
   
  );
};
