import { Error } from "../Error"
import { Label } from "../Label"

export const Input = ({ error, label, htmlFor, required, ...inputProps }) => {
   return (
        <>
            <Label label={label} htmlFor={htmlFor} required={required} />
            <input type="text" className="custom-input-field" id="android-play-store"
                placeholder="Enter Your Link" name="androidPlayStoreLink" {...inputProps} />
            {error && (
                <Error error={error} />
            )}
        </>
    )
}