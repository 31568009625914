// import { useEffect, useState } from "react";
// import CreatableSelect from "react-select/creatable";

// export const DomainDropdown = ({
//   sortedData,
//   setSortedData,
//   domainTypes,
//   setNewDomainTypes,
//   Formik,
// }) => {
//   const [domainFilter, setDomainFilter] = useState("Select Domain");

//   const domainOptions = domainTypes?.map((domain) => ({
//     value: domain?._id,
//     label: domain?.title,
//   }));

//   const mappedDomainIds = (data) => {
//     if (!data.__isNew__) {
//       return [data.value];
//     } else {
//       return [];
//     }
//   };

//   const mappedNewOptions = (data) => {
//     return data?.__isNew__
//       ? [{ title: data?.value?.toLowerCase(), remark: " " }]
//       : [];
//   };
//   // * * //

//   const handleChange = (selectedOptions) => {
//     let optionsToSet = selectedOptions;
//     if (optionsToSet) {
//       setDomainFilter(optionsToSet);
//       setSortedData(mappedDomainIds(optionsToSet));
//       setNewDomainTypes(mappedNewOptions(optionsToSet));
//       Formik?.setFieldValue("domainDetails", mappedNewOptions(optionsToSet));
//       Formik?.setFieldValue("domainId", mappedDomainIds(optionsToSet) || []);
//     } else {
//       setDomainFilter([]);
//       setSortedData([]);
//       setNewDomainTypes([]);
//       Formik?.setFieldValue("domainId", []);
//       Formik?.setFieldValue("domainDetails", []);
//     }
//   };

//   useEffect(() => {
//     if (sortedData && sortedData?.length > 0) {
//       // Find matching options based on sortedData
//       const matchingOptions = domainOptions.filter((option) =>
//         sortedData.some((data) => data === option.value)
//       );

//       setDomainFilter(matchingOptions);
//     } else {
//       // Reset to initial state
//       setDomainFilter([]);
//     }
//     // eslint-disable-next-line
//   }, [sortedData]);

//   useEffect(() => {
//     setDomainFilter(domainFilter);
//   }, [domainFilter]);

//   return (
//     <>
//       <CreatableSelect
//         isSearchable
//         isClearable
//         className="react-select-container"
//         placeholder="Select Domain"
//         classNamePrefix="react-select"
//         onChange={handleChange}
//         value={domainFilter}
//         options={domainOptions}
//       />
//     </>
//   );
// };

import { useEffect, useState } from "react";
import CreatableSelect from 'react-select/creatable';

export const DomainDropdown = ({ sortedData, setSortedData, domainTypes, setNewDomainTypes, Formik }) => {
    const [domainFilter, setDomainFilter] = useState('Select Domain');
    const [inputValue, setInputValue] = useState('');

    const domainOptions = domainTypes?.map((domain) => ({ value: domain?._id, label: domain?.title }));

    const mappedDomainIds = (data) => {
        if (!data.__isNew__) {
            return [data.value];
        } else {
            return [];
        }
    }

    const mappedNewOptions = (data) => {
        return data?.__isNew__ ? [{ title: data?.value?.toLowerCase(), remark: " " }] : [];
    }

    const handleChange = (selectedOptions) => {
        let optionsToSet = selectedOptions;
        if (optionsToSet) {
            setDomainFilter(optionsToSet);
            setSortedData(mappedDomainIds(optionsToSet));
            setNewDomainTypes(mappedNewOptions(optionsToSet));
            Formik?.setFieldValue('domainDetails', mappedNewOptions(optionsToSet));
            Formik?.setFieldValue('domainId', mappedDomainIds(optionsToSet) || []);
        } else {
            setDomainFilter([]);
            setSortedData([]);
            setNewDomainTypes([]);
            Formik?.setFieldValue('domainId', []);
            Formik?.setFieldValue('domainDetails', []);
        }
    };

    const handleInputChange = (newValue) => {
        if (newValue.length <= 30) {
            setInputValue(newValue);
        }
    };

    const handleCreate = (inputValue) => {
        if (inputValue.length <= 30) {
            const newOption = { value: inputValue, label: inputValue, __isNew__: true };
            handleChange(newOption);
        }
    };

    useEffect(() => {
        if (sortedData && sortedData?.length > 0) {
            const matchingOptions = domainOptions.filter(option => 
                sortedData.some(data => data === option.value)
            );
            setDomainFilter(matchingOptions);
        } else {
            setDomainFilter([]);
        }
        // eslint-disable-next-line
    }, [sortedData]);

    useEffect(() => {
        setDomainFilter(domainFilter);
    }, [domainFilter]);

    return (
        <>
            <CreatableSelect
                isSearchable
                isClearable
                className="react-select-container"
                placeholder="Select Domain"
                classNamePrefix="react-select"
                onChange={handleChange}
                onInputChange={handleInputChange}
                onCreateOption={handleCreate}
                inputValue={inputValue}
                value={domainFilter}
                options={domainOptions}
            />
        </>
    );
};
