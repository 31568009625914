import { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { SVGIMAGES } from "../assets/svgImages";
import { changePassword } from "../store/actions/authActions";
import { HeaderView, Label, Error, SidebarView, ButtonLoader } from "../components";
import { passwordValidation, trimValue } from "../utils/helper";

export const ChangePasswordView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const collapseSidebar = useSelector(({ auth }) => auth?.collapseSidebar);
    const [isLoading, setIsLoading] = useState(false);
    const [showOPassword, setShowOPassword] = useState(false);
    const [showNPassword, setShowNPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);

    const ChangePasswordFormik = useFormik({
        initialValues: {
            old_password: '',
            new_password: '',
            c_password: '',
        },
        // validationSchema: Yup.object({
        //     old_password: Yup.string().trim().required("Old Password is required!"),
        //     new_password: Yup.string().trim().required("New Password is required!"),
        //     c_password: Yup.string().trim().oneOf([Yup.ref('new_password')], 'Passwords must match').required("Confirm Password is required!"),
        // }),
          validationSchema : Yup.object({
            old_password: Yup.string()
                .trim()
                .required("Old password is required!"),
            new_password: Yup.string()
                .trim()
                .required("New password is required!")
                .min(8, "Password must be at least 8 characters long")
                .matches(
                    passwordValidation,
                    "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character"
                ),
            c_password: Yup.string()
                .trim()
                .oneOf([Yup.ref('new_password')], 'Passwords must match')
                .required("Confirm password is required!"),
        }),
        onSubmit: async (values) => {
           if (ChangePasswordFormik.isValid && ChangePasswordFormik.dirty) {
                const { old_password, new_password } = values;
                try {
                    const values = { oldPassword: old_password, newPassword: new_password };
                    setIsLoading(true);
                    await dispatch(changePassword(values)).then((res) => {
                        if (res?.status === 200 && res?.data?.success) {
                            const rememberMe = localStorage.getItem('rememberMe')
                            if (rememberMe) {
                                localStorage.setItem('password', new_password)
                            }
                            toastr.success("Success", res?.data?.message);
                            navigate(-1);
                        }
                        else {
                            toastr.error("Error", res?.data?.message);
                        }
                    })
                }
                catch (error) {
                    setIsLoading(false)
                    toastr.error("Error", error);
                }
                finally {
                    setIsLoading(false);
                }

            }
        }
    })

    return (
        <>
            <div className={collapseSidebar ? "mainBody sortingMain" : "mainBody"}>
                <HeaderView />

                <div className="wrapper">
                    <SidebarView />
                    <div className="main-container">
                        <div className="content">
                            <div className="row mb-3">
                                <div className="col-xxl-12">
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-xxl-12">
                                                <div className="greetingsText mb-3">
                                                    <div className="greetingsText-heading">
                                                        {/* <nav aria-label="breadcrumb">
                                                            <ol className="breadcrumb">
                                                                <li className="breadcrumb-item"><button onClick={() => navigate("/projects")}>Project</button>
                                                                </li>
                                                                <li className="breadcrumb-item active" aria-current="page">Create</li>
                                                            </ol>
                                                        </nav> */}
                                                        <h3>Change Password</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 align-self-center mobile_d_none">
                                                <img src={SVGIMAGES.ChangePasswordAdImg} alt="" className="img-fluid" />
                                            </div>
                                            <div className="col-lg-6  align-self-center">
                                                <form className="row g-3 changePassword" onSubmit={ChangePasswordFormik.handleSubmit}>
                                                    <div className="col-lg-12">
                                                        <Label label={"Old Password"} htmlFor={"old_password"} required />
                                                        <div className="possionIconInput">
                                                            <input type={showOPassword ? "text" : "password"} className="custom-input-field" id="old_password"
                                                                placeholder="Enter Old Password" name="old_password"
                                                                value={ChangePasswordFormik.values.old_password}
                                                                onChange={ChangePasswordFormik.handleChange}
                                                                maxLength={30}
                                                                onBlur={(e) => {
                                                                    ChangePasswordFormik.handleBlur(e);
                                                                    ChangePasswordFormik.setFieldValue(
                                                                    "old_password",
                                                                    trimValue(e.target.value)
                                                                  );
                                                                }}
                                                               />
                                                            <img src={showOPassword ? SVGIMAGES.EyeIcon : SVGIMAGES.EyeOffIcon} alt="" onClick={(e) => setShowOPassword(!showOPassword)} className="imgRightEye" />
                                                        </div>
                                                        {ChangePasswordFormik.errors.old_password && ChangePasswordFormik.touched.old_password && (
                                                            <Error error={ChangePasswordFormik.errors.old_password} />
                                                        )}
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <Label label={"New Password"} htmlFor={"new_password"} required />
                                                        <div className="possionIconInput">
                                                            <input type={showNPassword ? "text" : "password"} className="custom-input-field" id="new_password"
                                                                placeholder="Enter New Password" name="new_password"
                                                                value={ChangePasswordFormik.values.new_password}
                                                                onChange={ChangePasswordFormik.handleChange}
                                                                maxLength={30}
                                                                onBlur={(e) => {
                                                                    ChangePasswordFormik.handleBlur(e);
                                                                    ChangePasswordFormik.setFieldValue(
                                                                    "new_password",
                                                                    trimValue(e.target.value)
                                                                  );
                                                                }}
                                                                 />
                                                            <img src={showNPassword ? SVGIMAGES.EyeIcon : SVGIMAGES.EyeOffIcon} alt="" onClick={(e) => setShowNPassword(!showNPassword)} className="imgRightEye" />
                                                        </div>
                                                        {ChangePasswordFormik.errors.new_password && ChangePasswordFormik.touched.new_password && (
                                                            <Error error={ChangePasswordFormik.errors.new_password} />
                                                        )}
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <Label label={"Confirm Password"} htmlFor={"c_password"} required />
                                                        <div className="possionIconInput">
                                                            <input type={showCPassword ? "text" : "password"} className="custom-input-field" id="c_password"
                                                                placeholder="Enter Confirm Password" name="c_password"
                                                                value={ChangePasswordFormik.values.c_password}
                                                                onChange={ChangePasswordFormik.handleChange}
                                                                maxLength={30}
                                                                onBlur={(e) => {
                                                                    ChangePasswordFormik.handleBlur(e);
                                                                    ChangePasswordFormik.setFieldValue(
                                                                    "c_password",
                                                                    trimValue(e.target.value)
                                                                  );
                                                                }}
                                                                 />
                                                            <img src={showCPassword ? SVGIMAGES.EyeIcon : SVGIMAGES.EyeOffIcon} alt="" onClick={(e) => setShowCPassword(!showCPassword)} className="imgRightEye" />
                                                        </div>
                                                        {ChangePasswordFormik.errors.c_password && ChangePasswordFormik.touched.c_password && (
                                                            <Error error={ChangePasswordFormik.errors.c_password} />
                                                        )}
                                                    </div>

                                                    <div className="col-md-6 mt-4">
                                                        <button type="submit" className="custom-btn" >
                                                            {isLoading ? <ButtonLoader /> : "Submit"}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}