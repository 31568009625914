import React from "react";

export const SearchInput = ({ value, setSearchKeyword, placeholder }) => {
  return (

    <input className="custom-input-field"
      type="text"
      value={value}
      onChange={(e) => {
        setSearchKeyword && setSearchKeyword(e.target.value);
        if (!e.target.value) {
          sessionStorage.removeItem('searchKeyword');
        }
      }}
      placeholder={placeholder}
    />
  );
};
