import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { SVGIMAGES } from '../../assets/svgImages';

export const Thumbnails = ({ imageData = [], handleRemoveImage, addImage, viewImage, reorderImages, disabled, getInputProps, ...props }) => {

    const handleOnDragEnd = (result) => {
        if (!result.destination) return; // Exit if dropped outside the list
        const items = Array.from(imageData);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        reorderImages(items);
    };

    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="images" direction="horizontal">
                {(provided) => (
                    <ul className="screenThumImg" {...provided.droppableProps} ref={provided.innerRef}>
                        {imageData.length > 0 && imageData?.map((image, index) => (
                            <Draggable key={index} draggableId={`image-${index}`} index={index}>
                                {(provided) => (
                                    <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="">
                                        {typeof image === 'object' && image instanceof File ? (
                                            <img src={URL.createObjectURL(image)} alt="" className="img-fluid imgmainThum" />
                                        ) : (
                                            <img src={image} alt="" className="img-fluid imgmainThum" />
                                        )}
                                        {/* Handle the click to view image */}
                                        <div className="overflowClose" onClick={() => viewImage(index)} />
                                        {/* Remove the image */}
                                        <button type="button" disabled={disabled} onClick={() => handleRemoveImage && handleRemoveImage(index)}>
                                            <img src={SVGIMAGES.CloseIcon} alt="Remove" />
                                        </button>
                                    </li>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        {/* Add new image */}
                        <span>
                            <li className="addImage" onClick={() => addImage && addImage()}>
                                <img src={SVGIMAGES.PlusIcon} alt="Add" />
                            </li>
                        </span>
                        <input {...getInputProps({
                            className: "file-input",
                            id: "projectImages",
                            accept: "image/*",
                            onClick: () => { addImage && addImage() }
                        })} />
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
    );
};



