import { UsersApiEndpoints } from "../apiEndpoints";
import { Instance } from "../apiServices";
import { UsersActions } from "./actionTypes";
import { refreshToken } from "./authActions";

export const getuserList=(params)=>{
    const search = params?.search
    const searchParam = search ? `&search=${search}` : '';
    const pageNumber = params?.currentPage; 
    const limit = params?.pageLimit;
 return async (dispatch,getState)=>{
  const response = await Instance(
    getState,
    "GET",
    UsersApiEndpoints.GET_USERS_LIST_API+ `?page=${pageNumber}&limit=${limit}` + searchParam,
    null,
    true
  )
 if(response?.status ===200 && response?.data?.success){
    dispatch({
        type: UsersActions.GET_USERS_LIST,
        payload: response?.data,
      });
      return response;
    } else {
      return response;
    }
 }
}

export const createUser=(params)=>{
  return async (dispatch,getState)=>{
    const response = await Instance(
      getState,
      "POST",
      UsersApiEndpoints.ADD_USER_API,
      params,
      true,
      false
    )
    if (response?.status === 200 && response?.data?.success) {
      return response;
    } else if (
      response?.response?.status === 403 &&
      !response?.response?.data?.success
    ) {
      const res = await dispatch(refreshToken());
      if (res?.data?.success && res?.status === 200) {
        dispatch(createUser(params));
      }
    } else {
      return response;
    }
  }
}

export const deleteUser = (params) => {

  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "DELETE", //method
      UsersApiEndpoints.DELETE_USER_API + params,//api-endpoint
      params, //data/params
      true, //isAuth
      true
    );

    if (response?.status === 200 && response?.data?.success) {
      return response;
    } else if (
      response?.response?.status === 403 &&
      !response?.response?.data?.success
    ) {
      const res = await dispatch(refreshToken());
      if (res?.data?.success && res?.status === 200) {
        dispatch(deleteUser(params));
      }
    } else {
      return response;
    }
  };
};
