import { RECORD_TYPES } from "../../constants/Enums";
import { PlatformApiEndpoints } from "../apiEndpoints";
import { Instance } from "../apiServices";
import { platformActions } from "./actionTypes";
import { refreshToken } from "./authActions";


export const getPlatfromList = (params) => {
  const search = params?.search
  const searchParam = search ? `&title=${search}` : '';
  const pageNumber = params?.currentPage; const limit = params?.pageLimit;
  const getListBy = params?.getListBy
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "GET", //method
      PlatformApiEndpoints.GET_PLATFORM_LIST_API + RECORD_TYPES.PLATFORM + '/' + getListBy + `?page=${pageNumber}&limit=${limit}` + searchParam, //api-endpoint
      null, //data/params
      true //isAuth
    );

    if (response?.status === 200 && response?.data?.success) {
      dispatch({
        type: platformActions.GET_PLATFORM_LIST,
        payload: response?.data,
      });
      return response;
    } else {
      return response;
    }
  };
};

export const createPlatfrom = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "POST", //method
      PlatformApiEndpoints.ADD_PLATFORM_API,//api-endpoint
      params, //data/params
      true, //isAuth
      false
    );
    if (response?.status === 200 && response?.data?.success) {
      return response;
    } else if (
      response?.response?.status === 403 &&
      !response?.response?.data?.success
    ) {
      const res = await dispatch(refreshToken());
      if (res?.data?.success && res?.status === 200) {
        dispatch(createPlatfrom(params));
      }
    } else {
      return response;
    }
  };
};

export const deletePlatform = (params) => {

  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "DELETE", //method
      PlatformApiEndpoints.DELETE_PLATFORM_API + params,//api-endpoint
      params, //data/params
      true, //isAuth
      true
    );

    if (response?.status === 200 && response?.data?.success) {
      return response;
    } else if (
      response?.response?.status === 403 &&
      !response?.response?.data?.success
    ) {
      const res = await dispatch(refreshToken());
      if (res?.data?.success && res?.status === 200) {
        dispatch(deletePlatform(params));
      }
    } else {
      return response;
    }
  };
};