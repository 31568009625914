import { RECORD_TYPES } from "../../constants/Enums";
import { DomainApiEndpoints } from "../apiEndpoints";
import { Instance } from "../apiServices";
import { domainActions } from "./actionTypes";
import { refreshToken } from "./authActions";

export const getDomainList = (params) => {
  const search = params?.search;
  const searchParam = search ? `&title=${search}` : "";
  const pageNumber = params?.currentPage;
  const limit = params?.pageLimit;
  const getListBy = params?.getListBy;
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "GET", //method
      DomainApiEndpoints.GET_DOMAIN_LIST_API +
        RECORD_TYPES.DOMAIN +
        "/" +
        getListBy +
        `?page=${pageNumber}&limit=${limit}` +
        searchParam, //api-endpoint
      null, //data/params
      true //isAuth
    );

    if (response?.status === 200 && response?.data?.success) {
      dispatch({
        type: domainActions.GET_DOMAIN_LIST,
        payload: response?.data,
      });
      return response;
    } else {
      return response.response;
    }
  };
};
export const createDomain = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "POST", //method
      DomainApiEndpoints.ADD_DOMAIN_API, //api-endpoint
      params, //data/params
      true, //isAuth
      false
    );
    if (response?.status === 200 && response?.data?.success) {
      return response;
    } else if (
      response?.response?.status === 403 &&
      !response?.response?.data?.success
    ) {
      const res = await dispatch(refreshToken());
      if (res?.data?.success && res?.status === 200) {
        dispatch(createDomain(params));
      }
    } else {
      return response;
    }
  };
};

export const deleteDomain = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "DELETE", //method
      DomainApiEndpoints.DELETE_DOMAIN_API + params, //api-endpoint
      params, //data/params
      true, //isAuth
      true
    );

    if (response?.status === 200 && response?.data?.success) {
      return response;
    } else if (
      response?.response?.status === 403 &&
      !response?.response?.data?.success
    ) {
      const res = await dispatch(refreshToken());
      if (res?.data?.success && res?.status === 200) {
        dispatch(deleteDomain(params));
      }
    } else {
      return response;
    }
  };
};
