import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { reducer as toastrReducer } from "react-redux-toastr";
import {
  authReducer,
  projectReducer, domainReducer, platformReducer, technologyReducer,
  subscriptionReducer,portFolioReducer,ratingCustomerCountReducer,usersReducer
} from "./reducers";

// redux store states configuration

const persistConfig = {
  key: "root",
  storage,
};

const authConfig = {
  key: "auth",
  storage,
};

const projectsConfig = {
  key: "projects",
  storage,
};

const domainConfig = {
  key: "domain",
  storage,
};

const platformConfig = {
  key: "platform",
  storage,
};

const technologyConfig = {
  key: "technology",
  storage,
};

const subscriptionConfig = {
  key: "subscription",
  storage,
};

const portFolioConfig = {
  key: "portfolio",
  storage,
};
const ratingcustomercountconfig={
  key: "ratingcustomercount",
  storage,
}
const usersConfig = {
  key: "users",
  storage,
};

const rootReducer = combineReducers({
  auth: persistReducer(authConfig, authReducer),
  projects: persistReducer(projectsConfig, projectReducer),
  domain: persistReducer(domainConfig, domainReducer),
  platform: persistReducer(platformConfig, platformReducer),
  technology: persistReducer(technologyConfig, technologyReducer),
  subscription: persistReducer(subscriptionConfig, subscriptionReducer),
  portfolio:persistReducer(portFolioConfig,portFolioReducer),
  ratingcustomercount:persistReducer(ratingcustomercountconfig,ratingCustomerCountReducer),
  users:persistReducer(usersConfig,usersReducer),
  toastr: toastrReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export default persistedReducer;
