import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  AboutUsView,
  AddProjectView,
  ChangePasswordView,
  ContactView,
  DomainView,
  EditProjectView,
  FaqsView,
  ForgotOtpView,
  ForgotPasswordView,
  HomeExport,
  HomeExportDetail,
  HomeView,
  LoginView,
  PageNotFoundView,
  PlatformView,
  PricingView,
  PrivacyPolicyView,
  ProfileView,
  ProjectView,
  ResetPasswordView,
  SharedPortfolioView,
  SignupOtpView,
  SignupView,
  SuccessfullyView,
  TechnologyView,
  TermsConditionsView,
  UnderMaintenanceView,
  UsersView,
} from "../pages";
import ScrollToTop  from "../utils/helper";


import { AdminAuthRoutes, PrivateRoutes } from "./protectedRoutes";

export const Navigation = () => {

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/projectDetails/*" element={<HomeExport />} />
        <Route path="/project-details" element={<HomeExportDetail />} />

        <Route path="*"  element={<Navigate to={'/'}/>} />
        <Route path="/" element={<HomeView />} />
        <Route path="/under-maintenance" element={<UnderMaintenanceView />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyView />} />
        <Route path="/terms-conditions" element={<TermsConditionsView />} />
        <Route path="/contact-us" element={<ContactView />} />
        <Route path="/faqs" element={<FaqsView />} />
        <Route path="/about-us" element={<AboutUsView />} />
        <Route path="/pricing" element={<PricingView />} />

        <Route element={<PrivateRoutes />}>
          <Route path="/login" element={<LoginView />} />
          <Route path="/forgot-password" element={<ForgotPasswordView />} />
          <Route path="/forgot-password-otp" element={<ForgotOtpView />} />
          <Route path="/signup" element={<SignupView />} />
          <Route path="/signup-otp" element={<SignupOtpView />} />
          <Route path="/reset-password" element={<ResetPasswordView />} />
          <Route path="/successfully" element={<SuccessfullyView />} />
        </Route>

        <Route element={<AdminAuthRoutes />}>
          <Route path="/projects" element={<ProjectView />} />
          <Route path="/add-project" element={<AddProjectView />} />
          <Route path="/edit-project" element={<EditProjectView />} />
          <Route path="/change-password" element={<ChangePasswordView />} />
          <Route path="/profile" element={<ProfileView />} />
          <Route path="/domain" element={<DomainView />} />
          <Route path="/platform" element={<PlatformView />} />
          <Route path="/technology" element={<TechnologyView />} />
          <Route path="/users" element={<UsersView />} />
          <Route path="/shared-portfolio" element={<SharedPortfolioView />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
