

import { useNavigate } from "react-router-dom";
import { SVGIMAGES } from "../assets/svgImages";

export const SuccessfullyView=()=>{
    const navigate = useNavigate ();
    return (
        <div className="loginBg">
            <div className="container-fluid">
                <div className="align-self-center pad-0 form-section">
                    <div className="mainLoginbox">
                        <form >
                            <div className="loogSection">
                                <img src={SVGIMAGES.Logo} alt="" />   
                            </div>
                            <div className="sucessSection">
                            <img src={SVGIMAGES.ChangePasswordImg} alt="" />            
                                <h1 className="tilteHead">Change password successfully!</h1>
                                <p className="paregrapSub">You have successfully change password. Please use the new password when Sign in.</p>
                            </div>                                                      
                            
                            <div className="form-group align-self-center d-flex">
                                <button className="formButton" onClick={()=> navigate("/login")}>Okay</button>
                            </div>                     
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}