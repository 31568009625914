// import React, { useState } from "react";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/bootstrap.css";

// export const CountryCodeDropdown = ({onChangeValue}) => {
//   const [phoneNumber, setPhoneNumber] = useState("");
//     return (
//     <PhoneInput
//       country={"us"}  
//       enableSearch={true}
//       countryCodeEditable={false}
//       value={phoneNumber}  
//     //  onChange={(value, country, event, formattedValue)=>{console.log(value, country, event, formattedValue)}}
//       onChange={(value, country, event, formattedValue) => onChangeValue(value, country)}
//     />
//   );
// };
import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

export const CountryCodeDropdown = ({ onChangeValue, phoneNumber, reset }) => {
  React.useEffect(() => {
    if (reset) {
      onChangeValue("", { dialCode: "" }); // Reset phone number if `reset` is true
    }
  }, [reset, onChangeValue]);

  return (
    <PhoneInput
      country={"us"}
      key={reset ? 'reset' : 'normal'} 
      enableSearch={true}
       countryCodeEditable={false}
      placeholder="Enter Phone Number(Optional)"
      value={phoneNumber}
      onChange={(value, country) => onChangeValue(value, country)}
      required
    />    
  );
};


