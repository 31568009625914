import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IMAGES } from "../../assets/images";
import {
  getExportProjects,
  getSelectedExportProject,
} from "../../store/actions/authActions";
import { DataLoader, ImageLoader } from "../../components";
import { formatRemainingTime } from "../../utils/helper";
import { useExpirationCheck } from "../../components/Hooks/useExpirationCheck";

export const HomeExport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const webUrl = useLocation();
  const exportProjects = useSelector(({ auth }) => auth?.exportProjects) || [];
  const companyLogo = useSelector(({ auth }) => auth?.loginUser?.logo);
console.log('companyLogo======' ,companyLogo)
  const { isLinkExpired, remainingTime } = useExpirationCheck(
    exportProjects?.linkExpiredTime,
    exportProjects?.projectIds
  );
   console.log('exportProjects=======',exportProjects)
  const uniqueId = webUrl?.pathname?.split("/")?.pop();
  // const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [imageLoaded, setImageLoaded] = useState({}); // State to track loaded images
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  console.log(
    "isLinkExpired || !message?.data?.success",
    !message?.data?.success,
    "message",
    message
  );

  const handleImageLoad = (index) => {
    setImageLoaded((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };

  useEffect(() => {
    if (uniqueId !== undefined) {
      if (uniqueId !== undefined) {
        setIsLoading(true);
        dispatch(getExportProjects(uniqueId))
          .then((res) => {
            if (res?.data) {
              setMessage(res?.data);
            }
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          }).finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [dispatch, uniqueId]);

  return (
    <>
      {isLoading ? (
        <DataLoader width={100} />
      ) : (
        <div className="exportSection">
          {exportProjects?.projectIds?.length > 0 && (
          <div className="exHeader">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 "></div>
                <div className="col-lg-6 col-md-7 col-sm-6">
                  <img src={companyLogo} alt="" className="img-fluid comapnycuslogo" />
                </div>
                <div className="col-lg-3 col-md-5 col-sm-6">                
                    <span className="expiryTime">
                      Expiring in : {formatRemainingTime(remainingTime)}
                    </span>                   
                </div>
              </div>
            </div>            
          </div>
         )}

          {isLinkExpired || !message?.success ? (
            <div className="loginBg">
              <div className="container-fluid">
                <div className="align-self-center pad-0 form-section">
                  <div className="mainLoginbox text-center pageNotFoundBox">
                    <img src={IMAGES.PageNotFoundImg} alt="" className="img-fluid" />
                    {/* <h1>Link Expired</h1> */}
                    <p className="mt-4 text-capitalize">{message?.message }</p>
                    <button
                      className="formButton w-auto ps-4 pe-4"
                      onClick={() => navigate("/projects")}
                    >
                      Go Back Dashboard
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="portfolioSection">
              <div className="container-fluid">
                <div className="row">
                  {exportProjects?.projectIds?.length > 0 ? (
                    exportProjects?.projectIds?.map((project, index) => (
                      <div
                        className="col-xxl-Cus"
                        key={`project ${index + 1}`}
                        onClick={() => {
                          dispatch(
                            getSelectedExportProject({ ...project, uniqueId })
                          )?.then((res) => {
                            navigate("/project-details");
                          });
                        }}
                      >
                        <div className="portfoliogrid">
                          {!imageLoaded[index] && (
                            <div className="image-loader">
                              <ImageLoader color={"#f9896b"} />
                            </div>
                          )}
                          <img
                            src={`${project?.logo}`}
                            alt=""
                            className="img-fluid"
                            onLoad={() => handleImageLoad(index)}
                            style={{
                              display: imageLoaded[index] ? "block" : "none",
                            }}
                          />
                          <div className="p-3 d-flex align-items-center gap-2">
                            <span>
                              <h2>{project?.name}</h2>
                              <p>{project?.description}</p>
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="loginBg">
                      <div className="container-fluid">
                        <div className="align-self-center pad-0 form-section">
                          <div className="mainLoginbox text-center pageNotFoundBox">
                            <img src={IMAGES.PageNotFoundImg} alt="" />
                            {/* <h1>Link Expired</h1> */}
                            <h4 className="mt-4 text-capitalize">
                              data not found
                            </h4>
                            <button
                              className="formButton"
                              onClick={() => navigate("/projects")}
                            >
                              Go Back Dashboard
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="exportFootBx">
            <div className="container-fluid ">
              <p>
                Powered by <a href="https://bytecipher.net/">ByteCipher</a>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
