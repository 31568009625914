import React, { useRef, useState, useCallback } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const ImageCrop = ({ src, getCropImage, cancelModal }) => {
  const imgRef = useRef();

  const [crop, setCrop] = useState({
    aspect: 1,
    unit: "%", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });
  const [completedCrop, setCompletedCrop] = useState({
    unit: "px",
    x: 128,
    y: 96.02734375,
    width: 251.28076171875,
    height: 201.28076171875,
  });

  const onLoad = useCallback((img) => {
    imgRef.current = img;
    const width = img.width;
    const height = img.height;
    const initialCrop = {
      unit: "%",
      width: 50,
      height: 50,
      x: 25,
      y: 25,
      aspect: 1,
    };
    setCrop(initialCrop);
    setCompletedCrop(initialCrop);
  }, []);

  const getCroppedImg = useCallback(() => {
    const image = imgRef.current;
    const canvas = document.createElement("canvas");
    const crop = completedCrop;

    if (!crop || !image) {
      return;
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob((blob) => {
      if (!blob) {
        console.error("Canvas is empty");
        return;
      }

      const fileUrl = window.URL.createObjectURL(blob);

      const img = new Image();
      img.src = fileUrl;
      img.onload = function () {
        // Now you can access properties like name, lastModified, etc.
        console.log("Image object:", img);
      };
      const image = canvas?.toDataURL();

      getCropImage(image);
    }, "image/png");
  }, [completedCrop]);

  return (
    <div>
      <ReactCrop
        src={src}
        crop={crop}
        onChange={(newCrop) => setCrop(newCrop)}
        onComplete={(c) => setCompletedCrop(c)}
        onImageLoaded={onLoad}
        aspect={1}
      >
        <img ref={imgRef} src={src} alt="Crop me" />
      </ReactCrop>
      {/* <button onClick={getCroppedImg}>Download Cropped Image</button> */}
      <div className="footbutton">
        <button type="button" onClick={cancelModal} className="cancelBtn">
          Cancel
        </button>
        <button type="submit" onClick={getCroppedImg}>
          {"Crop Image"}
        </button>
      </div>
    </div>
  );
};

export default ImageCrop;

// // import React, { useRef, useState, useCallback } from 'react'
// // import ReactCrop from 'react-image-crop'
// // import 'react-image-crop/dist/ReactCrop.css'

// // const ImageCrop = ({ src, getCropImage, cancelModal }) => {
// //   console.log('scr======',src)
// //   const imgRef = useRef();

// //   const [crop, setCrop] = useState({
// //     aspect: 1,
// //     unit: '%',
// //     x: 25,
// //     y: 25,
// //     width: 50,
// //     height: 50
// //   });
// //   const [completedCrop, setCompletedCrop] = useState(null);

// //   const onLoad = useCallback((img) => {
// //     imgRef.current = img;
// //     const initialCrop = {
// //       unit: '%',
// //       width: 50,
// //       height: 50,
// //       x: 25,
// //       y: 25,
// //       aspect: 1,
// //     };
// //     setCrop(initialCrop);
// //     setCompletedCrop(initialCrop);
// //   }, []);

// //   const getCroppedImg = useCallback(() => {
// //     const image = imgRef.current;
// //     const canvas = document.createElement('canvas');
// //     const crop = completedCrop || crop; // Use the initial crop if completedCrop is not set

// //     if (!crop || !image) {
// //       return;
// //     }

// //     const scaleX = image.naturalWidth / image.width;
// //     const scaleY = image.naturalHeight / image.height;
// //     canvas.width = crop.width;
// //     canvas.height = crop.height;
// //     const ctx = canvas.getContext('2d');

// //     ctx.drawImage(
// //       image,
// //       crop.x * scaleX,
// //       crop.y * scaleY,
// //       crop.width * scaleX,
// //       crop.height * scaleY,
// //       0,
// //       0,
// //       crop.width,
// //       crop.height
// //     );

// //     canvas.toBlob((blob) => {
// //       if (!blob) {
// //         console.error('Canvas is empty');
// //         return;
// //       }
// //       console.log('blob----', blob);

// //       const fileUrl = window.URL.createObjectURL(blob);

// //       const img = new Image();
// //       img.src = fileUrl;
// //       img.onload = function () {
// //         // Now you can access properties like name, lastModified, etc.
// //         console.log('Image object:', img);
// //       };
// //       const image = canvas?.toDataURL();

// //       getCropImage(image);
// //     }, 'image/png');
// //   }, [completedCrop, crop, getCropImage]);

// //   return (
// //     <div>
// //       <ReactCrop
// //         src={src}
// //         crop={crop}
// //         onChange={(newCrop) => setCrop(newCrop)}
// //         onComplete={(c) => setCompletedCrop(c)}
// //         onImageLoaded={onLoad}
// //         aspect={1}
// //       />
// //       <div className="footbutton">
// //         <button
// //           type="button"
// //           onClick={cancelModal}
// //           className="cancelBtn"
// //         >
// //           Cancel
// //         </button>
// //         <button type="submit" onClick={getCroppedImg} >
// //           {"Crop Image"}
// //         </button>
// //       </div>
// //     </div>
// //   );
// // }

// // export default ImageCrop;
