// import React, { useEffect, useState, useCallback, useMemo } from "react";
// import CreatableSelect from 'react-select/creatable';
// import { components } from "react-select";
// // Custom MultiValue component to show selected items
// const CustomMultiValue = (props) => {
//     const MAX_DISPLAY = 1;
//     const { index, getValue } = props;
//     const selectedValues = getValue();

//     if (index < MAX_DISPLAY) {
//         return <components.MultiValue {...props} />;
//     } else if (index === MAX_DISPLAY) {
//         return (
//             <components.MultiValue {...props}>
//                 <span style={{ marginLeft: 4, fontSize: '0.875em', color: '#999' }}>
//                     {`+ ${selectedValues.length - MAX_DISPLAY} more`}
//                 </span>
//             </components.MultiValue>
//         );
//     } else {
//         return null;
//     }
// };

// // Custom styles for ReactSelect
// const customStyles = {
//     option: (base, state) => ({
//         ...base,
//         backgroundColor: state.isSelected ? '#d0e0fc' : state.isFocused ? '#f0f8ff' : '#fff',
//         color: state.isSelected ? '#0a58ca' : '#333',
//         ':active': {
//             backgroundColor: state.isSelected ? '#d0e0fc' : '#f0f8ff',
//         },
//         margin: '4px 0',
//        padding: '8px 12px',
//     }),
// };

// export const PlatformDropdown = ({ sortedData, setSortedData, platformTypes, Formik, setSelectedPlatformData,setNewPlatformTypes }) => {
//     const [platformFilter, setPlatformFilter] = useState([]);

//     const sortSelectedToTop = useCallback((options, selectedValues) => {
//         if (!selectedValues || selectedValues.length === 0) return options;
//         const selectedValuesSet = new Set(selectedValues.map(item => item.value));
//         const selectedOptions = options.filter(option => option.value !== "all" && selectedValuesSet.has(option.value));
//         const unselectedOptions = options.filter(option => option.value !== "all" && !selectedValuesSet.has(option.value));
//         const allOption = options.find(option => option.value === "all");
//         return allOption ? [allOption, ...selectedOptions, ...unselectedOptions] : [...selectedOptions, ...unselectedOptions];
//     }, []);

//     const platformOptions = useMemo(() => {
//         return platformTypes?.length > 0
//             ? [{ value: "all", label: "All" }, ...platformTypes.map((platform) => ({ value: platform?._id, label: platform?.title }))]
//             : [];
//     }, [platformTypes]);

//     // const mappedPlatformIds = (data) => data?.map((platform) => platform?.value);
//     const mappedPlatformIds = (data) => {
//         return data?.filter((value) => !value?.__isNew__).map((platform) => platform?.value);
//     }
//     const normalizePlatform = (platform) => platform?.map((data) => data?.label?.toLowerCase());
//     const mappedNewOptions = (data) => {
//         return data?.filter((value) => value?.__isNew__).map((option) => ({ title: option?.value?.toLowerCase(), remark: "" }));
//     }
//     const handleChange = useCallback((selectedOptions) => {
//         let optionsToSet = selectedOptions;

//         if (selectedOptions.some(option => option.value === "all")) {
//             optionsToSet = platformOptions.filter(option => option.value !== "all");
//         }

//         setPlatformFilter(optionsToSet);
//         const normalizedValue = normalizePlatform(optionsToSet);
//         setSelectedPlatformData(normalizedValue);
//         Formik?.setFieldValue('platformType', normalizedValue);
//         setSortedData(mappedPlatformIds(optionsToSet));
//         setNewPlatformTypes(mappedNewOptions(optionsToSet));
//         Formik?.setFieldValue('platformDetails',mappedNewOptions(optionsToSet))
//         Formik?.setFieldValue('platformId', mappedPlatformIds(optionsToSet) || []);
//         // eslint-disable-next-line
//     }, [platformOptions, Formik, setSelectedPlatformData, setSortedData]);

//     useEffect(() => {
//         if (sortedData && sortedData.length > 0) {
//             const matchingOptions = platformOptions.filter(option =>
//                 sortedData.some(data => data === option.value)
//             );
//             setPlatformFilter(matchingOptions);
//         } else {
//             setPlatformFilter([]);
//         }
//     }, [sortedData, platformOptions]);


//     const sortedOptions = useMemo(() => sortSelectedToTop(platformOptions, platformFilter), [platformOptions, platformFilter, sortSelectedToTop]);
//     useEffect(()=>{
//         setPlatformFilter(platformFilter)
//       },[platformFilter])
//     return (
//       <CreatableSelect
//         isMulti
//         isSearchable
//         isClearable
//         className="react-select-container"
//         placeholder="Select Platform"
//         classNamePrefix="react-select"
//         onChange={handleChange}
//         value={platformFilter}
//         options={sortedOptions}
//         components={{ MultiValue: CustomMultiValue }}
//         styles={customStyles}
//         hideSelectedOptions={false}
//     />
        
//     );
// };

import React, { useEffect, useState, useCallback, useMemo } from "react";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";

// Custom MultiValue component to show selected items
const CustomMultiValue = (props) => {
  const MAX_DISPLAY = 1;
  const { index, getValue } = props;
  const selectedValues = getValue();

  if (index < MAX_DISPLAY) {
    return <components.MultiValue {...props} />;
  } else if (index === MAX_DISPLAY) {
    return (
      <components.MultiValue {...props}>
        <span style={{ marginLeft: 4, fontSize: "0.875em", color: "#999" }}>
          {`+ ${selectedValues.length - MAX_DISPLAY} more`}
        </span>
      </components.MultiValue>
    );
  } else {
    return null;
  }
};

// Custom styles for ReactSelect
const customStyles = {
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? "#d0e0fc" : state.isFocused ? "#f0f8ff" : "#fff",
    color: state.isSelected ? "#0a58ca" : "#333",
    ":active": {
      backgroundColor: state.isSelected ? "#d0e0fc" : "#f0f8ff",
    },
    margin: "4px 0",
    padding: "8px 12px",
  }),
};

export const PlatformDropdown = ({
  sortedData,
  setSortedData,
  platformTypes,
  Formik,
  setSelectedPlatformData,
  setNewPlatformTypes,
}) => {
  const [platformFilter, setPlatformFilter] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const sortSelectedToTop = useCallback((options, selectedValues) => {
    if (!selectedValues || selectedValues.length === 0) return options;
    const selectedValuesSet = new Set(selectedValues.map((item) => item.value));
    const selectedOptions = options.filter(
      (option) => option.value !== "all" && selectedValuesSet.has(option.value)
    );
    const unselectedOptions = options.filter(
      (option) => option.value !== "all" && !selectedValuesSet.has(option.value)
    );
    const allOption = options.find((option) => option.value === "all");
    return allOption
      ? [allOption, ...selectedOptions, ...unselectedOptions]
      : [...selectedOptions, ...unselectedOptions];
  }, []);

  const platformOptions = useMemo(() => {
    return platformTypes?.length > 0
      ? [{ value: "all", label: "All" }, ...platformTypes.map((platform) => ({ value: platform?._id, label: platform?.title }))]
      : [];
  }, [platformTypes]);

  const mappedPlatformIds = (data) => {
    return data?.filter((value) => !value?.__isNew__).map((platform) => platform?.value);
  };

  const normalizePlatform = (platform) => platform?.map((data) => data?.label?.toLowerCase());

  const mappedNewOptions = (data) => {
    return data?.filter((value) => value?.__isNew__).map((option) => ({ title: option?.value?.toLowerCase(), remark: "" }));
  };

  const handleChange = useCallback((selectedOptions) => {
    let optionsToSet = selectedOptions;

    if (selectedOptions.some((option) => option.value === "all")) {
      optionsToSet = platformOptions.filter((option) => option.value !== "all");
    }

    setPlatformFilter(optionsToSet);
    const normalizedValue = normalizePlatform(optionsToSet);
    setSelectedPlatformData(normalizedValue);
    Formik?.setFieldValue("platformType", normalizedValue);
    setSortedData(mappedPlatformIds(optionsToSet));
    setNewPlatformTypes(mappedNewOptions(optionsToSet));
    Formik?.setFieldValue("platformDetails", mappedNewOptions(optionsToSet));
    Formik?.setFieldValue("platformId", mappedPlatformIds(optionsToSet) || []);
    // eslint-disable-next-line
  }, [platformOptions, Formik, setSelectedPlatformData, setSortedData]);

  const handleInputChange = (newValue) => {
    if (newValue.length <= 30) {
      setInputValue(newValue);
    }
  };

  const handleCreate = (inputValue) => {
    if (inputValue.length <= 30) {
      const newOption = { value: inputValue, label: inputValue, __isNew__: true };
      handleChange([...platformFilter, newOption]);
    }
  };

  useEffect(() => {
    if (sortedData && sortedData.length > 0) {
      const matchingOptions = platformOptions.filter((option) =>
        sortedData.some((data) => data === option.value)
      );
      setPlatformFilter(matchingOptions);
    } else {
      setPlatformFilter([]);
    }
  }, [sortedData, platformOptions]);

  const sortedOptions = useMemo(() => sortSelectedToTop(platformOptions, platformFilter), [platformOptions, platformFilter, sortSelectedToTop]);

  useEffect(() => {
    setPlatformFilter(platformFilter);
  }, [platformFilter]);

  return (
    <CreatableSelect
      isMulti
      isSearchable
      isClearable
      className="react-select-container"
      placeholder="Select Platform"
      classNamePrefix="react-select"
      onChange={handleChange}
      onInputChange={handleInputChange}
      onCreateOption={handleCreate}
      inputValue={inputValue}
      value={platformFilter}
      options={sortedOptions}
      components={{ MultiValue: CustomMultiValue }}
      styles={customStyles}
      hideSelectedOptions={false}
    />
  );
};

