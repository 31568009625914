// import { useState, useEffect } from "react";

// export const usePortfolioExpiration = (linkExpiredTime) => {
//   const [remainingTime, setRemainingTime] = useState(0);

//   useEffect(() => {
//     const checkExpiration = () => {
//       const currentTime = new Date().getTime();
//       const timeRemaining = linkExpiredTime - currentTime;

//       if (timeRemaining <= 0) {
//         setRemainingTime(0);
//       } else {
//         setRemainingTime(timeRemaining);
//       }
//     };

//     // Check immediately in case the link is already expired
//     checkExpiration();

//     // Set up a timer to check expiration and update the remaining time periodically
//     const intervalId = setInterval(checkExpiration, 1000);

//     // Cleanup interval on component unmount
//     return () => clearInterval(intervalId);
//   }, [linkExpiredTime]);

//   return { remainingTime };
// };
// src/components/Hooks/usePortfolioListExpiration.js

import { useState, useEffect } from "react";

export const usePortfolioExpiration = (portfolios) => {
  const [remainingTimes, setRemainingTimes] = useState({});

  useEffect(() => {
    const calculateRemainingTimes = () => {
      const times = {};
      portfolios?.forEach((portfolio) => {
        const currentTime = new Date().getTime();
         const timeRemaining = portfolio?.linkExpiredTime - currentTime;
        times[portfolio._id] = timeRemaining > 0 ? timeRemaining : 0;
      });
      setRemainingTimes(times);
    };

    calculateRemainingTimes();

    const intervalId = setInterval(calculateRemainingTimes, 1000);

    return () => clearInterval(intervalId);
  }, [portfolios]);

  return remainingTimes;
};
