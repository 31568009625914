import React from "react";
import { IMAGES } from "../../assets/images";

// pagination component with conditional rendering
export const PaginationView = ({ totalPages, pageNumber, setPageNumber }) => {
 
  const pageNumbers =
    totalPages >= 1 ? [...Array(totalPages + 1)?.keys()]?.slice(1) : 0;

  const nextPage = () => {
    if (pageNumber !== totalPages && totalPages !== 1)
      setPageNumber(pageNumber + 1);
  };
  const prevPage = () => {
    if (pageNumber !== 1) setPageNumber(pageNumber - 1);
  };

  return (
    // <>
    //   <ul>
    //     <li
    //       onClick={prevPage}
    //       className={pageNumber === 1 ? "cursor-no-drop" : ""}
    //     >
    //       <img src={IMAGES.back_Shape} alt="" className="tickActive" />
    //       <img src={IMAGES.back_ShapeRight} alt="" className="tickInctive" />
    //     </li>


    //     <li
    //       className={pageNumber === totalPages ? "cursor-no-drop" : ""}
    //       onClick={nextPage}
    //     >
    //       <img src={IMAGES.back_Shape} alt="" className="tickActive" />
    //       <img src={IMAGES.back_ShapeRight} alt="" className="tickInctive" />
    //     </li>
    //   </ul>
    // </>
    <>
      <ul>
        <li><button className="previousBtn"  onClick={prevPage}><img src={IMAGES.PreviousIcon} alt="" />Previous</button></li>
        {/* <li><button className="active"> 1</button></li> */}
        {pageNumbers?.length >= 0 ? (
          pageNumbers?.map((pgNumber) => (

            <li key={pgNumber}  onClick={() => setPageNumber(pgNumber)}><button className={pageNumber === pgNumber ? "active" : ""}>  {pgNumber <= 9 ? "0" + pgNumber : pgNumber}</button></li>
          ))
        ) : (
          <li><button>1</button></li>
        )}


        {/* <li><button>3</button></li>
        <li><button>...</button></li>
        <li><button>8</button></li>
        <li><button>9</button></li>
        <li><button>10</button></li> */}
        <li><button className="nextBtn" onClick={nextPage}>Next <img src={IMAGES.NextIcon} alt=""  /></button></li>
      </ul>
    </>
  );
};
