import AWS from "aws-sdk";
import { Buffer } from "buffer";
import { awsConfig } from "../config/config";
import { bytesToMb } from "./helper";

Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;

const {
  AWS_ACCESS_KEY_ID: S3_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY: S3_SECRET_ACCESS_KEY,
  AWS_BUCKET: S3_BUCKET,
  AWS_REGION: S3_REGION,
  AWS_ENDPOINT: S3_ENDPOINT,
} = awsConfig || {};

const endpoint = new AWS.Endpoint(S3_ENDPOINT);

// SDK Client configurations
AWS.config.update({
  accessKeyId: S3_ACCESS_KEY_ID,
  secretAccessKey: S3_SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3({
  endpoint: endpoint,
  region: S3_REGION,
  params: { Bucket: S3_BUCKET },
});
/**
 * Calculate an appropriate part size for S3 multipart uploads
 * @param {number} fileSize - The size of the file in bytes
 * @param {number} maxParts - The maximum number of parts (default: 10,000)
 * @returns {number} - The calculated part size in bytes
 */
const calculatePartSize = (fileSize, maxParts = 10000) => {
  // Minimum part size for S3 multipart upload is 5MB
  const minPartSize = 80 * 1024 * 1024;
  let partSize = Math.ceil(fileSize / maxParts) * 1024 * 1024;
  if (partSize < minPartSize) {
    partSize = minPartSize;
  }
  return partSize;
};

export const multiFileUpload = async (
  keyName,
  file,
  onProgress,
  updateOverallProgress,
  totalFiles
) => {
  if (awsConfig) {
    // Set the S3 object parameters
    const params = {
      Bucket: S3_BUCKET,
      Key: keyName,
      Body: file,
      ACL: 'public-read',
    };
    // Calculate the appropriate part size
    const partSize = calculatePartSize(file.size);
    console.log('partSize',bytesToMb(partSize));

    const options = { partSize: partSize, queueSize: 1 };

    let prevLoaded = 0;

    try {
      const upload = s3.upload(params, options);
     await upload.on("httpUploadProgress", async(event) => {
        if (onProgress) {
          const fileProgress = event.loaded / event.total;
          const incrementalProgress = fileProgress - prevLoaded;
          prevLoaded = fileProgress;
          updateOverallProgress(incrementalProgress, totalFiles);
          onProgress(Math.round(fileProgress * 100));
        }
      });

      upload.on("httpError", async (err) => {
        if (err.statusCode === 204) {
          console.error(
            "Received 204 No Content response. Skipping this part."
          );
        } else {
          console.error("Error uploading part:", err);
          throw err;
        }
      });

      const data = await upload.promise();
      console.log("File uploaded successfully:", data);
      return data.Location;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  }
};

export const fileUpload = async (keyName, file, onProgress, totalFiles) => {
  if (awsConfig) {
    // Set the S3 object parameters
    const params = {
      Bucket: S3_BUCKET,
      Key: keyName,
      Body: file,
      ACL: 'public-read',
    };

    const options = { partSize: calculatePartSize(file.size), queueSize: 1 };

    try {
      const upload = s3.upload(params, options);

      upload.on("httpUploadProgress", (event) => {
        if (onProgress) {
          onProgress(Math.round((event.loaded / event.total) * 100));
        }
      });

      upload.on("httpError", async (err) => {
        if (err.statusCode === 204) {
          console.error(
            "Received 204 No Content response. Skipping this part."
          );
        } else {
          console.error("Error uploading part:", err);
          throw err;
        }
      });

      const data = await upload.promise();

      // const data = await s3.upload(params, options).promise();
      console.log("File uploaded successfully:", data);
      return data.Location;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  }
};

export const profileUpload = async (keyName, base64ImageData, onProgress) => {
  if (awsConfig) {
    // Convert Base64 to Blob
    const blob = base64toBlob(base64ImageData);

    // Set the S3 object parameters
    const params = {
      Bucket: S3_BUCKET,
      Key: keyName,
      Body: blob,
      ACL: 'public-read',
    };

    const options = { partSize: calculatePartSize(blob.size), queueSize: 1 };

    try {
      const upload = s3.upload(params, options);

      upload.on("httpUploadProgress", (event) => {
        if (onProgress) {
          onProgress(Math.round((event.loaded / event.total) * 100));
        }
      });

      const data = await upload.promise();

      console.log("File uploaded successfully:", data);
      return data.Location; // Return the URL of the uploaded image
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  }
};

// Function to convert Base64 to Blob
function base64toBlob(base64Data) {
  const byteString = atob(base64Data.split(',')[1]);
  const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];

  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: mimeString });
}


export const videoFileUpload = async (
  keyName,
  file,
  onProgress,
  updateOverallProgress,
  totalFiles
) => {
  if (awsConfig) {
    // Set the S3 object parameters
    const params = {
      Bucket: S3_BUCKET,
      Key: keyName,
      Body: file,
      ACL: "public-read",
    };

    // Calculate the appropriate part size
    const partSize = calculatePartSize(file.size);

    const options = { partSize: partSize, queueSize: 1 };

    let prevLoaded = 0;

    try {
      // console.log("params", params, "myBucket", s3);
      const upload = s3.upload(params, options);
      upload.on("httpUploadProgress", (event) => {
        if (onProgress) {
          const fileProgress = event.loaded / event.total;
          const incrementalProgress = fileProgress - prevLoaded;
          prevLoaded = fileProgress;
          updateOverallProgress(incrementalProgress, totalFiles);
          onProgress(Math.round(fileProgress * 100));
        }
      });

      upload.on("httpError", async (err) => {
        if (err.statusCode === 204) {
          console.error(
            "Received 204 No Content response. Skipping this part."
          );
        } else {
          console.error("Error uploading part:", err);
          throw err;
        }
      });

      const data = await upload.promise();
      console.log("File uploaded successfully:", data);
      return data.Location;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  }
};

/**
 * Delete a file from AWS S3
 * @param {string} keyName - The key name of the file to delete
 * @param {Object} awsCredentials - The AWS credentials object
 * @returns {Promise} - A promise that resolves when the file is deleted
 */
export const deleteFile = async (keyName, awsCredentials) => {
  if (awsConfig) {
    // Set the S3 object parameters
    const params = {
      Bucket: S3_BUCKET,
      Key: keyName,
    };

    try {
      const data = await s3.deleteObject(params).promise();
      console.log("File deleted successfully:", data);
      return data;
    } catch (error) {
      console.error("Error deleting file:", error);
      throw error;
    }
  }
};

// import AWS from "aws-sdk";
// import { Buffer } from "buffer";
// import { awsConfig } from "../config/config";

// // Ensure Buffer is available
// Buffer.from("anything", "base64");

// // Extract necessary configurations
// const {
//   AWS_ACCESS_KEY_ID: S3_ACCESS_KEY_ID,
//   AWS_SECRET_ACCESS_KEY: S3_SECRET_ACCESS_KEY,
//   AWS_BUCKET: S3_BUCKET,
//   AWS_REGION: S3_REGION,
//   AWS_ENDPOINT: S3_ENDPOINT,
//   AWS_ACL: ACL,
// } = awsConfig || {};

// // Configure AWS
// AWS.config.update({
//   accessKeyId: S3_ACCESS_KEY_ID,
//   secretAccessKey: S3_SECRET_ACCESS_KEY,
// });

// const endpoint = new AWS.Endpoint(S3_ENDPOINT);
// const s3 = new AWS.S3({
//   endpoint: endpoint,
//   region: S3_REGION,
//   params: { Bucket: S3_BUCKET },
// });

// /**
//  * Calculate an appropriate part size for S3 multipart uploads
//  * @param {number} fileSize - The size of the file in bytes
//  * @param {number} maxParts - The maximum number of parts (default: 10,000)
//  * @returns {number} - The calculated part size in bytes
//  */
// const calculatePartSize = (fileSize, maxParts = 10000) => {
//   const minPartSize = 100 * 1024 * 1024;
//   let partSize = Math.ceil(fileSize / maxParts) * 1024 * 1024;
//   if (partSize < minPartSize) {
//     partSize = minPartSize;
//   }
//   return partSize;
// };

// /**
//  * Handle file upload to S3 with progress updates
//  * @param {Object} params - Parameters for S3 upload
//  * @param {Object} options - Options for S3 upload
//  * @param {Function} onProgress - Progress callback
//  * @param {Function} updateOverallProgress - Overall progress update callback
//  * @param {number} totalFiles - Total number of files
//  * @returns {Promise} - Promise resolving with upload location
//  */
// const handleUpload = async (params, options, onProgress, updateOverallProgress, totalFiles) => {
//   let prevLoaded = 0;

//   try {
//     const upload = s3.upload(params, options);

//     upload.on("httpUploadProgress", (event) => {
//       if (onProgress) {
//         const fileProgress = event.loaded / event.total;
//         const incrementalProgress = fileProgress - prevLoaded;
//         prevLoaded = fileProgress;
//         updateOverallProgress(incrementalProgress, totalFiles);
//         onProgress(Math.round(fileProgress * 100));
//       }
//     });

//     upload.on("httpError", (err) => {
//       if (err.statusCode === 204) {
//         console.error("Received 204 No Content response. Skipping this part.");
//       } else {
//         console.error("Error uploading part:", err);
//         throw err;
//       }
//     });

//     const data = await upload.promise();
//     console.log("File uploaded successfully:", data);
//     return data.Location;
//   } catch (error) {
//     console.error("Error uploading file:", error);
//     throw error;
//   }
// };

// export const multiFileUpload = async (keyName, file, onProgress, updateOverallProgress, totalFiles) => {
//   if (awsConfig) {
//     const params = { Bucket: S3_BUCKET, Key: keyName, Body: file, ACL: ACL };
//     const options = { partSize: calculatePartSize(file.size), queueSize: 1 };
//     return await handleUpload(params, options, onProgress, updateOverallProgress, totalFiles);
//   }
// };

// export const fileUpload = async (keyName, file, onProgress) => {
//   if (awsConfig) {
//     const params = { Bucket: S3_BUCKET, Key: keyName, Body: file, ACL: ACL };
//     const options = { partSize: calculatePartSize(file.size), queueSize: 1 };
//     return await handleUpload(params, options, onProgress, () => { }, 1);
//   }
// };

// export const videoFileUpload = async (keyName, file, onProgress, updateOverallProgress, totalFiles) => {
//   if (awsConfig) {
//     const params = { Bucket: S3_BUCKET, Key: keyName, Body: file, ACL: ACL };
//     const options = { partSize: calculatePartSize(file.size), queueSize: 1 };
//     return await handleUpload(params, options, onProgress, updateOverallProgress, totalFiles);
//   }
// };

// /**
//  * Delete a file from AWS S3
//  * @param {string} keyName - The key name of the file to delete
//  * @returns {Promise} - A promise that resolves when the file is deleted
//  */
// export const deleteFile = async (keyName) => {
//   if (awsConfig) {
//     const params = { Bucket: S3_BUCKET, Key: keyName };

//     try {
//       const data = await s3.deleteObject(params).promise();
//       console.log("File deleted successfully:", data);
//       return data;
//     } catch (error) {
//       console.error("Error deleting file:", error);
//       throw error;
//     }
//   }
// };
