import React, { useState } from "react";
import { WebHeaderView } from "./web-header";
import { WebFooterView } from "./web-footer";


import { useNavigate } from "react-router-dom";
import { AccordionContentView } from "./accordion-content";

export const FaqsView = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const navigate = useNavigate();

    return (
        <>
            <WebHeaderView />

            <div className="cusSlider faqsSlide">
                <div className="container-fluid">
                    <ul>
                        <li><button onClick={() => navigate("/")}>Home</button></li>
                        <li>|</li>
                        <li>FAQ's</li>
                    </ul>
                    <h1>FAQ's</h1>
                </div>
            </div>

            <div className="faq-accordion">
                <div className="container-fluid">
                    <div className='accordioan-container'>
                        <AccordionContentView
                            title={"1. What is Folio4u?"}
                            index={1}
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                        >
                            Folio4u is a versatile mobile application designed to help professionals and teams showcase their portfolios seamlessly 
                            across various devices, including iPads, Tablets, MacOS, and WindowsOS.
                        </AccordionContentView>
                        <AccordionContentView
                            title={"2. How can I use Folio4u?"}
                            index={2}
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                        >
                            Using Folio4u is simple! You can upload your portfolio through our admin portal, and it syncs automatically across your devices. 
                            This allows you to easily present your work to clients, during meetings, or any other professional setting.
                        </AccordionContentView>
                        <AccordionContentView
                            title={"3. What features does Folio4u offer?"}
                            index={3}
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                        >
                            Folio4u offers a range of features including:
                            <ul>
                                <li>Seamless integration across multiple platforms</li>
                                <li>Customizable themes and layouts for your portfolio</li>
                                <li>Real-time updates and collaboration tools</li>
                                <li>Security features like password protection and data encryption</li>
                            </ul>

                        </AccordionContentView>
                        <AccordionContentView
                            title={"4. Is Folio4u secure?"}
                            index={4}
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                        >
                            Yes, Folio4u prioritizes security. We use advanced encryption and secure storage practices to protect your data. Additionally, 
                            our platform is designed to ensure that your information remains private and secure at all times.
                        </AccordionContentView>
                        <AccordionContentView
                            title={"5. Can I use Folio4u offline?"}
                            index={5}
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                        >
                            Yes, Folio4u allows you to access and present your portfolio offline. Once synchronized, your portfolio content remains 
                            accessible even without an internet connection, ensuring uninterrupted presentations.
                        </AccordionContentView>
                        <AccordionContentView
                            title={"6. How can I get started with Folio4u?"}
                            index={6}
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                        >
                            Getting started with Folio4u is easy! Simply download the app from the App Store or Google Play, create your account, and begin 
                            uploading your portfolio content through our user-friendly admin portal.
                        </AccordionContentView>
                        <AccordionContentView
                            title={"7. Is there a free trial available for Folio4u?"}
                            index={7}
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                        >
                            Yes, we offer a free trial period for new users to explore Folio4u's features and functionality. This trial allows you to experience firsthand how 
                            Folio4u can benefit your professional portfolio management needs.

                        </AccordionContentView>
                        <AccordionContentView
                            title={"8. How often is Folio4u updated?"}
                            index={8}
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                        >
                            We regularly update Folio4u to introduce new features, improve performance, and address user feedback. Updates are provided automatically through app stores, 
                            ensuring you always have access to the latest enhancements.
                        </AccordionContentView>
                        <AccordionContentView
                            title={"9. Where can I find support or get help with Folio4u?"}
                            index={9}
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                        >
                            For assistance with Folio4u or any questions you may have, please visit our support page on our website or contact our customer support team at [Contact Information]. 
                            We're here to help you make the most out of Folio4u!
                        </AccordionContentView>                       
                    </div>
                </div>
            </div>

            <WebFooterView />
        </>
    );
};