import { SubscriptionApiEndpoints } from "../apiEndpoints";
import { Instance } from "../apiServices";
import { subscriptionActions } from "./actionTypes";
import { refreshToken } from "./authActions";

export const getSubscriptions = () => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "GET", //method
      SubscriptionApiEndpoints.GET_SUBSCRIPTIONS_API, //api-endpoint
      null, //data/params
      true //isAuth
    );
    if (response?.status === 200 && response?.data?.success) {
      dispatch({
        type: subscriptionActions.GET_SUBSCRIPTIONS,
        payload: response?.data?.data,
      });
      return response;
    } else if (
      response?.response?.status === 403 &&
      !response?.response?.data?.success
    ) {
      const res = await dispatch(refreshToken());
      if (res?.data?.success && res?.status === 200) {
        dispatch(getSubscriptions());
      }
    } else {
      return response;
    }
  };
};

export const getCurrentSubscription = () => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "GET", //method
      SubscriptionApiEndpoints.GET_CURRENT_SUBSCRIPTIONS_API, //api-endpoint
      null, //data/params
      true //isAuth
    );
    if (response?.status === 200 && response?.data?.success) {
      dispatch({
        type: subscriptionActions.GET_CURRENT_SUBSCRIPTION,
        payload: response?.data?.data,
      });
      return response;
    } else {
      return response.response;
    }
  };
};

export const getSubscriptionPaymentId = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "POST", //method
      SubscriptionApiEndpoints.GET_SUB_PAYMENT_ID_API, //api-endpoint
      params, //data/params
      true //isAuth
    );

    if (response?.status === 200 && response?.data?.success) {
      return response;
    } else if (
      response?.response?.status === 403 &&
      !response?.response?.data?.success
    ) {
      const res = await dispatch(refreshToken());
      if (res?.data?.success && res?.status === 200) {
        dispatch(getSubscriptionPaymentId(params));
      }
    } else {
      return response;
    }
  };
};

export const sendSubscriptionPaymentObject = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "POST", //method
      SubscriptionApiEndpoints.SEND_PAYMENT_OBJECT_API, //api-endpoint
      params, //data/params
      true //isAuth
    );

    if (response?.status === 200 && response?.data?.success) {
      return response;
    } else if (
      response?.response?.status === 403 &&
      !response?.response?.data?.success
    ) {
      const res = await dispatch(refreshToken());
      if (res?.data?.success && res?.status === 200) {
        dispatch(sendSubscriptionPaymentObject(params));
      }
    } else {
      return response;
    }
  };
};

export const cancelSubscription = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "POST", //method
      SubscriptionApiEndpoints.CANCEL_SUBSCRIPTION_API, //api-endpoint
      params, //data/params
      true //isAuth
    );

    if (response?.status === 200 && response?.data?.success) {
      return response;
    } else {
      return response;
    }
  };
};
