export const authActions = {
  AUTH_LOGIN: "AUTH_LOGIN",
  AUTH_LOGOUT: "AUTH_LOGOUT",
  GET_CATEGORY_DATA: "GET_CATEGORY_DATA",
  PROFILE_UPDATE: "PROFILE_UPDATE",
  GET_EXPORT_PROJECTS: "GET_EXPORT_PROJECTS",
  GET_SELECTED_PROJECT: "GET_SELECTED_PROJECT",
  REFRESH_TOKEN:"REFRESH_TOKEN",
  GET_PROFILE:"GET_PROFILE",
  TOGGLE_PRESSED:"TOGGLE_PRESSED"
};

export const projectActions = {
  DOMAIN_TYPES: "DOMAIN_TYPES",
  TECHNOLOGY_TYPES: "TECHNOLOGY_TYPES",
  PLATFORM_TYPES: "PLATFORM_TYPES",
  GET_PROJECT_LIST: "GET_PROJECT_LIST",
  DELETE_PROJECT: "DELETE_PROJECT",
  GET_SELECTED_PROJECT_DATA: "GET_SELECTED_PROJECT_DATA",
};

export const domainActions = {
  GET_DOMAIN_LIST: "GET_DOMAIN_LIST",
};

export const platformActions = {
  GET_PLATFORM_LIST: "GET_PLATFORM_LIST",
};

export const technologyActions = {
  GET_TECHNOLOGY_LIST: "GET_TECHNOLOGY_LIST",
};

export const subscriptionActions = {
  GET_SUBSCRIPTIONS: "GET_SUBSCRIPTIONS",
  GET_CURRENT_SUBSCRIPTION: "GET_CURRENT_SUBSCRIPTION",
};

export const credentialsActions = {
  GET_CREDENTIALS: "GET_CREDENTIALS",
};

export const sharedPortfolioActions={
  GET_SHAREDPORTFOLIO_LIST: "GET_SHAREDPORTFOLIO_LIST",
  DELETE_PORTFOLIO: "DELETE_PORTFOLIO", 
}

export const organizationRatingActions={
  GET_RATING_CUSTOMER_COUNT :"GET_RATING_CUSTOMER_COUNT",
}

export const UsersActions = {
  GET_USERS_LIST: "GET_USERS_LIST",
};
