import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toastr } from "react-redux-toastr";
import {
  ButtonLoader,
  DataLoader,
  DomainDropdown,
  HeaderView,
  ItemsPerPageDropdown,
  ModalLayout,
  PlatformDropdown,
  SidebarView,
  SubscriptionModal,
  Switch,
  TechnologyDropdown,
} from "../components";
import {
  deleteProject,
  getDomainTypes,
  getProjectList,
  updateProject,
} from "../store/actions/projectActions";
import { IMAGES } from "../assets/images";
import { projectActions } from "../store/actions/actionTypes";
import Pagination from "../components/Pagination/Pagination";
import { SVGIMAGES } from "../assets/svgImages";
import { deleteFile } from "../utils/S3-helper";
import { extractKeyNameFromUrl } from "../utils/helper";

export const ProjectView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const collapseSidebar = useSelector(({ auth }) => auth?.collapseSidebar);
  const domainTypes = useSelector(({ projects }) => projects?.domainTypes);
  const platformTypes = useSelector(({ projects }) => projects?.platformTypes);
  const subscriptions = useSelector(
    ({ subscription }) => subscription?.subscriptions
  );

  const currentSubscription = useSelector(
    ({ subscription }) => subscription?.currentSubscription
  );

  const technologyTypes = useSelector(
    ({ projects }) => projects?.technologyTypes
  );
  const totalProjectItems = useSelector(
    ({ projects }) => projects?.totalProjectItems
  );
  const projectList = useSelector(({ projects }) => projects?.projectList);

  const [selectedDomainType, setSelectedDomainType] = useState({});
  const [selectedPlatformType, setSelectedPlatformType] = useState([]);
  // eslint-disable-next-line
  const [selectedPlatformData, setSelectedPlatformData] = useState([]);
  const [selectedTechnologyType, setSelectedTechnologyType] = useState([]);
  // eslint-disable-next-line
  const [newTechnologyTypes, setNewTechnologyTypes] = useState([]);
  // eslint-disable-next-line
  const [newDomainTypes, setNewDomainTypes] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [selectedProject, setSelectedProject] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit,setPageLimit] = useState(10);
  const [confirmModal, setConfimModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  // eslint-disable-next-line
  const [newPlatformTypes, setNewPlatformTypes] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [projectName, setProjectName] = useState("");

  useEffect(() => {
    dispatch(getDomainTypes());
    // dispatch(getTechnologyTypes());
    // dispatch(getPlatformTypes());
  }, [dispatch]);

  useEffect(() => {
    const params = {
      pageLimit,
      currentPage,
      search,
      selectedDomainType,
      selectedPlatformType,
      selectedTechnologyType,
    };
    if (search === "" || search?.length >= 2) {
      setLoading(true);
      dispatch(getProjectList(params)).finally(() => setLoading(false));
    }
  }, [
    currentPage,
    dispatch,
    pageLimit,
    search,
    selectedDomainType,
    selectedPlatformType,
    selectedTechnologyType,
  ]);

  const getProjectCreationLimit = () => {
    const subscription = subscriptions.find(
      (sub) => sub?._id === currentSubscription?.subscriptionId
    );
    const projectLimitFeature = subscription?.features?.find(
      (feature) => feature.type === "project"
    );
    return projectLimitFeature ? parseInt(projectLimitFeature.limit) : 0;
  };

  const projectLimit = getProjectCreationLimit();

  const refreshFilter = () => {
    setSelectedDomainType([]);
    setSelectedPlatformType([]);
    setSelectedTechnologyType([]);
  };

  const projectDetail = (item) => {
    dispatch({
      type: projectActions.GET_SELECTED_PROJECT_DATA,
      payload: item,
    });
    navigate("/edit-project", {
      state: {
        search: search,
        currentPage: currentPage,
        pageLimit: pageLimit,
      },
    });
  };

  const projectDelete = async () => {
    setDeleteLoading(true);
    if (selectedProject) {
      const { logo, videoLink, screenShots } = selectedProject;
      const assetsToDelete = [];

      if (logo) {
        const logoKeyName = extractKeyNameFromUrl(logo);
        // console.log(`Extracted logo key: ${logoKeyName}`);
        assetsToDelete.push(logoKeyName);
      }

      if (videoLink) {
        const videoKeyName = extractKeyNameFromUrl(videoLink);
        // console.log(`Extracted video key: ${videoKeyName}`);
        assetsToDelete.push(videoKeyName);
      }

      if (Array.isArray(screenShots)) {
        screenShots.forEach((url) => {
          const screenShotKeyName = extractKeyNameFromUrl(url);
          // console.log(`Extracted screenshot key: ${screenShotKeyName}`);
          assetsToDelete.push(screenShotKeyName);
        });
      }

      console.log("Assets to delete:", assetsToDelete);

      for (const keyName of assetsToDelete) {
        try {
          await deleteFile(keyName);
          // console.log(`Deleted asset: ${keyName}`);
        } catch (error) {
          console.error(`Error deleting asset ${keyName}:`, error);
        }
      }

      console.log("All assets processed for deletion");
      try {
        // Uncomment and update this part as needed
        await dispatch(deleteProject(projectId)).then((res) => {
          if (res?.status === 200 && res?.data?.success) {
            const params = {
              pageLimit,
              currentPage:
                projectList?.length === 1 && currentPage > 1
                  ? currentPage - 1
                  : currentPage,
              search,
              selectedDomainType,
              selectedPlatformType,
              selectedTechnologyType,
            };
            dispatch(getProjectList(params));
            toastr.success("Success", res?.data?.message);
          } else {
            toastr.error("Error", res?.data?.message);
          }
        });
      } catch (err) {
        console.error(`Error deleting asset ${err}:`, err);
      } finally {
        setSelectedProject({});
        setProjectId("");
        setDeleteLoading(false);
        setDeleteModal(false);
      }
    }
  };

  const addProject = () => {
    if (projectList?.length >= projectLimit) {
      setShowSubscriptionModal(true);
    } else {
      navigate("/add-project");
    }
  };

  const handleStatusChange = async (id, status) => {
    setConfimModal(false);

    try {
      const params = {
        id: id || projectId,
        status: status === "active" ? "inactive" : "active",
      };

      await dispatch(updateProject(params)).then((response) => {
        if (response?.status === 200 && response?.data?.success) {
          toastr.success("Success", response?.data?.message);
          const params = {
            pageLimit,
            currentPage,
            search,
            selectedDomainType,
            selectedPlatformType,
            selectedTechnologyType,
          };
          dispatch(getProjectList(params));
        } else {
          toastr.error("Error", response?.data?.message);
        }
      });
    } catch (error) {
      toastr.error("Error", "Failed to update project status");
    }
  };

  return (
    <>
      <div className={collapseSidebar ? "mainBody sortingMain" : "mainBody"}>
        <HeaderView />

        <div className="wrapper">
          <SidebarView />
          <div className="main-container">
            <div className="content">
              <div className="row mb-3">
                <div className="col-xxl-12">
                  <div className="row justify-content-between mb-3">
                    <div className="col-xxl-2 col-xl-3 col-lg-3">
                      <div className="greetingsText">
                        <div className="greetingsText-heading">
                          <h3>Projects
                          <small>
                              <span
                                class="tooltip-toggleuser pro"
                                aria-label="The Projects section allows you to view your list of projects. You can add new projects by clicking the plus button. Additionally, you can filter, search, activate, deactivate, and delete projects here. Active projects will be shown on the Folio4u app."
                                tabindex="0"
                              >
                                <img src={SVGIMAGES.infoIcon} alt="" />
                              </span>
                            </small>
                          </h3>
                        </div>
                      </div>
                    </div>

                    <div className="col-xxl-10 col-xl-9 col-lg-9 d-flex filterNewCus">
                      <div className="headingFilter selectlangth">
                        <DomainDropdown
                          domainTypes={domainTypes}
                          setNewDomainTypes={setNewDomainTypes}
                          sortedData={selectedDomainType}
                          setSortedData={setSelectedDomainType}
                        />
                      </div>

                      <div className="headingFilter selectlangth">
                        <PlatformDropdown
                          platformTypes={platformTypes}
                          sortedData={selectedPlatformType}
                          setSortedData={setSelectedPlatformType}
                          setSelectedPlatformData={setSelectedPlatformData}
                          setNewPlatformTypes={setNewPlatformTypes}
                        />
                      </div>

                      <div className="headingFilter selectlangth">
                        <TechnologyDropdown
                          technologyTypes={technologyTypes}
                          setNewTechnologyTypes={setNewTechnologyTypes}
                          sortedData={selectedTechnologyType}
                          setSortedData={setSelectedTechnologyType}
                        />
                      </div>

                      {(selectedTechnologyType?.length > 0 ||
                        selectedPlatformType?.length > 0 ||
                        selectedDomainType?.length > 0) && (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="button-tooltip-2">Refresh</Tooltip>
                          }
                        >
                          <div className="buttons d-flex" variant="light">
                            <button
                              className="ctr-btn"
                              onClick={() => refreshFilter()}
                            >
                              <img src={SVGIMAGES.refreshImage} alt="" />
                            </button>
                          </div>
                        </OverlayTrigger>
                      )}

                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id="button-tooltip-2">Add Project</Tooltip>
                        }
                      >
                        <div className="buttons d-flex" variant="light">
                          <button
                            className="ctr-btn"
                            onClick={() => addProject()}
                            variant="light"
                          >
                            <img src={SVGIMAGES.PlusIcon} alt="" />
                          </button>
                        </div>
                      </OverlayTrigger>

                      <input
                        type="text"
                        className="custom-input-field"
                        placeholder="Search Project"
                        value={search}
                        onChange={(e) => {
                          setCurrentPage(1);
                          const s = e.currentTarget?.value
                            ?.replace(/[^\w\s]|_/g, "")
                            .replace(/\s{2,}/g, " ");
                          setSearch(s);
                        }}
                      />
                    </div>
                  </div>
                  {projectList?.length > 0  &&
                  <div className="col-xl-3 col-lg-4 mb-3">
                      <ItemsPerPageDropdown
                        pageLimit={pageLimit}
                        setPageLimit={setPageLimit}
                        setCurrentPage={setCurrentPage}
                      />
                    </div>}
                  <div className="row">
                    {loading ? (
                      <DataLoader width={100} />
                    ) : (
                      <div className="col-xxl-12">
                        {projectList?.length > 0 ? (
                          <>
                            <div className="table-responsive">
                              <table className="custom-table">
                                <thead>
                                  <tr>
                                    <th scope="col">Project</th>
                                    <th scope="col">Domain</th>
                                    <th scope="col">Platform</th>
                                    <th scope="col">Technology</th>
                                    <th scope="col">Action</th>
                                    <th scope="col">Active</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {projectList?.length > 0 ? (
                                    projectList?.map((project) => (
                                      <tr key={project?._id}>
                                        <td
                                          onClick={() => projectDetail(project)}
                                        >
                                          <img
                                            className="project-logo"
                                            src={
                                              project?.logo
                                                ? project?.logo
                                                : IMAGES.UploadLogo
                                            }
                                            alt=""
                                          />
                                          <span>{project?.name}</span>
                                        </td>
                                        <td
                                          onClick={() => projectDetail(project)}
                                          className="w-descriptin"
                                        >
                                          {project?.domainId[0]?.title || "N/A"}
                                        </td>
                                        <td
                                          onClick={() => projectDetail(project)}
                                          className="w-descriptin"
                                        >
                                          {project?.platformId
                                            ?.map((platform) => platform?.title)
                                            .join(", ") || "N/A"}
                                        </td>
                                        <td
                                          onClick={() => projectDetail(project)}
                                          className="w-descriptin"
                                        >
                                          {project?.technologyId
                                            ?.map(
                                              (technology) => technology?.title
                                            )
                                            .join(", ") || "N/A"}
                                        </td>
                                        <td>
                                          <div className="action-btn">
                                            <button
                                              onClick={() => {
                                                setProjectId(project?._id);
                                                setSelectedProject(project);
                                                setDeleteModal(true);
                                              }}
                                            >
                                              <img
                                                src={SVGIMAGES.DeleteIcon}
                                                alt=""
                                              />
                                            </button>
                                          </div>
                                        </td>
                                        <td>
                                          <Switch
                                            id={project?._id}
                                            checked={
                                              project?.status === "active"
                                            }
                                            onChange={() => {
                                              setProjectId(project?._id);
                                              setCurrentStatus(project.status);
                                              setProjectName(project?.name);
                                              if (project.status === "active") {
                                                setConfimModal(true);
                                              } else {
                                                handleStatusChange(
                                                  project?._id,
                                                  project.status
                                                );
                                              }
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td className="text-center" colSpan={5}>
                                        No project records found
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            <div className="cusPagination">
                              <Pagination
                                currentPage={currentPage}
                                totalCount={totalProjectItems}
                                pageSize={pageLimit}
                                onPageChange={(page) => setCurrentPage(page)}
                              />
                            </div>
                          </>
                        ) : (
                          <div className="nodataFileBox">
                            <img src={SVGIMAGES.NoDataFoundImg} alt="" className="img-fluid" />
                            <h1>No Data Found</h1>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {confirmModal && (
        <ModalLayout show={confirmModal} setShow={setConfimModal} centered>
          <img
            src={
              currentStatus
                ? SVGIMAGES.DeactivateProject
                : SVGIMAGES.ActivateProject
            }
            alt=""
            className="mainIconModal"
          />
          <h2>{currentStatus ? "Deactivate" : "Activate"} Project</h2>
          <p className="w-100">
            Are you sure you want to deactivate {projectName}? It won’t be
            visible on application, but you can easily reactivate it later.
          </p>
          <div className="footbutton">
            <button
              type="button"
              onClick={() => {
                setConfimModal(false);
              }}
              className="cancelBtn"
            >
              Cancel
            </button>
            <button
              type="button"
              className="confirmBtn"
              onClick={() => {
                handleStatusChange(projectId, currentStatus);
              }}
            >
              Confirm
            </button>
          </div>
        </ModalLayout>
      )}

      {deleteModal && (
        <ModalLayout show={deleteModal} setShow={setDeleteModal} centered>
          <img
            src={SVGIMAGES.DeleteModaleIcon}
            alt=""
            className="mainIconModal"
          />
          <h2>Delete Project</h2>
          <p>Are you sure you want to Delete Project?</p>
          <div className="footbutton">
            <button
              type="button"
              onClick={() => {
                setDeleteModal(false);
              }}
              className="cancelBtn"
            >
              Cancel
            </button>
            <button
              type="button"
              className="confirmBtn"
              disabled={deleteLoading}
              onClick={() => {
                projectDelete();
              }}
            >
              {deleteLoading ? <ButtonLoader /> : "Confirm"}
            </button>
          </div>
        </ModalLayout>
      )}

      {showSubscriptionModal && (
        <SubscriptionModal
          showSubscriptionModal={showSubscriptionModal}
          setShowSubscriptionModal={setShowSubscriptionModal}
          message={
            "You have reached your project limit. To add more projects, please upgrade your plan."
          }
        />
      )}
    </>
  );
};
