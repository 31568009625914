export const awsConfig = {
    AWS_ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    AWS_SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    AWS_BUCKET: process.env.REACT_APP_AWS_BUCKET,
    AWS_REGION: process.env.REACT_APP_AWS_REGION,
    AWS_ENDPOINT: process.env.REACT_APP_AWS_ENDPOINT,
    AWS_CDN_URL: process.env.REACT_APP_CDN_URL,
    AWS_ACL: process.env.REACT_APP_ACL
}

export const razorpayConfig = {
    RAZORPAY_KEY_ID: process.env.REACT_APP_RAZORPAY_KEY_ID,
    SECRET_KEY: process.env.REACT_APP_RAZORPAY_KEY_SECRET
}