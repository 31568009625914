import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { ButtonLoader } from "../components/Loader";
import { Error, Label } from "../components";
import { doOtpVerify, doResetCode } from "../store/actions/authActions";
import { SVGIMAGES } from "../assets/svgImages";
import { trimValue } from "../utils/helper";

export const SignupOtpView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isResetLoading,setISResetLoading]=useState(false)
    const [email, setEmail] = useState('');

    const formik = useFormik({
        initialValues: {
            email: '',
            otp: '',
            verify:'email'
        },
        validationSchema: Yup.object({
            otp: Yup.string().trim().required("OTP is required!"),  // Changed to Yup.string()
        }),
        onSubmit: async (values) => {
             if (formik.isValid && formik.dirty) {
                try {
                    setIsLoading(true);
                    await dispatch(doOtpVerify({...values,email:values.email.toLowerCase()})).then((res) => {
                        if (res?.status === 200 && res?.data?.success) {
                            toastr.success("Success", res?.data?.message);
                            navigate("/projects");
                        }
                        else {
                            toastr.error("Error", res?.data?.message);
                        }
                    })
                }
                catch (error) {
                    toastr.error("Error", error);
                    setIsLoading(false)
                }
                finally {
                    setIsLoading(false);
                }

            }

        }
    });

    useEffect(() => {
        const storedEmail = localStorage.getItem('user_email');
        if (storedEmail) {
            setEmail(storedEmail);
            formik.setFieldValue('email', storedEmail);
        }
        // eslint-disable-next-line
    }, []);

    const resetCode = async () => {
        try {
            setISResetLoading(true);
            const values = { email: email.toLowerCase() };
            await dispatch(doResetCode(values)).then((res) => {
                if (res?.status === 200 && res?.data?.success) {
                    setISResetLoading(false);
                    toastr.success("Success", res?.data?.message);
                }
                else {
                    setISResetLoading(false);
                    toastr.error("Error", res?.data?.message);
                }
            })
        } catch (error) {
            setISResetLoading(false);
            toastr.error("Error", error);
        }

    }

    return (
        <div className="loginBg">
            <div className="container-fluid">
                <div className="align-self-center pad-0 form-section">
                    <div className="mainLoginbox">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="loogSection">
                                <img src={SVGIMAGES.Logo} alt="" />
                            </div>
                            <h1 className="tilteHead">OTP</h1>
                            <p className="paregrapSub">To Verify, please enter the OTP sent on “{email}”</p>
                            <div className="form-group">
                            <Label label={"OTP"} htmlFor={"otp"} required />
                                <div className="possionIconInput">
                                    <input
                                        type="text"
                                        placeholder="Enter Code"
                                        className="form-control"
                                        value={formik.values.otp}
                                        onChange={formik.handleChange}
                                        onBlur={(e) => {
                                            formik.handleBlur(e);
                                            formik.setFieldValue("otp", trimValue(e.target.value));
                                          }}
                                        name="otp" 
                                    />
                                    <img src={SVGIMAGES.CodeIcon} alt="" />
                                </div>
                                {formik.touched.otp && formik.errors.otp && (
                                    <Error error={formik.errors.otp} />
                                )}
                            </div>
                            <div className="form-group">
                                <h5 className="justify-content-end">
                                    <button type="button" onClick={() => resetCode()}> {isResetLoading ? <ButtonLoader /> : "Resend Code"}</button>
                                </h5>
                            </div>
                            <div className="form-group align-self-center d-flex">
                                <button type="submit" className="formButton">
                                    {isLoading ? <ButtonLoader /> : "Verify"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}