import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactTyped } from "react-typed";
import { WebHeaderView } from "./web-header";
import { WebFooterView } from "./web-footer";
import { IMAGES } from "../../assets/images";
import { Error, ModalLayout } from "../../components";
import { useSelector } from "react-redux";
// import { SimpleSliderView } from "./slider";
// import { Image } from "react-bootstrap";
// import { TestimonialSliderView } from "./testimonial";
import { useFormik } from "formik";
import { toastr } from "react-redux-toastr";
import * as Yup from "yup";
import { interestedCustomerEmail } from "../../store/actions/authActions";
import { useDispatch } from "react-redux";
import { getRatingCustomerCount } from "../../store/actions/ratingCustomerCountActions";
import Rating from "react-rating";
export const HomeView = () => {
  const profile = useSelector(({ auth }) => auth?.loginUser);
  const ratingCustomerCount = useSelector(
    ({ ratingcustomercount }) => ratingcustomercount?.rating_customerCount
  );
  console.log("ratingCustomerCount", ratingCustomerCount);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [videoView, setVideoView] = useState(false);
  const isProfileEmpty = !profile || Object.keys(profile).length === 0;
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getRatingCustomerCount());
  }, [dispatch]);

  const Formik = useFormik({
    initialValues: { customerEmail: "" },
    validationSchema: Yup.object({
      customerEmail: Yup.string()
        .trim()
        .email("Please enter an valid email address!")
        .required("Email is required!"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (Formik.isValid && Formik.dirty) {
        try {
          setIsLoading(true);
          const lowercaseEmail = values.customerEmail.toLowerCase();
          await dispatch(
            interestedCustomerEmail({ customerEmail: lowercaseEmail })
          ).then((res) => {
            if (res?.status === 200 && res?.data?.success) {
              toastr.success("Success", res?.data?.message);
              resetForm();
            } else {
              toastr.error("Error", res?.data?.message);
            }
          });
        } catch (error) {
          toastr.error("Error", error);
        } finally {
          setIsLoading(false);
        }
      }
    },
  });

  return (
    <>
      <WebHeaderView />
      <section className="sliderpart">
        <div className="container-fluid">
          <div className="slideContent">
            <h2>
              Showcase Your Portfolio Seamlessly Across All Devices
              <span>
                <ReactTyped
                  strings={["Anytime", "Anywhere"]}
                  typeSpeed={200}
                  loop
                  backSpeed={50}
                  cursorChar="|"
                  showCursor={true}
                />
              </span>
            </h2>
            <p>
              In today's fast-paced world, having instant access to your
              professional portfolio is crucial. Whether you're a creative
              professional, an entrepreneur, or a consultant, Folio4u is your
              ultimate solution for showcasing your work effortlessly on any
              Desktop and Tablet devices.
            </p>
            <div className="sliBtnbox">
              <button
                className="buttoncusSlide"
                onClick={() => {
                  window.scrollTo({
                    top: document.body.scrollHeight - 900,
                    behavior: "smooth",
                  });
                }}
              >
                Get The App
              </button>{" "}
              <button
                className="onlyBorder btnBlacksed"
                onClick={() => setVideoView("true")}
              >
                <img src={IMAGES.PlayIcon} alt="" /> Watch Video
              </button>
            </div>
          </div>
          <div className="slidebottom">
            <img src={IMAGES.SliderMobile} alt="" />
          </div>
        </div>
      </section>

      <section className="servicePart" id="work">
        <div className="container-fluid">
          <h2>Features at a Glance</h2>
          {/* <p>"Streamlining your workflow for maximum efficiency and success"</p> */}
          <div className="sercContent">
            <div className="row">
              <div className="col-md-4">
                <div className="SerBody borderRight borderBottom">
                  <img src={IMAGES.UniversalCompatibilityIcon} alt="" />
                  <h6>Universal Compatibility</h6>
                  <p>
                    Access and display your portfolio on iPads, Tablets, MacOS,
                    and WindowsOS.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="SerBody borderRight borderBottom">
                  <img src={IMAGES.PortfolioLinkIcon} alt="" />
                  <h6>Sharable Portfolio Link</h6>
                  <p>
                    Generate a link for your portfolio and share it via email,
                    social media, or messaging apps for immediate viewing.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="SerBody borderBottom">
                  <img src={IMAGES.OfflineAccessIcon} alt="" />
                  <h6>Offline Access</h6>
                  <p>
                    Access and manage your portfolio anytime, even without an
                    internet connection. View and present your work seamlessly.
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="SerBody borderRight ">
                  <img src={IMAGES.QuickSyncingIcon} alt="" />
                  <h6>Simple and Quick Syncing</h6>
                  <p>
                    Effortlessly upload your portfolio through our user-friendly
                    admin portal and sync it across all your devices in seconds.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="SerBody borderRight ">
                  <img src={IMAGES.SecureReliableIcon} alt="" />
                  <h6>Secure and Reliable</h6>
                  <p>
                    Your portfolio is securely stored and synced, ensuring that
                    your work is always accessible and protected.{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="SerBody cusborderBottom">
                  <img src={IMAGES.ProfessionalPresentationsIcon} alt="" />
                  <h6>Professional Presentations</h6>
                  <p>
                    Perfect for client meetings, presentations, and any
                    professional setting where you need to showcase your work.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="streamline">
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            <div className="col-xxl-6 align-self-center">
              <div className="subsImgContent">
                <img
                  src={IMAGES.HandAppMobile}
                  alt=""
                  className="img-fluid hiroImg"
                />
              </div>
            </div>
            <div className="col-xxl-6 align-self-center">
              <h2>
                Transform Portfolio Management with{" "}
                <span>State-of-the-Art Tech</span>
              </h2>
              <ul>
                <li>
                  <img src={IMAGES.CheckboxIcon} alt="" />
                  Real-Time Updates Across All Devices.
                </li>
                <li>
                  <img src={IMAGES.CheckboxIcon} alt="" />
                  Interactive Elements to Engage Your Audience.
                </li>
                <li>
                  <img src={IMAGES.CheckboxIcon} alt="" />
                  Offline Access for Uninterrupted Presentations.
                </li>
                <li>
                  <img src={IMAGES.CheckboxIcon} alt="" />
                  Cross-platform compatible for Maximum availability.
                </li>
                <li>
                  <img src={IMAGES.CheckboxIcon} alt="" />
                  Collaborative Features for Team Presentations.
                </li>
                <li>
                  <img src={IMAGES.CheckboxIcon} alt="" />
                  Revolutionize Portfolio Showcase with Modern Technology.
                </li>
                <li>
                  <img src={IMAGES.CheckboxIcon} alt="" />
                  showcase your portfolio via a shareable link.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {isProfileEmpty && (
        <div className="serviceBanner">
          <div className="gridBackground">
            <img src={IMAGES.GridLine} alt="" />
          </div>
          <div className="container-fluid">
            <h6>Be a Part of Our Growing Network</h6>
            <h2>Join Us - Register Today!</h2>
            <p>
              Join our expanding network of professionals who rely on Folio4u to
              present their work with style and simplicity. Sign up today and
              effortlessly display your portfolio across all your devices.
            </p>
            <button
              className="btnBlacksed"
              onClick={() => {
                navigate("/signup");
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              Register
            </button>
          </div>
        </div>
      )}

      <div className="succeBoxcontent">
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            <div className="col-xl-6 align-self-center">
              <h2>
                Folio4u: Uniting Your <span> Portfolio, Teammates,</span> and
                Tools
              </h2>
              <h3>
                The unified platform is designed for professionals like you.
                Whether showcasing your portfolio, collaborating with teammates,
                or managing projects.
              </h3>
              <form onSubmit={Formik.handleSubmit}>
                <h5>
                  <input
                    type="text"
                    placeholder="Email Address"
                    name="customerEmail"
                    id="customerEmail"
                    value={Formik.values.customerEmail}
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                  />
                  <button type="submit">
                    <img src={IMAGES.SendIcon} alt="" />
                  </button>
                </h5>
                {Formik.touched.customerEmail &&
                  Formik.errors.customerEmail && (
                    <Error className="validationBx" error={Formik.errors.customerEmail} />
                  )}
              </form>
              <h6>
                <small>
                  <b>{ratingCustomerCount?.total_customer ? ratingCustomerCount?.total_customer : 0} +</b> Happy Customers
                </small>
                <small className="ipadviewrating">
                  <b>{ratingCustomerCount?.star_rating ? ratingCustomerCount?.star_rating : 0}/5 </b>
                  <span>
                    <ul>
                      {/* <li><img src={IMAGES.StarColorIcon} alt="" className="img-fluid" /></li>
                      <li><img src={IMAGES.StarColorIcon} alt="" className="img-fluid" /></li>
                      <li><img src={IMAGES.StarColorIcon} alt="" className="img-fluid" /></li>
                      <li><img src={IMAGES.StarColorIcon} alt="" className="img-fluid" /></li>
                      <li><img src={IMAGES.StarGrayIcon} alt="" className="img-fluid" /></li> */}
                      <Rating
                       initialRating={ratingCustomerCount?.star_rating ? ratingCustomerCount?.star_rating : 0}
                       emptySymbol="fa fa-star-o fa-2x "
                        fullSymbol="fa fa-star fa-2x"
                        readonly
                      />
                    </ul>
                    User Rating
                  </span>
                </small>
              </h6>
            </div>
            <div className="col-xl-6 align-self-center">
              <div className="subsImgContent">
                <img
                  src={IMAGES.SubsImg}
                  alt=""
                  className="img-fluid hiroImg"
                />
                {/* <div className="subBoxPoint">
                  <h2>Banking app UX research</h2>
                  <h6>Team member</h6>
                  <div className="talents-work" >
                    <img src={IMAGES.UserelipImg01} alt="" />
                    <img src={IMAGES.UserelipImg02} alt="" className="sli-user-img" />
                    <img src={IMAGES.UserelipImg03} alt="" className="sli-user-img" />
                    <span className="">+3</span>
                 
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="H_pricingBox">
        <div className="">
          <h2>Happy <span>Clients</span></h2>
          <SimpleSliderView centerMode>
                        <div className="col-md-12">
                            <div className="slideDesignweb">
                                <div className="testProfile">
                                    <Image draggable={false} src={IMAGES.ClientIMG1} />
                                </div>
                                <h3>Marvin McKinney</h3>
                                <div class="tesbottonBx">
                                    <ul>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                    </ul>
                                </div>
                                <img src={IMAGES.QuoteIcon} alt="" />
                                <p className="description">Lorem ipsum dolor sit amet consectetur adipiscing elit tortor eu egestas morbi sem vulputate etiam.</p>

                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="slideDesignweb">
                                <div className="testProfile">
                                    <Image draggable={false} src={IMAGES.ClientIMG2} />
                                </div>
                                <h3>Kristin Watsone</h3>
                                <div class="tesbottonBx">
                                    <ul>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                    </ul>
                                </div>
                                <img src={IMAGES.QuoteIcon} alt="" />
                                <p className="description">Lorem ipsum dolor sit amet consectetur adipiscing elit tortor eu egestas morbi sem vulputate etiam.</p>

                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="slideDesignweb">
                                <div className="testProfile">
                                    <Image draggable={false} src={IMAGES.ClientIMG3} />
                                </div>
                                <h3>Jacob Jones</h3>
                                <div class="tesbottonBx">
                                    <ul>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                    </ul>
                                </div>
                                <img src={IMAGES.QuoteIcon} alt="" />
                                <p className="description">Lorem ipsum dolor sit amet consectetur adipiscing elit tortor eu egestas morbi sem vulputate etiam.</p>

                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="slideDesignweb">
                                <div className="testProfile">
                                    <Image draggable={false} src={IMAGES.ClientIMG4} />
                                </div>
                                <h3>Eleanor Pena</h3>
                                <div class="tesbottonBx">
                                    <ul>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                    </ul>
                                </div>
                                <img src={IMAGES.QuoteIcon} alt="" />
                                <p className="description">Lorem ipsum dolor sit amet consectetur adipiscing elit tortor eu egestas morbi sem vulputate etiam.</p>

                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="slideDesignweb">
                                <div className="testProfile">
                                    <Image draggable={false} src={IMAGES.ClientIMG5} />
                                </div>
                                <h3>Darrell Steward</h3>
                                <div class="tesbottonBx">
                                    <ul>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                        <li><img src={IMAGES.StarColorIcon} alt="" /></li>
                                    </ul>
                                </div>
                                <img src={IMAGES.QuoteIcon} alt="" />
                                <p className="description">Lorem ipsum dolor sit amet consectetur adipiscing elit tortor eu egestas morbi sem vulputate etiam.</p>
                            </div>

                        </div>
                    </SimpleSliderView>
        </div>
      </div> */}
      <WebFooterView />

      {videoView && (
        <ModalLayout
          show={videoView}
          setShow={setVideoView}
          centered
          title={"How It Work"}
          className={"customLargeWidth"}
        >
          <video className="w-100" controls autoPlay>
            <source src={IMAGES.VideoURL} type="video/mp4" allowFullScreen />
          </video>
        </ModalLayout>
      )}
    </>
  );
};
