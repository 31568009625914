import { useNavigate } from "react-router-dom";
import { SVGIMAGES } from "../assets/svgImages";
import { useDispatch } from "react-redux";
import { togglePress } from "../store/actions/authActions";

export const SidebarView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <>
            <div className="side desviewmenu">
                <div className="toggle-wrap">
                    <span className="toggle-bar"></span>
                </div>
                <aside>
                    <div className="aside-logo">
                        <img
                            src={SVGIMAGES.sideBarIcon}
                            alt=""
                            onClick={() => {
                                navigate("/projects");
                            }}
                            className="logoImg"
                        />
                        <img
                            src={SVGIMAGES.sideBarIcon}
                            alt=""
                            onClick={() => {
                                navigate("/projects");
                            }}
                            className="markImg"
                        />
                       
                    </div>


                    <span className="menuScrol" id="style-5">

                        <li className={window.location.pathname === "/projects" ? "active" : "" ||
                            window.location.pathname === "/add-project" ? "active" : "" ||
                                window.location.pathname === "/edit-project" ? "active" : ""
                        }>
                            <button onClick={() => {
                                navigate("/projects")
                            }
                            }>
                                <img src={SVGIMAGES.ProjectIcon} alt="" />
                                <span>Projects</span>
                            </button>
                        </li>
                        <li className={window.location.pathname === "/domain" ? "active" : ""}>
                            <button onClick={() => navigate("/domain")}>
                                <img src={SVGIMAGES.DomainIcon} alt="" />
                                <span>Domains</span>
                            </button>
                        </li>
                        <li className={window.location.pathname === "/platform" ? "active" : ""}>
                            <button onClick={() => navigate("/platform")}>
                                <img src={SVGIMAGES.PlatformIcon} alt="" />
                                <span>Platforms</span>
                            </button>
                        </li>

                        <li className={window.location.pathname === "/technology" ? "active" : ""}>
                            <button onClick={() => navigate("/technology")}>
                                <img src={SVGIMAGES.TechnologyIcon} alt="" />
                                <span>Technologies</span>
                            </button>
                        </li>

                        <li className={window.location.pathname === "/shared-portfolio" ? "active" : ""}>
                            <button onClick={() => navigate("/shared-portfolio")}>
                                <img src={SVGIMAGES.SharedPortfolioIcon} alt="" />
                                <span>Shared Portfolios</span>
                            </button>
                        </li>

                        <li className={window.location.pathname === "/users" ? "active" : ""}>
                            <button onClick={() => navigate("/users")}>
                                <img src={SVGIMAGES.UserIcon} alt="" />
                                <span>Users</span>
                            </button>
                        </li>
                        <li>
                            <button onClick={(e) => {
                                window.location.href = 'mailto:support@folio4u.com';
                                e.preventDefault();}}>
                                <img src={SVGIMAGES.contactUsIcon} alt="" />
                                <span>Contact Us</span>
                            </button>
                        </li>
                    </span>
                </aside>
            </div>

            <div className="mobileviewmenu">                
            <div className="side">
                <div className="toggle-wrap">
                    <span className="toggle-bar"></span>
                </div>
                <aside>
                    <div className="aside-logo">
                    <img
                            src={SVGIMAGES.sideBarIcon}
                            alt=""
                            onClick={() => {
                                navigate("/projects");
                                dispatch(togglePress());
                            }}
                            className="markImg"
                        />
                        <img
                            src={SVGIMAGES.CloseButton}
                            alt=""
                            onClick={() => {
                                dispatch(togglePress());
                            }}
                            className="closetogglemobile"
                        />
                    </div>


                    <span className="menuScrol" id="style-5">

                        <li className={window.location.pathname === "/projects" ? "active" : "" ||
                            window.location.pathname === "/add-project" ? "active" : "" ||
                                window.location.pathname === "/edit-project" ? "active" : ""
                        }>
                            <button onClick={() => {
                                navigate("/projects")
                                dispatch(togglePress());
                            }
                            }>
                                <img src={SVGIMAGES.ProjectIcon} alt="" />
                                <span>Projects</span>
                            </button>
                        </li>
                        <li className={window.location.pathname === "/domain" ? "active" : ""}>
                            <button onClick={() => {
                                dispatch(togglePress());
                                navigate("/domain")}}>
                                <img src={SVGIMAGES.DomainIcon} alt="" />
                                <span>Domains</span>
                            </button>
                        </li>
                        <li className={window.location.pathname === "/platform" ? "active" : ""}>
                            <button onClick={() => {navigate("/platform")
                                dispatch(togglePress());
                            }

                            }>
                                <img src={SVGIMAGES.PlatformIcon} alt="" />
                                <span>Platforms</span>
                            </button>
                        </li>

                        <li className={window.location.pathname === "/technology" ? "active" : ""}>
                            <button onClick={() => {navigate("/technology")
                                dispatch(togglePress());
                            }}>
                                <img src={SVGIMAGES.TechnologyIcon} alt="" />
                                <span>Technologies</span>
                            </button>
                        </li>

                        <li className={window.location.pathname === "/shared-portfolio" ? "active" : ""}>
                            <button onClick={() => {navigate("/shared-portfolio")
                                dispatch(togglePress());
                            }}>
                                <img src={SVGIMAGES.SharedPortfolioIcon} alt="" />
                                <span>Shared Portfolios</span>
                            </button>
                        </li>

                        <li className={window.location.pathname === "/users" ? "active" : ""}>
                            <button onClick={() => {navigate("/users")
                                dispatch(togglePress());
                            }}>
                                <img src={SVGIMAGES.UserIcon} alt="" />
                                <span>Users</span>
                            </button>
                        </li>
                        <li>
                            <button onClick={(e) => {
                                window.location.href = 'mailto:support@folio4u.com';
                                e.preventDefault();
                                dispatch(togglePress());
                                }}>
                                <img src={SVGIMAGES.contactUsIcon} alt="" />
                                <span>Contact Us</span>
                            </button>
                        </li>
                    </span>
                </aside>
                <div className="menuoverview"
                onClick={() => {
                    dispatch(togglePress());
                }}>
                    &nbsp;
                </div>
            </div>
            </div>
        </>
    )
}