import { ratingCustomerCountEndpoints } from "../apiEndpoints";
import { Instance } from "../apiServices";
import { organizationRatingActions } from "./actionTypes";


export const getRatingCustomerCount = () => {
    return async (dispatch, getState) => {
    const response = await Instance(
       getState, //state
      "GET", //method
       ratingCustomerCountEndpoints.GET_RATING_CUSTOMER_COUNT_API,
        null, //data/params
        false //isAuth
    );
    if (response?.status === 200 && response?.data?.success) {
        dispatch({
       type:organizationRatingActions.GET_RATING_CUSTOMER_COUNT,
        payload: response?.data,
          });
      return response;
    }  else {
      return response.response;
    }
  };
};