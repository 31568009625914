import { Instance } from "../apiServices";
import { authActions, credentialsActions } from "./actionTypes";
import { AWSCredentialsApiEndpoint, AuthApiEndpoints } from "../apiEndpoints";
import { toastr } from "react-redux-toastr";

export const doAuthLogin = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "POST", //method
      AuthApiEndpoints.AUTH_LOGIN_API, //api-endpoint
      params, //data/params
      false //isAuth
    );
    if (response?.status === 200 && response?.data?.success) {
      const { data } = response;
      dispatch({
        type: authActions.AUTH_LOGIN,
        payload: data,
      });
      return response;
    } else {
      return response.response || response;
    }
  };
};

export const getProfile = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "GET", //method
      AuthApiEndpoints.GET_PROFILE, //api-endpoint
      params, //data/params
      true //isAuth
    );

    if (response?.status === 200 && response?.data?.success) {
      const { data } = response;
      dispatch({
        type: authActions.GET_PROFILE,
        payload: data,
      });
      return response;
    } else {
      return response;
    }
  };
};

export const doAuthSignup = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "POST", //method
      AuthApiEndpoints.AUTH_SIGNUP_API, //api-endpoint
      params, //data/params
      false, //isAuth
      false // formData
    );
    if (response?.status === 200 && response?.data?.success) {
      return response;
    } else {
      return response;
    }
  };
};

export const refreshToken = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const refreshToken = state?.auth?.refreshToken;
    // const refreshToken = localStorage.getItem('refreshToken');
    const userId = state?.auth?.loginUser?._id;

    const params = { token: refreshToken, id: userId };
    const response = await Instance(
      getState,
      "POST",
      AuthApiEndpoints.REFRESH_TOKEN_API,
      params,
      false
    );

    if (response?.status === 200 && response?.data?.success) {
      const data = response?.data;
      dispatch({
        type: authActions.REFRESH_TOKEN,
        payload: data,
      });
      localStorage.setItem('accessToken',data?.accessToken);
      localStorage.setItem('refreshToken',data?.refreshToken)
      return response;
    } if (response?.status === 200 && !response?.data?.success) {
      toastr.error('Error',response?.data?.message);
      dispatch(doAuthLogout())
      return response;
    } else {
      return response;
    }
  };
};

export const doOtpVerify = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "POST", //method
      AuthApiEndpoints.OTP_VERIFY, //api-endpoint
      params, //data/params
      false //isAuth
    );
    if (response?.status === 200 && response?.data?.success) {
      const { data } = response;
      dispatch({
        type: authActions.AUTH_LOGIN,
        payload: data,
      });
      return response;
    } else {
      return response;
    }
  };
};

export const doResetCode = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "POST", //method
      AuthApiEndpoints.RESET_OTP, //api-endpoint
      params, //data/params
      false //isAuth
    );
    if (response?.status === 200 && response?.data?.success) {
      return response;
    } else {
      return response;
    }
  };
};

export const doForgotResetPassword = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "POST", //method
      AuthApiEndpoints.FORGOT_RESET_PASSWORD, //api-endpoint
      params, //data/params
      false //isAuth
    );
    if (response?.status === 200 && response?.data?.success) {
      return response;
    } else {
      return response.response;
    }
  };
};

export const profileUpdate = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "PUT", //method
      AuthApiEndpoints.PROFILE_UPDATE, //api-endpoint
      params, //data/params
      true, //isAuth
      false
    );
    if (response?.status === 200 && response?.data?.success) {
      const { data } = response;
      dispatch({
        type: authActions.PROFILE_UPDATE,
        payload: data,
      });
      return response;
    } else if (
      response?.response?.status === 403 &&
      !response?.response?.data?.success
    ) {
      const res = await dispatch(refreshToken());
      if (res?.data?.success && res?.status === 200) {
        dispatch(profileUpdate(params));
      }
    } else {
      return response;
    }
  };
};

export const getCategoryTypes = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "GET", //method
      AuthApiEndpoints.GET_CATEGORY_LIST, //api-endpoint
      params, //data/params
      true //isAuth
    );
    if (response?.status === 200 && response?.data?.success) {
      dispatch({
        type: authActions.GET_CATEGORY_DATA,
        payload: response?.data?.data,
      });
      return response;
    } else {
      return response;
    }
  };
};

export const checkOrgCode = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "POST", //method
      AuthApiEndpoints.AUTH_CHECK_ORG_CODE, //api-endpoint
      params, //data/params
      false //isAuth
    );
    if (response?.status === 200 && response?.data?.success) {
      return response;
    } else {
      return response;
    }
  };
};

export const changePassword = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "POST", //method
      AuthApiEndpoints.CHANGE_PASSWORD_API, //api-endpoint
      params, //data/params
      true //isAuth
    );
    if (response?.status === 200 && response?.data?.success) {
      return response;
    } else if (
      response?.response?.status === 403 &&
      !response?.response?.data?.success
    ) {
      const res = await dispatch(refreshToken());
      if (res?.data?.success && res?.status === 200) {
        dispatch(changePassword(params));
      }
    } else {
      return response;
    }
  };
};

export const ContactUs = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "POST", //method
      AuthApiEndpoints.CONTACT_US, //api-endpoint
      params, //data/params
      false //isAuth
    );
    if (response?.status === 200 && response?.data?.success) {
      return response;
    } else {
      return response;
    }
  };
};
export const interestedCustomerEmail = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "POST", //method
      AuthApiEndpoints.INTERESTED_CUSTOMER, //api-endpoint
      params, //data/params
      false //isAuth
    );
    if (response?.status === 200 && response?.data?.success) {
      return response;
    } else {
      return response;
    }
  };
};

export const doAuthLogout = () => {
  return async (dispatch) => {
    dispatch({
      type: authActions.AUTH_LOGOUT,
      payload: {},
    });
    const rememberMe = localStorage.getItem("rememberMe");
    const rememberedEmail = rememberMe ? localStorage.getItem("email") : "";
    const rememberedPassword = rememberMe
      ? localStorage.getItem("password")
      : "";

    localStorage.clear();
    if (rememberMe) {
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userId");
      localStorage.setItem("rememberMe", "true");
      localStorage.setItem("email", rememberedEmail);
      localStorage.setItem("password", rememberedPassword);
    }
  };
};

export const getExportProjects = (uniqueId) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "GET", //method
      AuthApiEndpoints.GET_EXPORT_PROJECTS_API + uniqueId, //api-endpoint
      null, //data/params
      false //isAuth
    );
    if (response?.status === 200 && response?.data?.success) {
      dispatch({
        type: authActions.GET_EXPORT_PROJECTS,
        payload: response?.data?.data,
      });
      return response;
    } else {
      return response;
    }
  };
};

export const getSelectedExportProject = (projectDetails) => {
  return async (dispatch) => {
    dispatch({
      type: authActions.GET_SELECTED_PROJECT,
      payload: projectDetails,
    });
  };
};

export const getAwsCredentials = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState,
      "POST",
      AWSCredentialsApiEndpoint.GET_CREDENTIALS_API,
      params, //data/params
      false //isAuth
    );

    if (response?.status === 200 && response?.data?.success) {
      dispatch({
        type: credentialsActions.GET_CREDENTIALS,
        payload: response?.data?.data,
      });

      // const encryptedCredentials = response?.data?.data;

      // try {
      //   const decryptedCredentials = {};
      //   for (const [key, value] of Object.entries(encryptedCredentials)) {
      //     decryptedCredentials[key] = await sharedCryptoLib.decrypt(value);
      //   }

      //   // Dispatch the decrypted credentials to the Redux store
      //   dispatch({
      //     type: credentialsActions.GET_CREDENTIALS,
      //     payload: decryptedCredentials,
      //   });
      // } catch (error) {
      //   console.error("Error decrypting credentials:", error);
      // }
    }

    return response;
  };
};

export const sendAWSCredentials = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: credentialsActions.GET_CREDENTIALS,
      payload: data,
    });
  };
};

export const togglePress = () => {
  return async (dispatch) => {
    dispatch({
      type: authActions.TOGGLE_PRESSED,
    });
  };
};
