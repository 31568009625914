import { authActions, subscriptionActions } from "../actions/actionTypes";

const getInitialState = () => ({
  subscriptions: [],
  currentSubscription: {},
});

export const subscriptionReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case subscriptionActions.GET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action?.payload,
      };

    case subscriptionActions.GET_CURRENT_SUBSCRIPTION:
      return {
        ...state,
        currentSubscription: action?.payload,
      };

    case authActions.AUTH_LOGOUT:
      return {
        ...state,
        subscriptions: [],
        currentSubscription: {},
      };

    default:
      return state;
  }
};
