import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { SVGIMAGES } from "../../assets/svgImages";
import { SUBSCRIPTION_TYPES } from "../../constants/Enums";
import { razorpayCheckoutScript } from "../../store/apiEndpoints";
import { convertTimestampToDate, loadScript } from "../../utils/helper";
import { ModalLayout } from "../ModalLayout";
import { IMAGES } from "../../assets/images";
import { getCurrentSubscription, getSubscriptionPaymentId, getSubscriptions, sendSubscriptionPaymentObject } from "../../store/actions/subscriptionActions";
import { NotificationModal } from "./NotificationModal";
import { ButtonLoader } from "../Loader";
import { CurrentPlan } from "./CurrentPlanModal";
import { razorpayConfig } from "../../config/config";
import { Link } from "react-router-dom";

export const SubscriptionModal = ({ showSubscriptionModal, setShowSubscriptionModal, ...props }) => {
  const dispatch = useDispatch();
  const profile = useSelector(({ auth }) => auth?.loginUser);

  const subscriptions = useSelector(({ subscription }) => subscription?.subscriptions);

  const currentSubscription = useSelector(({ subscription }) => subscription?.currentSubscription);

  const LOGO = IMAGES.Logo;
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState("");
  const [subscriptionResponse, setSubscriptionResponse] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");
  const [currency, setCurrency] = useState("USD");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  useEffect(() => {
    // simulateTimeZone("America/New_York");
    var timeZone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;
    // console.log("User's Time Zone: " + timeZone);

    var indiaTimeZone1 = "Asia/Calcutta";
    var indiaTimeZone2 = "Asia/Kolkata";
    if (timeZone === indiaTimeZone1 || timeZone === indiaTimeZone2) {
      setCurrency("INR");
    } else {
      setCurrency("USD");
    }
  }, []);
  const GSTAmount = selectedSubscription?.dollar * (selectedSubscription?.gstPercentage / 100) || 0.0;
  const getBasicPlan = () => {
    const basicPlan = subscriptions?.find((subscription) => subscription?.type === SUBSCRIPTION_TYPES.BASIC);
    return basicPlan;
  };

  const getPremiumPlan = () => {
    const premiumPlan = subscriptions?.find((subscription) => subscription?.type === SUBSCRIPTION_TYPES.PREMIUM);
    return premiumPlan;
  };

  const getGoldPlan = () => {
    const goldPlan = subscriptions?.find((subscription) => subscription?.type === SUBSCRIPTION_TYPES.GOLD);
    return goldPlan;
  };

  const getExpiryDate = () => {
    let dateString = convertTimestampToDate(currentSubscription?.endDateOfSub)?.toString();
    return dateString;
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    setShowErrorModal(false);
    setShowSubscriptionModal(false);
  };

  const getSubButtonClassName = (subscription) => {
    if (subscription?.type === SUBSCRIPTION_TYPES.FREE) {
      if (currentSubscription.subscriptionId === subscription._id) {
        return "activeFreeplan";
      }
      return "btnFreeplan";
    } else if (subscription?.type === SUBSCRIPTION_TYPES.BASIC) {
      if (currentSubscription.subscriptionId === subscription._id) {
        return "activeBasicplan";
      }
      return "btnBasicplan";
    } else if (subscription?.type === SUBSCRIPTION_TYPES.PREMIUM) {
      if (currentSubscription.subscriptionId === subscription._id) {
        return "activePremiumplan";
      }
      return "btnPremiumplan";
    } else if (subscription?.type === SUBSCRIPTION_TYPES.GOLD) {
      if (currentSubscription.subscriptionId === subscription._id) {
        return "activeGoldplan";
      }
      return "btngoldplan";
    }
    return "";
  };

  const getSubButtonName = (subscription) => {
    return currentSubscription?.subscriptionId === subscription?._id && currentSubscription?.endDateOfSub >= Date.now() ? `${currentSubscription?.status} Plan` : subscription?.type !== SUBSCRIPTION_TYPES.FREE ? "Upgrade Plan" : subscription?.type === SUBSCRIPTION_TYPES.FREE && currentSubscription?.subscriptionId !== subscription?._id ? "Expired" : "Expired";
  };

  const isDisabled = (subscription) => {
    return (
      (currentSubscription?.subscriptionId === getGoldPlan()?._id && currentSubscription?.subscriptionId === getPremiumPlan()?._id && subscription?._id === getBasicPlan()?._id) || (subscription?.type === SUBSCRIPTION_TYPES.FREE && currentSubscription?.subscriptionId !== subscription?._id) || (currentSubscription?.subscriptionId === subscription?._id && currentSubscription?.endDateOfSub >= Date.now())
      //   (currentSubscription?.status === "active" &&
      //     subscription?.type !== SUBSCRIPTION_TYPES.PREMIUM &&
      //     getFreePlan()?._id !== currentSubscription?.subscriptionId)
    );
    // return currentSubscription?.subscriptionId === subscription?._id && currentSubscription?.endDateOfSub >= Date.now()
  };

  const getPriceClassName = (subscription) => {
    if (subscription?.type === SUBSCRIPTION_TYPES.FREE) {
      return "h1Freeplan";
    } else if (subscription?.type === SUBSCRIPTION_TYPES.BASIC) {
      return "h1Basicplan";
    } else if (subscription?.type === SUBSCRIPTION_TYPES.PREMIUM) {
      return "h1Premiumplan";
    } else if (subscription?.type === SUBSCRIPTION_TYPES.GOLD) {
      return "h1goldplan";
    }
    return "";
  };

  const getPlanDetails = (subscription) => {
    if (subscription?.type === SUBSCRIPTION_TYPES.FREE) {
      return {
        icon: SVGIMAGES.FreePlanIcon,
        name: "Free Plan",
        duration: "For 3 Months",
      };
    } else if (subscription?.type === SUBSCRIPTION_TYPES.BASIC) {
      return {
        icon: SVGIMAGES.BasicPlanIcon,
        name: "Basic Plan",
        duration: "Month",
      };
    } else if (subscription?.type === SUBSCRIPTION_TYPES.PREMIUM) {
      return {
        icon: SVGIMAGES.PremiumPlanIcon,
        name: "Premium Plan",
        duration: "Year",
      };
    } else if (subscription?.type === SUBSCRIPTION_TYPES.GOLD) {
      return {
        icon: IMAGES.GoldPlanIcon,
        name: "Gold Plan",
        duration: "Year",
      };
    }
    return {
      icon: null,
      name: "Unknown Plan",
    };
  };

  const getPlanCardClass = (subscription) => {
    if (currentSubscription?.subscriptionId === subscription?._id && subscription?.type === SUBSCRIPTION_TYPES.FREE) {
      return "activeFP";
    } else if (currentSubscription?.subscriptionId === subscription?._id && subscription?.type === SUBSCRIPTION_TYPES.BASIC) {
      return "activeBP";
    } else if (currentSubscription?.subscriptionId === subscription?._id && subscription?.type === SUBSCRIPTION_TYPES.PREMIUM) {
      return "activePP";
    } else if (currentSubscription?.subscriptionId === subscription?._id && subscription?.type === SUBSCRIPTION_TYPES.GOLD) {
      return "activeGP";
    } else {
      return "";
    }
  };

  const getLoaderColor = (subscription) => {
    if (subscription?.type === SUBSCRIPTION_TYPES.FREE) {
      return "#F9896B";
    } else if (subscription?.type === SUBSCRIPTION_TYPES.BASIC) {
      return "#8C3AF6";
    } else if (subscription?.type === SUBSCRIPTION_TYPES.PREMIUM) {
      return "#46ADB8";
    } else if (subscription?.type === SUBSCRIPTION_TYPES.GOLD) {
      return "#FEC72F";
    } else {
      return "#fff";
    }
  };
  function simulateTimeZone(timeZone) {
    Intl.DateTimeFormat.prototype.resolvedOptions = function () {
      return { timeZone: timeZone };
    };
  }

  async function renderRazorpay(subscriptionId) {
    setIsLoading(true);
    const res = await loadScript(razorpayCheckoutScript);

    if (!res) {
      setPaymentResponse({
        message: "Razorpay SDK failed to load. Are you online?",
      });
      setIsLoading(false);
      setShowErrorModal(true);
      return;
    }

    try {
      await dispatch(getSubscriptionPaymentId({ subscriptionId, currency })).then((result) => {
        if (result?.data?.success) {
          const { id, razorpaySubscriptionId } = result.data.data;
          const options = {
            key: razorpayConfig.RAZORPAY_KEY_ID,
            name: "Folio4u",
            description: "Test Transaction",
            image: { LOGO },
            order_id: razorpaySubscriptionId,
            handler: async function (response) {
              setSubscriptionResponse(response);
              if (response?.razorpay_payment_id) {
                // Upgrade subscription plan step-2
                const paymentResult = await dispatch(
                  sendSubscriptionPaymentObject({
                    id,
                    paymentObject: response,
                  })
                );

                setPaymentResponse(paymentResult?.data);
                if (paymentResult?.data?.success) {
                  dispatch(getSubscriptions());
                  dispatch(getCurrentSubscription());
                  setShowSuccessModal(true);
                } else {
                  console.error("Payment Failed:", response);
                  setShowErrorModal(true);
                }
              }
            },
            prefill: {
              name: profile.name,
              email: profile.email,
              contact: profile?.countryCode + profile?.phoneNumber,
              company_name: profile?.name,
              company_id: profile?._id,
            },
            theme: {
              color: "#000000",
            },
          };
          const paymentObject = new window.Razorpay(options);

          paymentObject.on("payment.failed", (response) => {
            console.error("Payment Failed:", response);
            paymentObject.close();
            setPaymentResponse(response?.error);
            setShowErrorModal(true);
          });

          paymentObject.open();
        } else {
          console.error("error", result);
          setPaymentResponse(result?.response?.data || result);
          setShowErrorModal(true);
        }
      });
    } catch (error) {
      console.error("error", error);
      setPaymentResponse(error);
      setShowSubscriptionModal(false);
      setShowErrorModal(true);
    } finally {
      setIsLoading(false);
      dispatch(getSubscriptions());
      dispatch(getCurrentSubscription());
    }
  }
  const featureDisplayNames = {
    project: "Project Limit",
    screenshot: "Image Limit",
    videosize: "Video Size Limit",
    imagesize: "Image Size Limit",
    sharelinkcreatecount: "Share Portfolio Limit",
  };

  const filteredSubscriptions = subscriptions?.filter((subscription) => {
    if (subscription?.type === SUBSCRIPTION_TYPES.FREE) {
      return subscription?._id === currentSubscription?.subscriptionId;
    }
    if (subscription?.type === SUBSCRIPTION_TYPES.BASIC) {
      if (getPremiumPlan()?._id === currentSubscription?.subscriptionId && currentSubscription?.endDateOfSub >= Date.now()) {
        return false;
      }
      return true;
    }
    return true;
  });
  const getClassBySubLength = () => {
    if (filteredSubscriptions?.length === 4) {
      return "subscriboxfore";
    }
    if (filteredSubscriptions?.length === 3) {
      return "subscriboxthree";
    }
    if (filteredSubscriptions?.length === 2) {
      return "subscriboxTwo";
    }
    return "";
  };

  return (
    <React.Fragment>
      {currentSubscription?.endDateOfSub >= Date.now() && currentSubscription?.subscriptionId === getGoldPlan()?._id ? (
        <CurrentPlan
          showSubscriptionModal={showSubscriptionModal}
          setShowSubscriptionModal={setShowSubscriptionModal}
          planDetails={getGoldPlan()}
          onClose={handleCloseModal}
        />
      ) : showSuccessModal ? (
        <NotificationModal
          showModal={showSuccessModal}
          setShowModal={setShowSuccessModal}
          onClose={handleCloseModal}
          title={"Payment Successful"}
          type={"success"}
          paymentResponse={{ ...paymentResponse, ...subscriptionResponse }}
          message={
            <span>
              An invoice has been sent to your registered email address: <b>{profile?.email}</b>.
            </span>
          }
        />
      ) : showErrorModal ? (
        <NotificationModal
          showModal={showErrorModal}
          setShowModal={setShowErrorModal}
          onClose={() => handleCloseModal()}
          title={"Payment Failed"}
          type={"error"}
          paymentResponse={{ ...paymentResponse, ...subscriptionResponse }}
          message={<span>{paymentResponse?.message}</span>}
        />
      ) : (
        <ModalLayout
          show={showSubscriptionModal}
          setShow={setShowSubscriptionModal}
          onClose={() => {
            handleCloseModal();
            props.onClose();
          }}
          centered
          title={selectedSubscription === null ? "✨ Enjoy Peace of Mind with Our Payment Plans! ✨" : "Plan Details"}
          className={`subscription_body ${selectedSubscription === null ? getClassBySubLength() : "subscriboxTwo"}`}
          message={props?.message}
        >
          {selectedSubscription === null && (
            <>
              <p>Choose the subscription plan that suits you and your business best.</p>
              <ul className="freeToPay">
                <li>🔒 No Credit Card Needed</li>
                <li>🔒 No Debit Card Required </li>
                <li>🔒 No Auto Payments</li>
              </ul>
              <div className="maincuscolmdsubcri">
                {subscriptions?.length > 0 &&
                  subscriptions
                    ?.sort((a, b) => a.sequenceId - b.sequenceId)
                    ?.filter((subscription) => {
                      if (subscription?.type === SUBSCRIPTION_TYPES.FREE) {
                        return subscription?._id === currentSubscription?.subscriptionId;
                      }
                      if (subscription?.type === SUBSCRIPTION_TYPES.BASIC) {
                        if (getPremiumPlan()?._id === currentSubscription?.subscriptionId && currentSubscription?.endDateOfSub >= Date.now()) {
                          return false;
                        }
                        return true;
                      }
                      return true;
                    })
                    ?.map((subscription, index) => (
                      <div
                        className="cuscolmdsubcri"
                        key={`subcription ${index + 1}`}
                      >
                        <div className={`subscriAuth ${getPlanCardClass(subscription)}`}>
                          <h3>
                            {subscription?.type && (
                              <img
                                src={getPlanDetails(subscription)?.icon}
                                alt=""
                              />
                            )}
                            {getPlanDetails(subscription)?.name}
                          </h3>
                          <h1 className={getPriceClassName(subscription)}>
                            {/* {getPrice(subscription)} */}
                            {`${subscription?.dollar > 0 ? "$ " + subscription?.dollar : "Free"}`}
                            <span className={"yearSpanClass"}>/{`${getPlanDetails(subscription)?.duration}`} </span>
                          </h1>

                          <hr />
                          {subscription?.features?.length > 0 &&
                            subscription.features.map((feature, index) => {
                              const displayName = featureDisplayNames[feature.type.toLowerCase()];
                              const limit = feature.limit < 10 ? `0${feature.limit}` : feature.limit;

                              return (
                                <h6
                                  className="text-capitalize"
                                  key={`feature ${index + 1}`}
                                >
                                  {`${displayName} : ${limit}`}
                                </h6>
                              );
                            })}
                          <button
                            disabled={isDisabled(subscription)}
                            className={getSubButtonClassName(subscription)}
                            onClick={() => {
                              setSubscriptionId(subscription?._id);
                              currency === "INR" ? setSelectedSubscription(subscription) : renderRazorpay(subscription?._id);
                            }}
                          >
                            {isLoading && subscriptionId === subscription?._id ? (
                              <ButtonLoader
                                color={getLoaderColor(subscription)}
                                width={19}
                              />
                            ) : (
                              getSubButtonName(subscription)
                            )}
                          </button>
                        </div>
                        {currentSubscription?.subscriptionId === subscription?._id && (
                          <h1 className="expTimeShow">
                            {currentSubscription?.endDateOfSub <= Date.now() ? (
                              <>
                                {" "}
                                <span style={{ color: "red", fontSize: 16, fontWeight: 600 }}>Expired</span>
                              </>
                            ) : (
                              <>
                                Expires on <span>{getExpiryDate()}</span>
                              </>
                            )}{" "}
                          </h1>
                        )}

                        {/* <h1 className="expTimeShow">Expires on <span>2024-09-05 13: 50</span></h1> */}
                      </div>
                    ))}
              </div>
            </>
          )}

          {selectedSubscription && (
            <div className="maincuscolmdsubcri">
              <div className="cuscolmdsubcri">
                <div className={`subscriAuth ${getPlanCardClass(selectedSubscription)}`}>
                  <h3 style={{ justifyContent: "center", marginBottom: 10 }}>
                    {selectedSubscription?.type && (
                      <img
                        src={getPlanDetails(selectedSubscription)?.icon}
                        alt=""
                      />
                    )}
                    {getPlanDetails(selectedSubscription)?.name}
                  </h3>
                  <hr />

                  <div className="row cusGstpt">
                    <div className="col-md-6">
                      <div className="GS_leftpart">
                        {selectedSubscription?.features?.length > 0 &&
                          selectedSubscription.features.map((feature, index) => {
                            const displayName = featureDisplayNames[feature.type.toLowerCase()];
                            const limit = feature.limit < 10 ? `0${feature.limit}` : feature.limit;

                            return (
                              <h6
                                className="text-capitalize"
                                key={`feature ${index + 1}`}
                              >
                                {`${displayName} : ${limit}`}
                              </h6>
                            );
                          })}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="GS_rightpart">
                        <h2>
                          <h6 className="text-capitalize"> Base Amount</h6>
                          <h6 className="text-capitalize"> : $ {parseFloat(selectedSubscription.dollar).toFixed(2)}</h6>
                        </h2>

                        <h2>
                          <h6 className="text-capitalize"> GST ({selectedSubscription?.gstPercentage || 0}%)</h6>
                          <h6 className="text-capitalize"> : $ {parseFloat(GSTAmount || 0.0).toFixed(2)}</h6>
                        </h2>
                        <h2>
                          <h6 className="text-capitalize"> Total Payable Amount</h6>
                          <h6 className="text-capitalize"> : $ {parseFloat(selectedSubscription.dollar + GSTAmount).toFixed(2)}</h6>
                        </h2>
                      </div>
                    </div>
                  </div>

                  <button
                    className={getSubButtonClassName(selectedSubscription)}
                    onClick={() => {
                      setSubscriptionId(selectedSubscription?._id);
                      renderRazorpay(selectedSubscription?._id);
                    }}
                  >
                    {isLoading && subscriptionId === selectedSubscription?._id ? (
                      <ButtonLoader
                        color={getLoaderColor(selectedSubscription)}
                        width={19}
                      />
                    ) : (
                      "Pay Now"
                    )}
                  </button>
                </div>
                <Link
                  onClick={() => {
                    setSelectedSubscription(null);
                  }}
                  style={{ textAlign: "center", marginTop: 15, fontSize: 14, fontWeight: 600, display: "block" }}
                >
                  Back{" "}
                </Link>
              </div>
            </div>
          )}
        </ModalLayout>
      )}
    </React.Fragment>
  );
};
