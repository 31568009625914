import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ButtonLoader } from "./Loader";
import { ModalLayout } from "./ModalLayout";
import { doAuthLogout, togglePress } from "../store/actions/authActions";
import { IMAGES } from "../assets/images";
import { SVGIMAGES } from "../assets/svgImages";
import {
  getSubscriptions,
  getCurrentSubscription,
} from "../store/actions/subscriptionActions";
import { SubscriptionModal } from "./Modals";
import Ticker from "react-ticker";
import { formatRemainingDays } from "../utils/helper";
import { useLocation } from 'react-router-dom';

export const HeaderView = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(({ auth }) => auth?.loginUser);
  const currentSubscription = useSelector(
    ({ subscription }) => subscription?.currentSubscription
  );
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [expiredPlanModal, setExpiredPlanModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const daysRemaining =formatRemainingDays(currentSubscription?.endDateOfSub)

  
  let message = '';
 if (daysRemaining <= 30 && daysRemaining > 1) {
    message = `Attention! Your subscription will end in ${daysRemaining} days. Please renew to continue enjoying our services.`;
  } else if (daysRemaining === 1) {
    message = 'Attention! Your subscription will expire tomorrow. Please renew to continue enjoying our services.';
  } else if (daysRemaining <= 0) {
    message = 'Your subscription has expired. Please renew to continue enjoying our services.';
  }

  useEffect(() => {
   dispatch(getSubscriptions());
    dispatch(getCurrentSubscription());
  }, [dispatch, showSubscriptionModal]);

  const handleLogout = () => {
    setIsLoading(true);
    try {
      dispatch(doAuthLogout()).then(() => {
        navigate("/");
      });
    } catch {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(()=>{
    if(currentSubscription?.endDateOfSub <= Date.now()){
      setExpiredPlanModal(true);
    }
  },[currentSubscription.subscriptionId])

  return (
    <>
    
      <div className={daysRemaining >30 ?"header_dash" :"header_dash header_dash30less"}>
      {daysRemaining <=30 &&
      <div className="marqeeboxCus">
        <Ticker mode="smooth" >
          {({ index }) => (
            <>
              <p>{message}</p>
            
            </>
          )}
        </Ticker>
        </div>
        }
        <div className="">        
          <nav className="navbar navbar-expand-md navbar-dark d-flex justify-content-between">
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="AdminNameHead">
                <h2>
                  <img
                    src={SVGIMAGES.MenuToggleImg}
                    alt=""
                    onClick={() => {
                      dispatch(togglePress());
                    }}
                  />
                   <img src={SVGIMAGES.sideBarIcon} alt="" className="mobilelogoImg" />
                </h2>
               
              </div>
              
              <ul className="navbar-nav ml-auto">
                <li
                  className="nav-item dropdown"
                  onClick={() => {
                    setShow(!show);
                  }}
                >
                  <button
                    className={
                      show
                        ? "nav-link profile-drop dropdown-toggle show"
                        : "nav-link profile-drop dropdown-toggle"
                    }
                    aria-expanded="false"
                  >
                    <img
                      src={profile?.logo ? profile?.logo : IMAGES.UploadLogo}
                      className="userIconimg"
                      alt=""
                    />
                    <img
                      src={SVGIMAGES.DropDownIcon}
                      className="right-down"
                      alt=""
                    />
                  </button>
                  <div
                    className={
                      show ? "dropdown-menu dropdownOpen" : "dropdown-menu"
                    }
                    aria-labelledby="navbar DropdownMenuLink"
                  >
                    <button
                      className="dropdown-item"
                      onClick={() => navigate("/profile")}
                    >
                      Profile
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => navigate("/change-password")}
                    >
                      Change Password
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => setShowSubscriptionModal(true)}
                    >
                      Subscription
                    </button>
                                      
                    <hr />
                    <button
                      className="dropdown-item dropdown-item-no"
                      onClick={() => setLogoutModal(true)}
                    >
                      Logout
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>

      {logoutModal && (
        <ModalLayout show={logoutModal} setShow={setLogoutModal} centered>
          <img
            src={SVGIMAGES.LogoutModaleIcon}
            alt=""
            className="mainIconModal"
          />
          <h2>Logout</h2>
          <p>Are you sure you want to Logout?</p>
          <div className="footbutton">
            <button
              type="button"
              onClick={() => {
                setLogoutModal(false);
              }}
              className="cancelBtn"
            >
              Cancel
            </button>
            <button
              type="button"
              className="confirmBtn"
              onClick={() => {
                handleLogout();
              }}
            >
              {isLoading ? <ButtonLoader /> : "Logout"}
            </button>
          </div>
        </ModalLayout>
      )}

      {showSubscriptionModal && (
        <SubscriptionModal
          showSubscriptionModal={showSubscriptionModal}
          setShowSubscriptionModal={setShowSubscriptionModal}
          message=""
          onClose={()=> currentSubscription?.endDateOfSub <= Date.now() && setExpiredPlanModal(true)}
        />
      )}

      {expiredPlanModal && (
        <ModalLayout  show={expiredPlanModal} setShow={setExpiredPlanModal} centered className="expiredmodalcss" closeButton={false}>
          <img
            src={IMAGES.ExpiredIconPlan}
            alt=""
            className="mainIconModal "
          />
          <h2>Your plan has been expired</h2>
          <p>Please renew it plan to continue accessing our services.</p>
          <h6>If you need help, contact us at <a href="mailto:support@folio4u.com">support@folio4u.com</a></h6>
          <div className="footbutton">
            {/* <button
              type="button"
              onClick={() => {
                setExpiredPlanModal(false);
              }}
              className="cancelBtn"
            >
              Cancel
            </button> */}
            <button
              type="button"
              className=""
              onClick={() => {
                setShowSubscriptionModal(true);
                setExpiredPlanModal(false)
              }}
            >
              {isLoading ? <ButtonLoader /> : "Continue"}
            </button>             
          </div>
          <button className="cancelBtn" type="button"
              onClick={() => {
                handleLogout();
              }}
            >
              {isLoading ? <ButtonLoader /> : "Logout"}</button>
        </ModalLayout>
      )}

    </>
  );
};
