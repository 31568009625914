import React from "react";
import { useNavigate } from "react-router-dom";
import { IMAGES } from "../../assets/images";

import TopIconImg from "../../assets/images/webImage/topIcon.svg";


export const WebFooterView = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="downloadSection" id="DownloadSection">
                <div className="container-fluid">
                    <div className="downloadSec_Box">
                        <div className="downLoadMark"></div>
                        <h6>Download the Folio4u app now!</h6>
                        <p>Be the first to explore one of the best Portfolio management solution.</p>
                        <div className="appDownButn">
                            <button><a href="https://play.google.com/store/apps/details?id=com.folio4u.app" target="_blank" rel="noreferrer"><img src={IMAGES.GooglePlay} alt="" /></a></button>
                            <button><a href="https://apps.apple.com/us/app/folio4u/id6514303408?platform=ipad" target="_blank" rel="noreferrer"><img src={IMAGES.AppStore} alt="" /></a></button>
                            <button><a href="https://apps.apple.com/us/app/folio4u/id6514303408?platform=mac" target="_blank" rel="noreferrer"><img src={IMAGES.MacStore} alt="" /></a></button>
                            <button><a href="https://apps.microsoft.com/detail/9nbrh49p99qd?cid=DevShareMCLPCB&hl=en-us&gl=IN" target="_blank" rel="noreferrer"><img src={IMAGES.Windows} alt="" /></a></button>
                        </div>
                    </div>
                </div>
            </div>
      <footer>
        <div className="container-fluid">
          <span className="foot_flex">
            <div className="foot_logo">
              <button onClick={() => navigate("/")}>
                <img src={IMAGES.LogoWeb} alt="Logo" />              
              </button>
            </div>           
            <ul>
              <li>
                <button type="button"
                  onClick={() => {
                    navigate("/privacy-policy");
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  Privacy Policy
                </button>
              </li>
              <li>
                <button type="button"
                  onClick={() => {
                    navigate("/terms-conditions");
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  Terms & Conditions
                </button>
              </li>
              <li>
                <button type="button"
                  onClick={() => {
                    navigate("/faqs");
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  FAQ's
                </button>
              </li>
            </ul>
            <div className="socialLink">
              <ul>
                <li><button><a href="https://x.com/Helpteam27" target="_blank"><img src={IMAGES.TwitterIcon} alt="" /></a></button></li>
                <li><button><a href="https://www.facebook.com/bytecipher/" target="_blank"><img src={IMAGES.FacebookIcon} alt="" /></a></button></li>
                <li><button><a href="https://instagram.com/bytecipher_pvt_ltd/" target="_blank"><img src={IMAGES.InSIcon} alt="" /></a></button></li>
                <li><button><a href="https://www.linkedin.com/in/portfolio-team-791375317/" target="_blank"><img src={IMAGES.linkedInIcon} alt="" /></a></button></li>
              </ul>
            </div>
          </span> 
          
        </div>
      </footer>
      <div className="coppyBox">
        <div className="container-fluid">
            <p>Copyright © 2024-2025 <a href={"https://bytecipher.net/"} target="_black">ByteCipher</a>. All Rights Reserved</p>
        </div>
      </div>  

      <div className="top-to-btm">
        <div
          className="icon-position icon-style"
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <img src={TopIconImg} alt="Arrow" />
        </div>
      </div>
    </>
  );
};




