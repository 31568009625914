import { authActions, credentialsActions } from "../actions/actionTypes";

const getInitialState = () => ({
  loginUser: {},
  accessToken: "",
  refreshToken: "",
  userType: "",
  categoryList: [],
  exportProjects: [],
  selectedExportProject: {},
  awsCredentials: {},
  collapseSidebar: false
});

export const authReducer = (state = getInitialState(), action) => {


  switch (action.type) {
    case authActions.AUTH_LOGIN:
      return {
        ...state,
        loginUser: action?.payload?.user,
        accessToken: action?.payload?.accessToken,
        refreshToken: action?.payload?.refreshToken,
        userType: action.payload.userType,
      };
    case authActions.REFRESH_TOKEN:
      return {
        ...state,
        accessToken: action?.payload?.accessToken,
        refreshToken: action?.payload?.refreshToken,
      }
    case authActions.GET_PROFILE: {
      return {
        ...state,
        loginUser: action?.payload?.data,
      };
    }

    case authActions.PROFILE_UPDATE: {
      return {
        ...state,
        loginUser: action?.payload?.data,
      };
    }

    case authActions.GET_CATEGORY_DATA:
      return {
        ...state,
        categoryList: action.payload,
      };

    case authActions.GET_EXPORT_PROJECTS:
      return {
        ...state,
        exportProjects: action.payload,
      };

    case authActions.GET_SELECTED_PROJECT:
      return {
        ...state,
        selectedExportProject: action.payload,
      };

    case authActions.TOGGLE_PRESSED:
      return {
        ...state,
        collapseSidebar: state.collapseSidebar ? false : true,
      };
    case credentialsActions.GET_CREDENTIALS:
      return {
        ...state,
        awsCredentials: action.payload,
      };

    case authActions.AUTH_LOGOUT:
      return {
        ...state,
        loginUser: {},
        accessToken: "",
        refreshToken: "",
        userType: "",
        categoryList: [],
        exportProjects: [],
        selectedExportProject: {},
        awsCredentials: {},
        collapseSidebar: false
      };
    default:
      return state;
  }
};
