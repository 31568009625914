import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import { IMAGES } from "../../assets/images";
import { SVGIMAGES } from "../../assets/svgImages";
import { ImageLoader, ModalLayout } from "../../components";
import { useExpirationCheck } from "../../components/Hooks/useExpirationCheck";
import { formatRemainingTime } from "../../utils/helper";

export const HomeExportDetail = () => {
    const navigate = useNavigate();
    const projectDetails = useSelector(({ auth }) => auth?.selectedExportProject);
    const exportProjects = useSelector(({ auth }) => auth?.exportProjects) || [];
    const { isLinkExpired, remainingTime } = useExpirationCheck(exportProjects?.linkExpiredTime, exportProjects?.projectIds);
    const [videoViewModal, setVideoViewModal] = useState(false);
    const [imageLoaded, setImageLoaded] = useState({}); // State to track loaded images

    const handleImageLoad = (index) => {
        setImageLoaded((prevState) => ({
            ...prevState,
            [index]: true,
        }));
    };

    return (
        <>
            <div className="exportSection">
                <div className="backbuttonview">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-1">
                        <button
                            onClick={() =>
                                navigate(`/projectDetails/${projectDetails?.uniqueId}`)
                            }
                        >
                            
                            <img src={IMAGES.BAckButtonIcon} alt="" />{" "}
                        </button>
                        </div>
                        <div className="col-11">
                        <span className="expiryTime expiryTimedetial">Expiring in : {formatRemainingTime(remainingTime)}</span>
                        </div>
                        </div>
                    </div>
                </div>
                {isLinkExpired ? (
                    <div className="loginBg">
                        <div className="container-fluid">
                            <div className="align-self-center pad-0 form-section">
                                <div className="mainLoginbox text-center pageNotFoundBox">
                                    <img src={IMAGES.PageNotFoundImg} alt="" />
                                    <h1>Link Expired</h1>
                                    <p>
                                        The link has expired. Please contact support or request a
                                        new link.
                                    </p>
                                    <button
                                        className="formButton"
                                        onClick={() => navigate("/projects")}
                                    >
                                        Go Back Dashboard
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>) : (
                    <>
                        <div className="projectDetail">
                            <div className="container-fluid">
                                <div className="row">
                                    {projectDetails?.screenShots &&
                                        projectDetails?.screenShots.length > 0 && (
                                            <div className="col-md-6">
                                                <Carousel>
                                                    {projectDetails.screenShots.map((screenshot, index) => (
                                                        <Carousel.Item key={`slider ${index + 1}`}>
                                                            {!imageLoaded[index] && (
                                                                <div className="image-loader">
                                                                    <ImageLoader color={"#f9896b"} width={80} />
                                                                </div>
                                                            )}

                                                            <img
                                                                src={`${screenshot}`}
                                                                alt=""
                                                                onLoad={() => handleImageLoad(index)}
                                                                style={{
                                                                    display: imageLoaded[index] ? "block" : "none",
                                                                }}
                                                            />
                                                        </Carousel.Item>
                                                    ))}
                                                </Carousel>
                                            </div>
                                        )}
                                    <div className="col-md-6 ">
                                        <Tabs
                                            defaultActiveKey="project"
                                            id="uncontrolled-tab-example"
                                            className="mb-3"
                                        >
                                            <Tab eventKey="project" title="Project">
                                                {projectDetails?.name && (
                                                    <h2>
                                                        <span>Project :</span> {projectDetails?.name}
                                                    </h2>
                                                )}
                                                {projectDetails?.website && (
                                                    <h2>
                                                        <span>Website :</span>
                                                        <a href={projectDetails?.website}>
                                                            {projectDetails?.website}
                                                        </a>{" "}
                                                    </h2>
                                                )}
                                                {projectDetails?.domainId && (
                                                    <h2>
                                                        <span>Domain :</span>{" "}
                                                        {projectDetails?.domainId
                                                            ?.map((domain) => domain?.title)
                                                            ?.toString()}
                                                    </h2>
                                                )}
                                                {projectDetails?.platformId && (
                                                    <h2>
                                                        <span>Platform :</span>{" "}
                                                        {projectDetails?.platformId
                                                            ?.map((platform) => platform?.title)
                                                            ?.toString()}
                                                    </h2>
                                                )}
                                                {projectDetails?.technologyId && (
                                                    <h2>
                                                        <span>Technology :</span>{" "}
                                                        {projectDetails?.technologyId
                                                            ?.map((technology) => technology?.title)
                                                            ?.toString()}
                                                    </h2>
                                                )}
                                                {projectDetails?.duration && (
                                                    <h2>
                                                        <span>Duration :</span> {projectDetails?.duration}
                                                    </h2>
                                                )}
                                                {projectDetails?.teamSize && (
                                                    <h2>
                                                        <span>Team Size :</span> {projectDetails?.teamSize}
                                                    </h2>
                                                )}
                                            </Tab>
                                            <Tab eventKey="client" title="Client">
                                                {projectDetails?.clientName && (
                                                    <h2>
                                                        <span>Client Name :</span> {projectDetails?.clientName}
                                                    </h2>
                                                )}
                                                {projectDetails?.clientCountry && (
                                                    <h2>
                                                        <span>Client Country :</span>{" "}
                                                        {projectDetails?.clientCountry}
                                                    </h2>
                                                )}
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="projectDescri">
                            <div className="container-fluid">
                                <h2>Project Description</h2>
                                <p>{projectDetails?.description}</p>
                            </div>
                        </div>
                        <div className="videoViewSec">
                            <div className="container-fluid">
                                {projectDetails?.videoLink && (
                                    <div className="videoThumb">
                                        <video
                                            type="video/mp4"
                                            src={projectDetails?.videoLink}
                                            className="img-fluid imgmainThum"
                                        />
                                        <div className="overflowClose" />
                                        <button type="button" onClick={() => setVideoViewModal(true)}>
                                            <img src={SVGIMAGES.VideoPlayIcon} alt="" />
                                        </button>
                                    </div>
                                )}
                                {(projectDetails?.iosAppStoreLink ||
                                    projectDetails?.androidPlayStoreLink) && (
                                        <>
                                            <h2>Download Now!</h2>
                                            <h6>
                                                {projectDetails?.iosAppStoreLink && (
                                                    <button>
                                                        <a href={projectDetails?.iosAppStoreLink}>
                                                            <img src={IMAGES.AppStore} alt="" />
                                                        </a>
                                                    </button>
                                                )}
                                                {projectDetails?.androidPlayStoreLink && (
                                                    <button>
                                                        <a href={projectDetails?.androidPlayStoreLink}>
                                                            <img src={IMAGES.GooglePlay} alt="" />
                                                        </a>
                                                    </button>
                                                )}
                                            </h6>
                                        </>
                                    )}
                            </div>
                        </div>
                    </>
                )
                }

                <div className="exportFootBx">
                    <div className="container-fluid ">
                        <p>Powered by <a href="https://bytecipher.net/" >ByteCipher</a></p>
                    </div>
                </div>
            </div>
            {videoViewModal && (
                <ModalLayout
                    show={videoViewModal}
                    setShow={setVideoViewModal}
                    centered
                    title={"Project Video "}
                    className={"customLargeWidth macbookipd"}
                    closeButton={true}
                >
                    <video className="w-100 videocusHeight" controls autoPlay>
                        <source
                            src={projectDetails?.videoLink}
                            type="video/mp4"
                            allowFullScreen
                        />
                    </video>
                </ModalLayout>
            )}
        </>
    );
};
