import { PortfolioApiEndpoints } from "../apiEndpoints";
import { Instance } from "../apiServices";
import { sharedPortfolioActions } from "./actionTypes";
import { refreshToken } from "./authActions";


export const getPortfolioList = (params) => {
  
  const pageNumber = params?.currentPage;
  const limit = params?.pageLimit;
   return async (dispatch, getState) => {
    const response = await Instance(
       getState, //state
      "GET", //method
       PortfolioApiEndpoints.GET_PORTFOLIO_LIST_API +
        `?page=${pageNumber}&limit=${limit}`,
        null, //data/params
        true //isAuth
    );
    if (response?.status === 200 && response?.data?.success) {
        dispatch({
            type: sharedPortfolioActions.GET_SHAREDPORTFOLIO_LIST,
            payload: response?.data,
          });
      return response;
    }  else {
      return response.response;
    }
  };
};
export const deletePortfolio = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "DELETE", //method
      PortfolioApiEndpoints.DELETE_PORTFOLIO + params, //api-endpoint
      params, //data/params
      true, //isAuth
      true
    );
  if (response?.status === 200 && response?.data?.success) {
      return response;
    } else if (
      response?.response?.status === 403 &&
      !response?.response?.data?.success
    ) {
      const res = await dispatch(refreshToken());
      if (res?.data?.success && res?.status === 200) {
         dispatch(deletePortfolio(params))
      }
    } else {
      return response.response;
    }
  };
};

export const updatePortfolio = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "PUT", //method
      PortfolioApiEndpoints.UPDATE_PORTFOLIO, //api-endpoint
      params, //data/params
      true, //isAuth
      false
    );

    if (response?.status === 200 && response?.data?.success) {
      return response;
    }else if (
      response?.response?.status === 403 &&
      !response?.response?.data?.success
    ) {
      const res = await dispatch(refreshToken());
      if (res?.data?.success && res?.status === 200) {
         dispatch(updatePortfolio(params))
      }
    } else {
      return response.response;
    }
  };
};
