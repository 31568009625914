import React from "react";
import ReactSelect from "react-select";

const itemsPerPageOptions = [
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 20, label: "20" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];

export const ItemsPerPageDropdown = ({ pageLimit, setPageLimit, setCurrentPage }) => {
  const handleChange = (selectedOption) => {
    setPageLimit(selectedOption ? selectedOption.value : 10);
    setCurrentPage(1); // Reset to first page
  };

  return (
    <div className="d-flex align-items-center itemsreactSelect mb-3">
      <h6 className="me-2 d-flex ">Records per page
      <ReactSelect
        className="react-select-container custom-select-width itemsperpag"
        classNamePrefix="react-select"
        placeholder="Select"
        options={itemsPerPageOptions}
        value={itemsPerPageOptions.find(option => option.value === pageLimit)}
        onChange={handleChange}
        isClearable={false}
        isSearchable={false}
      />
      </h6>
    </div>
  );
};
