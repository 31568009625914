import { authActions, technologyActions } from "../actions/actionTypes";

const intialState=()=>({
    technologyList:[],
    totalPages:0,
    totalItems:0,
})

export const technologyReducer=(state=intialState,action)=>{
    switch (action.type) {
        case technologyActions.GET_TECHNOLOGY_LIST:
        return {
            ...state,
            technologyList:action.payload?.data,
            totalPages:action?.payload?.totalPages,
            totalItems:action?.payload?.totalItems
        };
        case authActions.AUTH_LOGOUT:
        return{
         ...state,
         technologyList:[],
         totalPages:0,
         totalItems:0,
        };
        default:
           return state
    }

}