import { authActions, platformActions } from "../actions/actionTypes"



const intialState=()=>({
    platformList:[],
    totalPages:0,
    totalItems:0,
})

export const platformReducer=(state=intialState,action)=>{
  
    switch (action.type) {
        case platformActions.GET_PLATFORM_LIST:
         return {
            ...state,
            platformList :action?.payload?.data,
            totalPages:action?.payload?.totalPages,
            totalItems:action?.payload?.totalItems
         };
         case authActions.AUTH_LOGOUT:
         return{
            ...state,
            platformList:[],
            totalPages:0,
            totalItems:0,
         };
        default:
            return state
    }
}