import React, { useLayoutEffect, useRef, useState } from "react";

import { IMAGES } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ModalLayout } from "../../components";


export const WebHeaderView = () => {
  const profile = useSelector(({ auth }) => auth?.loginUser)
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const [videoView, setVideoView] = useState(false);



  const stickyMyHeader = useRef();

  useLayoutEffect(() => {
    const mainSticky = document.getElementById("mainSticky");
    let fixedTop = stickyMyHeader.current.offsetTop;
    const fixedHeader = () => {
      if (window.pageYOffset > fixedTop) {
        mainSticky.classList.add("fixedTop");
      } else {
        mainSticky.classList.remove("fixedTop");
      }
    };
    window.addEventListener("scroll", fixedHeader);
  }, []);

  return (
    <>
      <header id="mainSticky" ref={stickyMyHeader}>
        <div className="container-fluid">
          <div className="navigation-wrap start-header start-style">
            <nav className="navbar navbar-expand-md navbar-light">
              <span>
                <button
                  className="navbar-toggler"
                  onClick={() => setShow(!show)}
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarContent"
                  aria-controls="navbarContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  {show ? (
                    <img
                      src={IMAGES.CLoseButton}
                      alt="Close"
                      className="toggleImgShows"
                    />
                  ) : (
                    <img
                      src={IMAGES.ToggelMenuIcon}
                      alt="Menu"
                      className="toggleMuneShows"
                    />
                  )}
                </button>

                <button className="navbar-brand" onClick={() => navigate('/')}>
                  <img src={IMAGES.LogoWeb} alt="Logo" />
                </button>
              </span>

              <div className="getTheApp mobileViewApp">
                {/* <button className="btnBlacksed" onClick={() => {
                  navigate("/login")
                }} >Login</button> */}

                 {profile && Object.keys(profile).length !== 0 ? (
                  <div className="loginViewPro" onClick={()=> navigate('/projects')}>
                    <div className="profileBxIcon">
                      <img
                        src={profile.logo ? profile.logo : IMAGES.UploadLogo}                      
                        alt=""
                      />
                    </div>
                    <h2>{profile.name}</h2>
                  </div>
                ) : (
                  <button
                    className="btnBlacksed"
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Login
                  </button>
                )}      
              </div>

              <div
                className={`collapse navbar-collapse ${show ? "show" : ""}`}
                id="navbarContent"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <button
                      className={
                        window.location.pathname === "/"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={() => {
                        navigate("/")
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      Home
                    </button>
                  </li>

                  <li className="nav-item">
                    <button className={
                        window.location.pathname === "/about-us"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={() => {
                        navigate("/about-us")
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}>
                      About Us
                    </button>
                  </li>
                  <li className="nav-item">
                    <button className="nav-link" onClick={()=> setVideoView("true")}>
                      How It Work
                    </button>
                  </li>

                  <li className="nav-item">
                    <button className={
                        window.location.pathname === "/pricing"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={() => {
                        navigate("/pricing");
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}>
                      Pricing
                    </button>
                  </li>


                  <li className="nav-item">
                    <button
                      className={
                        window.location.pathname === "/contact-us"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={() => {
                        navigate("/contact-us");
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      Contact Us
                    </button>
                  </li>


                </ul>
              </div>
              <div className="getTheApp destopViewApp">

                {profile && Object.keys(profile).length !== 0 ? (
                  <div className="loginViewPro" onClick={()=> navigate('/projects')}>
                    <div className="profileBxIcon">
                      <img
                        src={profile.logo ? profile.logo : IMAGES.UploadLogo}                      
                        alt=""
                      />
                    </div>
                    <h2>{profile.name}</h2>
                  </div>
                ) : (
                  <button
                    className="btnBlacksed"
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Login
                  </button>
                )}
              </div>
            </nav>
          </div>
        </div>
      </header>

      {videoView && (
          <ModalLayout
              show={videoView}
              setShow={setVideoView}
              centered
              title={"How It Work"}
              className={"customLargeWidth"}
          >
              <video className="w-100" controls autoPlay>
                  <source
                    src={IMAGES.VideoURL}
                      type="video/mp4"
                      allowFullScreen
                  />
              </video>
          </ModalLayout>
      )}
    </>
  );
};





