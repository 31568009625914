import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import { Error, Label } from "../components";
import {ButtonLoader} from "../components/Loader";
import { doAuthLogin, doResetCode } from "../store/actions/authActions";
import { SVGIMAGES } from "../assets/svgImages";
import { passwordValidation, trimValue } from "../utils/helper";


export const LoginView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
  
   
    const Formik = useFormik({
        initialValues: { email: '', password: '' },
        validationSchema: Yup.object({
            email: Yup.string().trim().email("Please enter an valid email address!").required("Email is required!"),
            //  password: Yup.string().trim().required("Password is required!")
            password: Yup.string()
                .trim()
                .required("Password is required!")
                .min(8, "Password must be at least 8 characters long")
                .matches(passwordValidation,"Password must contain at least one uppercase letter, one lowercase letter, one number and one special character"
                ),
        }),
        onSubmit: async (values) => {
            if (Formik.isValid && Formik.dirty) {
                try {
                    const { email, password } = values;
                    setIsLoading(true);
                    const res = await dispatch(doAuthLogin({ ...values, email: email.toLowerCase() }));
                       if (res?.status === 200 && res?.data?.success) {
                        localStorage.setItem('accessToken', res?.data?.accessToken);
                        localStorage.setItem('refreshToken', res?.data?.refreshToken);
                        localStorage.setItem('userId', res?.data?.user?._id);
                        if (rememberMe) {
                            localStorage.setItem('rememberMe', rememberMe);
                            localStorage.setItem('email', email);
                            localStorage.setItem('password', password);
                        } else {
                            localStorage.removeItem("rememberMe");
                            localStorage.removeItem("email");
                            localStorage.removeItem("password");
                        }
                            toastr.success("Success", res?.data?.message);
                              navigate("/projects");
                    } else {
                        if (res?.data?.data?.isVerifyOtp === false) {
                            localStorage.setItem("user_email", email);
                            const resetValues = { email: email.toLowerCase() };
                            const resetRes = await dispatch(doResetCode(resetValues));
                            if (resetRes?.status === 200 && resetRes?.data?.success) {
                                navigate("/signup-otp");
                            } else {
                                toastr.error("Error", resetRes?.data?.message);
                            }
                        } else {
                            toastr.error("Error", res?.data?.message);
                        }
                    }
                } catch (error) {
                    toastr.error("Error", error.message);
                } finally {
                    setIsLoading(false);
                }
            }
        }
    })
    useEffect(() => {
        const remember_me = localStorage.getItem("rememberMe");
         const rememberedEmail = localStorage.getItem('email') ;
        const rememberedPassword = localStorage.getItem('password')
        if (remember_me) {
             Formik?.setValues({ email: rememberedEmail, password: rememberedPassword });
            setRememberMe(true);
        }
        // eslint-disable-next-line
    }, []);
    
    return (
        <div className="loginBg">
            <div className="container-fluid">
                <div className="align-self-center pad-0 form-section">
                    <div className="mainLoginbox">
                        <form onSubmit={Formik.handleSubmit}>
                            <div className="loogSection">
                                <img src={SVGIMAGES.Logo} alt="" />
                            </div>
                            <h1 className="tilteHead">Login</h1>
                            <p className="paregrapSub">Hey, Enter your details to get sign in to your account</p>
                            <div className="form-group">
                            <Label label={"Email"} htmlFor={"Email"} required />
                                <div className="possionIconInput">
                                    <input className="form-control" type="text" placeholder="Enter Email" name="email" value={Formik.values.email} onChange={Formik.handleChange} 
                                    onBlur={(e) => {
                                        Formik.handleBlur(e);
                                        Formik.setFieldValue("email", trimValue(e.target.value));
                                      }}
                                      maxLength={100}
                                    />
                                    <img src={SVGIMAGES.EmailIcon} alt="" />
                                </div>
                                {Formik.touched.email && Formik.errors.email && (
                                    <Error error={Formik.errors.email} />
                                )}
                            </div>
                            <div className="form-group">
                            <Label label={"Password"} htmlFor={"Password"} required />
                                <div className="possionIconInput">
                                    <img src={SVGIMAGES.KeyIcon} alt="" />
                                    <input type={showPassword ? "text" : "password"} placeholder="Enter Password" className="form-control" name="password" value={Formik.values.password} onChange={Formik.handleChange}  onBlur={(e) => {
                                        Formik.handleBlur(e);
                                        Formik.setFieldValue("password", trimValue(e.target.value));
                                      }}
                                      maxLength={30} />
                                    <img src={showPassword ? SVGIMAGES.EyeIcon : SVGIMAGES.EyeOffIcon} alt="" onClick={(e) => setShowPassword(!showPassword)} className="imgRightEye" />
                                </div>
                                {Formik.touched.password && Formik.errors.password && (
                                    <Error error={Formik.errors.password} />
                                )}
                            </div>
                            <div className="form-group">
                                <h5>
                                    <span>
                                        <input type="checkbox" id="rememberCheck" name="rememberMe"   checked={rememberMe}
                                            onChange={() => setRememberMe(!rememberMe)} />
                                        <label htmlFor="rememberCheck">Remember Me</label>
                                    </span>
                                    <button type="button" onClick={() => navigate("/forgot-password", { state: { email: Formik.values.email } })}>Forgot Password ?</button>
                                </h5>
                            </div>
                            <div className="form-group align-self-center d-flex">
                                <button type="submit" className="formButton" disabled={isLoading || Formik.errors.email || Formik.errors.password} >
                                    {isLoading ? (<ButtonLoader />) : "Sign In"}
                                </button>
                            </div>
                            <div className="form-group">
                                <h6>Don’t have an account?<button type="button" onClick={() => navigate("/signup")}>Create a new account</button></h6>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}