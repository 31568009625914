import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { Error, Label } from "../components";
import { ButtonLoader } from "../components/Loader";
import { doForgotResetPassword } from "../store/actions/authActions";
import { SVGIMAGES } from "../assets/svgImages";
import { passwordValidation, trimValue } from "../utils/helper";

export const ResetPasswordView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .trim()
        .required("Password is required!")
        .min(8, "Password must be at least 8 characters long")
        .matches(
          passwordValidation,
          "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character"
        ),
      confirm_password: Yup.string()
        .trim()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required!"),
    }),
    onSubmit: async (values) => {
       if (formik.isValid && formik.dirty) {
        const { email, password } = values;
        try {
          const values = { email: email.toLowerCase(), password: password };
          setIsLoading(true);
          await dispatch(doForgotResetPassword(values)).then((res) => {
            console.log("res", res);
            if (res?.status === 200 && res?.data?.success) {
              console.log("res", res);
              toastr.success("Success", res?.data?.message);
              navigate("/successfully");
            } else {
              toastr.error("Error", res?.data?.message);
            }
          });
        } catch (error) {
          setIsLoading(false);
          toastr.error("Error", error);
        } finally {
          setIsLoading(false);
        }
      }
    },
  });
  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      formik.setFieldValue("email", storedEmail);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div className="loginBg">
      <div className="container-fluid">
        <div className="align-self-center pad-0 form-section">
          <div className="mainLoginbox">
            <form onSubmit={formik.handleSubmit}>
              <div className="loogSection">
                <img src={SVGIMAGES.Logo} alt="" />
              </div>
              <h1 className="tilteHead">Reset Password</h1>
              <p className="paregrapSub">Enter your password below</p>
              <div className="form-group">
                <Label label={"Password"} htmlFor={"password"} required />
                <div className="possionIconInput">
                  <img src={SVGIMAGES.KeyIcon} alt="" />
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter Password"
                    className="form-control"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      formik.setFieldValue("password", trimValue(e.target.value));
                    }}
                    maxLength={30}
                    name="password"

                  />
                  <img
                    src={
                      showPassword ? SVGIMAGES.EyeIcon : SVGIMAGES.EyeOffIcon
                    }
                    alt=""
                    onClick={(e) => setShowPassword(!showPassword)}
                    className="imgRightEye"
                  />
                </div>
                {formik.touched.password && formik.errors.password && (
                  <Error error={formik.errors.password} />
                )}
              </div>
              <div className="form-group">
                <Label
                  label={"Confirm Password"}
                  htmlFor={"confirm_password"}
                  required
                />
                <div className="possionIconInput">
                  <img src={SVGIMAGES.KeyIcon} alt="" />
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Enter Confirm Password"
                    className="form-control"
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                     name="confirm_password"
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      formik.setFieldValue("confirm_password", trimValue(e.target.value));
                    }}
                    maxLength={30}
                  />
                  <img
                    src={
                      showConfirmPassword
                        ? SVGIMAGES.EyeIcon
                        : SVGIMAGES.EyeOffIcon
                    }
                    alt=""
                    onClick={(e) =>
                      setShowConfirmPassword(!showConfirmPassword)
                    }
                    className="imgRightEye"
                  />
                </div>
                {formik.touched.confirm_password &&
                  formik.errors.confirm_password && (
                    <Error error={formik.errors.confirm_password} />
                  )}
              </div>
              <div className="form-group align-self-center d-flex">
                <button type="submit" className="formButton">
                  {isLoading ? <ButtonLoader /> : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
