import { IMAGES } from "../assets/images";

export const UnderMaintenanceView = () => {
  return (
    <div className="loginBg">
      <div className="container-fluid">
        <div className="align-self-center pad-0 form-section">
          <div className="mainLoginbox text-center pageNotFoundBox">
            <img src={IMAGES.UnderMaintenanceImg} alt="" />
            <h5>WE ARE COMMING SOON</h5>
            <h3>The website under maintenance!</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
