import { useEffect } from "react";
import { useLocation } from "react-router";
import * as Yup from "yup";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  return null;
};



export const dropDownLimits = [10, 25, 50, 100];

export const formatRemainingTime = (milliseconds) => {
  if (milliseconds <= 0) {
    return "Expired";
  }

  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  // if (hours > 0) {
  //   return `${hours < 10 ? "0" : ""}${hours}:${
  //     minutes < 10 ? "0" : ""
  //   }${minutes}:${seconds < 10 ? "0" : ""}${seconds} hrs`;
  // } else if (minutes > 0) {
  //   return `${minutes < 10 ? "0" : ""}${minutes}:${
  //     seconds < 10 ? "0" : ""
  //   }${seconds} min`;
  // } else {
  //   return `${seconds < 10 ? "0" : ""}${seconds} seconds`;
  // }
  let result = "";

  if (hours > 0) {
    result += `${hours}h `;
  }
  if (minutes > 0 || hours > 0) {
    result += `${minutes}m `;
  }
  result += `${seconds}s left`;

  return result.trim();
};

// export const formatRemainingDays = (endDateOfSub) => {
//   // Convert to a number if it's a string
//   const endDateTimestamp =
//     typeof endDateOfSub === "string"
//       ? parseInt(endDateOfSub, 10)
//       : endDateOfSub;
//   // Create a new Date object
//   const endDate = new Date(endDateTimestamp);
//   console.log('endDate',endDate)
//   // Check if endDate is valid
//   if (isNaN(endDate.getTime())) {
//     return "Invalid end date";
//   }
//   const today = new Date();
//   console.log('today',today)
//   today.setHours(0, 0, 0, 0); // Normalize to start of the day
//   endDate.setHours(0, 0, 0, 0); // Normalize to start of the day
//   const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in one day
//   const daysRemaining = Math.ceil((endDate - today) / oneDay);
//   console.log('daysRemaining',daysRemaining)
//   return daysRemaining;
// };
// Function to calculate a future date in milliseconds


// Format Remaining Days Function
export const formatRemainingDays = (endDateOfSub) => {
  

  // Convert to a number if it's a string
  const endDateTimestamp = typeof endDateOfSub === 'string' ? parseInt(endDateOfSub, 10) : endDateOfSub;
  
   // Create a new Date object
  const endDate = new Date(endDateTimestamp);
 
  // Check if endDate is valid
  if (isNaN(endDate.getTime())) {
    return 'Invalid end date';
  }

  // Get current date in UTC
  const today = new Date();
  const todayUTC = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));
 
   // Normalize endDate to UTC
  const endDateUTC = new Date(Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate()));
 
  const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in one day
  const daysRemaining = Math.ceil((endDateUTC - todayUTC) / oneDay);
   return daysRemaining;
}



export const getFormatedDate = (date) => {
  const d = new Date(date).toLocaleDateString(undefined, {
    year: "numeric",
    month: "long",
    day: "2-digit",
  });
  return d;
};

export const getFormatedJourneyDate = (date) => {
  const d = new Date(date).toLocaleString(undefined, {
    month: "2-digit",
    year: "numeric",
    day: "2-digit",
  });
  return d;
};

export const handlePhoneNumberChange = (event, Formik, key) => {
  // Remove any non-numeric characters
  const sanitizedValue = event.target.value.replace(/[^0-9]/g, "");
  Formik.setFieldValue(key, sanitizedValue);
  return sanitizedValue;
};

export const filterByDomainTypes = (data, id) => {
  const selectedData = data?.find((domain) => domain?._id === id);
  // return { ...selectedData, other: true }
  return selectedData;
};

export const filterByTechnologyTypes = (data, id) => {
  const selectedData = data.find((reservation) => reservation._id === id);
  // return { ...selectedData, other: true }
  return selectedData;
};
export const filterByPlatformTypes = (data, area) => {
  return data.filter((reservation) => reservation.area === area);
};
const videoValidation = Yup.mixed().test(
  "videoLink-or-projectVideo",
  "Either video link or project video is required!",
  function (value) {
    const { videoLink, projectVideo } = this.parent;
    return !!videoLink || !!projectVideo;
  }
);
// export const videoRegex = /.*\.(?:mp4|mov)$/i;

// Regex to match .mp4 or .mov video links or file names (case insensitive)
export const videoRegex = /^(https?:\/\/[^\s/$.?#].[^\s]*)\.(mp4|mov)$/i;

export const videoFormatValidation = Yup.mixed().test(
  "videoLink-or-projectVideo",
  "Either a valid .mp4 or .mov video link or project video is required!",
  function (value) {
    const { videoLink, projectVideo } = this.parent;
    console.log('videoLink====',videoLink)
    console.log('projectVideo====',projectVideo)
    // Ensure the videoLink is a .mp4 or .mov URL if provided
    return !!projectVideo || (!!videoLink && videoRegex.test(videoLink));
  }
);




export const urlRegex =
  /^(http:\/\/www\.|https:\/\/www\.|www\.|http:\/\/|https:\/\/)[a-zA-Z0-9-\.]+\.[a-zA-Z]{2,}(\/\S*)?$/;

export const passwordValidation =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const addProjectValidationSchema = Yup.object({
  name: Yup.string().required("Name is required!"),
  projectLogo: Yup.mixed()
    .test("fileType", "Incorrect file type", (file) => {
      if (!file?.type) {
        return true;
      }
      return ["image/png", "image/jpg", "image/jpeg"].includes(file.type);
    })
    .required("Logo is required!"),
  description: Yup.string().required("Description is required!"),
  projectImages: Yup.array()
    .of(Yup.string())
    .min(1, "At least one image is required!"),

  domainId: Yup.array().of(Yup.string()),
  website: Yup.string().required("Website is required!"),
  platformId: Yup.array().of(Yup.string()),
  technologyId: Yup.array().of(Yup.string()),
  duration: Yup.string().required("Duration is required!"),
  teamSize: Yup.string().required("Team size is required!"),
  iosAppStoreLink: Yup.string().required("Apple store link is required!"),
  androidPlayStoreLink: Yup.string().required("Play store link is required!"),
  clientName: Yup.string().required("Client name is required!"),
  clientCountry: Yup.string().required("Client country is required!"),
  videoLink: videoValidation,
  projectVideo: videoValidation,
});

export const handleRemoveImage = (setImages, index, Formik) => {
  setImages((prevImageData) => {
    const newImageData = [...prevImageData];
    newImageData.splice(index, 1);
    Formik && Formik?.setFieldValue("projectImages", newImageData);
    return newImageData;
  });
};

export function mbToBytes(mb) {
  const bytesPerMb = 1024 * 1024; // 1 MB = 1024 KB, 1 KB = 1024 Bytes
  return mb * bytesPerMb;
}

export function bytesToMb(bytes) {
  const bytesPerMb = 1024 * 1024; // 1 MB = 1024 KB, 1 KB = 1024 Bytes
  return bytes / bytesPerMb;
}

export const capitalizeFirstLetter = (string) => {
  if (typeof string !== "string" || string.length === 0) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const capitalizeFirstLetterEachWord = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

// Function to load external script
export function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export const getFormattedTime = (inputDate) => {
  const date = new Date(parseInt(inputDate, 10));
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  const formattedHours = (hours % 12 === 0 ? 12 : hours % 12)
    .toString()
    .padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  const time = `${formattedHours}:${formattedMinutes} ${ampm}`;
  return time;
};

export const convertTimestampToDate = (timestampString) => {
  const timestamp = parseInt(timestampString, 10);
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const ampm = hours >= 12 ? "PM" : "AM";

  const formattedHours = (hours % 12 === 0 ? 12 : hours % 12)
    .toString()
    .padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");

  const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;

  return formattedDate;
};

export const getFiveMinutesFromNow = () => {
  const now = new Date();
  const fiveMinutesFromNow = new Date(now.getTime() + 2 * 60000); // 5 minutes * 60,000 milliseconds per minute
  return fiveMinutesFromNow.getTime();
};

export const timestamp = new Date().getTime() * 1000;

export const FolderTypes = {
  logo: "logo/",
  projectImages: "project_images/",
  videos: "videos/",
};

export const extractKeyNameFromUrl = (url) => {
  const urlObj = new URL(url);
  return decodeURIComponent(urlObj.pathname.substring(1)); // Remove leading '/' and decode the URL
};

export const trimValue = (value) => value.trim();
