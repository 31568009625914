import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toastr } from "react-redux-toastr";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Tab, Tabs } from "react-bootstrap";
import {
  HeaderView,
  ModalLayout,
  Label,
  Error,
  SidebarView,
  ButtonLoader,
  DataLoader,
  ItemsPerPageDropdownTab,
} from "../components";

import {
  createPlatfrom,
  deletePlatform,
  getPlatfromList,
} from "../store/actions/platformActions";
import { RECORD_TYPES } from "../constants/Enums";
import Pagination from "../components/Pagination/Pagination";
import { SVGIMAGES } from "../assets/svgImages";
import { capitalizeFirstLetter, trimValue } from "../utils/helper";

export const PlatformView = () => {
  const dispatch = useDispatch();
  const collapseSidebar = useSelector(({ auth }) => auth?.collapseSidebar);
  const platformList = useSelector(({ platform }) => platform?.platformList);
  const totalItems = useSelector(({ platform }) => platform?.totalItems);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [platformId, setPlatformId] = useState("");
  const [selectedPlatformData, setSelectedPlatformData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line
  const [pageLimit, setPageLimit] = useState(10);
  const [getListBy, setGetListBy] = useState("organization");

  useEffect(() => {
    const params = { pageLimit, currentPage, search, getListBy };
    if (search === "" || search?.length >= 2) {
      setIsLoading(true);
      dispatch(getPlatfromList(params)).finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line
  }, [currentPage, search, getListBy, pageLimit, dispatch]);
  const AddPlatformFormik = useFormik({
    initialValues: {
      title: "",
      remark: "",
      recordType: RECORD_TYPES.PLATFORM,
    },
    validationSchema: Yup.object({
      title: Yup.string()
      .trim()
      .min(2, "Platform must be at least 2 characters long")
      .required("Platform name is required!"),
      remark: Yup.string()
      .trim()
      .min(10, "Remark must be at least 10 characters long")
      .required("Remark is required!"),
    }),
    onSubmit: async (values) => {
      if (AddPlatformFormik.isValid && AddPlatformFormik.dirty) {
        try {
          setIsLoading(true);
          await dispatch(createPlatfrom(values)).then((res) => {
            if (res?.status === 200 && res?.data?.success) {
              AddPlatformFormik.resetForm();
              const params = { pageLimit, currentPage, search, getListBy };
              dispatch(getPlatfromList(params));
              toastr.success("Success", res?.data?.message);
              setAddModal(false);
            } else {
              toastr.error("Error", res?.data?.message);
            }
          });
        } catch (error) {
          toastr.error("Error", error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }
    },
  });

  const EditPlatformFormik = useFormik({
    initialValues: {
      id: "",
      title: "",
      remark: "",
      recordType: RECORD_TYPES.PLATFORM,
    },
    validationSchema: Yup.object({
      title: Yup.string()
      .trim()
      .min(2, "Platform must be at least 2 characters long")
      .required("Platform name is required!"),
      remark: Yup.string()
      .trim()
      .min(10, "Remark must be at least 10 characters long")
      .required("Remark is required!"),
    }),
    onSubmit: async (values) => {
      if (EditPlatformFormik.isValid && EditPlatformFormik.dirty) {
        try {
          setIsLoading(true);
          await dispatch(createPlatfrom(values)).then((res) => {
            if (res?.status === 200 && res?.data?.success) {
              EditPlatformFormik.resetForm();
              const params = { pageLimit, currentPage, search, getListBy };
              dispatch(getPlatfromList(params));
              toastr.success("Success", res?.data?.message);
              setEditModal(false);
            } else {
              toastr.error("Error", res?.data?.message);
            }
          });
        } catch (error) {
          toastr.error("Error", error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }
    },
  });

  const openEditModal = (platform) => {
    setSelectedPlatformData(platform);
    setEditModal(true);
  };
  useEffect(() => {
    if (selectedPlatformData && editModal) {
      EditPlatformFormik.setValues({
        id: selectedPlatformData._id,
        title: selectedPlatformData.title,
        remark: selectedPlatformData.remark,
        recordType: RECORD_TYPES.PLATFORM,
      });
    }
    // eslint-disable-next-line
  }, [selectedPlatformData, editModal]);

  const platformDelete = async () => {
    setDeleteModal(false);
    try {
      await dispatch(deletePlatform(platformId)).then((res) => {
        if (res?.status === 200 && res?.data?.success) {
          const params = {
            pageLimit,
            currentPage:
              platformList?.length === 1 && currentPage > 1
                ? currentPage - 1
                : currentPage,
            search,
            getListBy,
          };
          dispatch(getPlatfromList(params));
          toastr.success("Success", res?.data?.message);
        } else {
          toastr.error("Error", res?.data?.message);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div className={collapseSidebar ? "mainBody sortingMain" : "mainBody"}>
        <HeaderView />

        <div className="wrapper">
          <SidebarView />
          <div className="main-container">
            <div className="content">
              <div className="row mb-3">
                <div className="col-xxl-12 custTabsCss">
                  <div className="row justify-content-between align-items-center mb-3">
                    <div className="col-xl-3 col-lg-4">
                      <div className="greetingsText">
                        <div className="greetingsText-heading">
                          <h3>Platform
                            <small>
                              <span
                                class="tooltip-toggleuser pro"
                                aria-label="The Platforms section allows you to view your list of platforms, including the default platform created by Folio4u. You can add new platforms by clicking the plus button. These platforms can be used when adding projects, and platforms added by you will be visible only to you."
                                tabindex="0"
                              >
                                <img src={SVGIMAGES.infoIcon} alt="" />
                              </span>
                            </small>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-5 d-flex">
                      {getListBy === 'organization' &&
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="button-tooltip-2">Add Platform</Tooltip>
                          }
                        >
                          <div className="buttons d-flex" variant="light">
                            <button
                              className="ctr-btn"
                              onClick={() => setAddModal(true)}
                            >
                              <img src={SVGIMAGES.PlusIcon} alt="" />
                            </button>
                          </div>
                        </OverlayTrigger>}
                      <input
                        type="text"
                        className="custom-input-field"
                        placeholder="Search Platform"
                        value={search}
                        onChange={(e) => {
                          setCurrentPage(1);
                          const s = e.currentTarget?.value
                            ?.replace(/[^\w\s]|_/g, "")
                            .replace(/\s{2,}/g, " ");
                          setSearch(s);
                        }}
                      />
                    </div>
                  </div>
                  {platformList?.length > 0 &&
                  <div className="col-xl-12">
                    <ItemsPerPageDropdownTab
                      pageLimit={pageLimit}
                      setPageLimit={setPageLimit}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>}
                  <Tabs
                    defaultActiveKey="created_by_me"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    onSelect={(key) => {
                      if (key === "created_by_me") {
                        setCurrentPage(1)
                        setGetListBy("organization");
                      } else if (key === "default") {
                        setCurrentPage(1)
                        setGetListBy("admin");
                      }
                    }}
                  >
                    <Tab eventKey="created_by_me" title="Created by me">
                      {isLoading ? (
                        <DataLoader width={100} />
                      ) : (
                        <div className="row">
                          <div className="col-xxl-12">
                            {platformList?.length > 0 ? (
                              <>
                                <div className="table-responsive">
                                  <table className="custom-table">
                                    <thead>
                                      <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Remark</th>
                                        <th scope="col">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {platformList?.length > 0 ? (
                                        platformList?.map((platform) => (
                                          <tr key={platform?._id}>
                                            <td
                                              onClick={() =>
                                                platform?.createdBy ===
                                                "organization" &&
                                                openEditModal(platform)
                                              }
                                              className="w-descriptin"
                                            >
                                              {platform?.title}
                                            </td>
                                            <td
                                              onClick={() =>
                                                platform?.createdBy ===
                                                "organization" &&
                                                openEditModal(platform)
                                              }
                                              className="w-descriptin"
                                            >
                                              {platform?.remark}
                                            </td>

                                            <td>
                                              {platform?.createdBy ===
                                                "organization" && (
                                                  <div className="action-btn">
                                                    <button
                                                      onClick={() => {
                                                        setPlatformId(
                                                          platform?._id
                                                        );
                                                        setDeleteModal(true);
                                                      }}
                                                    >
                                                      <img
                                                        src={SVGIMAGES.DeleteIcon}
                                                        alt=""
                                                      />
                                                    </button>
                                                  </div>
                                                )}
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <td
                                            className="text-center"
                                            colSpan={5}
                                          >
                                            No platform records found
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <div className="cusPagination">
                                  <Pagination
                                    currentPage={currentPage}
                                    totalCount={totalItems}
                                    pageSize={pageLimit}
                                    onPageChange={(page) =>
                                      setCurrentPage(page)
                                    }
                                  />
                                </div>
                              </>
                            ) : (
                              <div className="nodataFileBox">
                                <img src={SVGIMAGES.NoDataFoundImg} alt="" className="img-fluid" />
                                <h1>No Data Found</h1>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Tab>
                    <Tab eventKey="default" title="Default">
                      {isLoading ? (
                        <DataLoader width={100} />
                      ) : (
                        <div className="row">
                          <div className="col-xxl-12">
                            {platformList?.length > 0 ? (
                              <>
                                <div className="table-responsive">
                                  <table className="custom-table">
                                    <thead>
                                      <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Remark</th>

                                      </tr>
                                    </thead>
                                    <tbody>
                                      {platformList?.length > 0 ? (
                                        platformList?.map((platform) => (
                                          <tr key={platform?._id}>
                                            <td
                                              onClick={() =>
                                                platform?.createdBy ===
                                                "organization" &&
                                                openEditModal(platform)
                                              }
                                            >
                                              {platform?.title}
                                            </td>
                                            <td
                                              onClick={() =>
                                                platform?.createdBy ===
                                                "organization" &&
                                                openEditModal(platform)
                                              }
                                              className="w-descriptin"
                                            >
                                              {platform?.remark}
                                            </td>


                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <td
                                            className="text-center"
                                            colSpan={5}
                                          >
                                            No platform records found
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <div className="cusPagination">
                                  <Pagination
                                    currentPage={currentPage}
                                    totalCount={totalItems}
                                    pageSize={pageLimit}
                                    onPageChange={(page) =>
                                      setCurrentPage(page)
                                    }
                                  />
                                </div>
                              </>
                            ) : (
                              <div className="nodataFileBox">
                                <img src={SVGIMAGES.NoDataFoundImg} alt="" />
                                <h1>No Data Found</h1>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {deleteModal && (
        <ModalLayout show={deleteModal} setShow={setDeleteModal} centered>
          <img
            src={SVGIMAGES.DeleteModaleIcon}
            alt=""
            className="mainIconModal"
          />
          <h2>Delete Platform</h2>
          <p>Are you sure you want to Delete Platform?</p>
          <div className="footbutton">
            <button
              type="button"
              onClick={() => {
                setDeleteModal(false);
              }}
              className="cancelBtn"
            >
              Cancel
            </button>
            <button
              type="button"
              className="confirmBtn"
              onClick={() => {
                platformDelete();
              }}
            >
              Confirm
            </button>
          </div>
        </ModalLayout>
      )}

      {editModal && (
        <ModalLayout
          show={editModal}
          setShow={setEditModal}
          centered
          title={"Edit Platform"}
        >
          <form
            className="row g-3"
            id="editPlatformForm"
            onSubmit={EditPlatformFormik.handleSubmit}
          >
            <div className="col-lg-12">
              <Label label={"Platform"} htmlFor={"platformName"} required />
              <input
                type="text"
                className="custom-input-field"
                id="platformName"
                placeholder="Enter Platform"
                name="title"
                value={EditPlatformFormik.values.title}
                onChange={(e) => {
                  const capitalizedValue = capitalizeFirstLetter(e.target.value);
                  EditPlatformFormik.setFieldValue('title', capitalizedValue);
                }}
                onBlur={(e) => {
                  EditPlatformFormik.handleBlur(e);
                  EditPlatformFormik.setFieldValue(
                    "title",
                    capitalizeFirstLetter(trimValue(e.target.value))
                  );
                }}
                maxLength={60}
              />

              {EditPlatformFormik.errors.title &&
                EditPlatformFormik.touched.title && (
                  <Error error={EditPlatformFormik.errors.title} />
                )}
            </div>
            <div className="col-lg-12">
              <Label label={"Remark"} htmlFor={"remark"} required />
              <textarea
                type="text"
                className="custom-input-field"
                id="remark"
                name="remark"
                placeholder="Enter Remark"
                rows="5"
                value={EditPlatformFormik.values.remark}
                onChange={(e) => {
                  const capitalizedValue = capitalizeFirstLetter(e.target.value);
                  EditPlatformFormik.setFieldValue('remark', capitalizedValue);
                }}
                onBlur={(e) => {
                  EditPlatformFormik.handleBlur(e);
                  EditPlatformFormik.setFieldValue(
                    "remark",
                    capitalizeFirstLetter(trimValue(e.target.value))
                  );
                }}
                maxLength={60}
              ></textarea>
              {EditPlatformFormik.errors.remark &&
                EditPlatformFormik.touched.remark && (
                  <Error error={EditPlatformFormik.errors.remark} />
                )}
            </div>
          </form>
          <div className="footbutton">
            <button
              type="button"
              onClick={() => {
                setEditModal(false);
              }}
              className="cancelBtn"
            >
              Cancel
            </button>
            <button type="submit" form="editPlatformForm">
              {isLoading ? <ButtonLoader /> : "Update"}
            </button>
          </div>
        </ModalLayout>
      )}

      {addModal && (
        <ModalLayout
          show={addModal}
          setShow={setAddModal}
          centered
          title={"Add Platform"}
          formik={AddPlatformFormik}
        >
          <form
            className="row g-3"
            method="POST"
            id="addPlatformForm"
            onSubmit={AddPlatformFormik.handleSubmit}
          >
            <div className="col-lg-12">
              <Label label={"Platform"} htmlFor={"platformName"} required />
              <input
                type="text"
                className="custom-input-field"
                id="platformName"
                placeholder="Enter Platform"
                name="title"
                value={AddPlatformFormik.values.title}
                onChange={(e) => {
                  const capitalizedValue = capitalizeFirstLetter(e.target.value);
                  AddPlatformFormik.setFieldValue('title', capitalizedValue);
                }}
                onBlur={(e) => {
                  AddPlatformFormik.handleBlur(e);
                  AddPlatformFormik.setFieldValue(
                    "title",
                    capitalizeFirstLetter(trimValue(e.target.value))
                  );
                }}
                maxLength={60}
              />

              {AddPlatformFormik.errors.title &&
                AddPlatformFormik.touched.title && (
                  <Error error={AddPlatformFormik.errors.title} />
                )}
            </div>
            <div className="col-lg-12">
              <Label label={"Remark"} htmlFor={"remark"} required />
              <textarea
                type="text"
                className="custom-input-field"
                id="remark"
                name="remark"
                placeholder="Enter Remark"
                rows="5"
                value={AddPlatformFormik.values.remark}
                onChange={(e) => {
                  const capitalizedValue = capitalizeFirstLetter(e.target.value);
                  AddPlatformFormik.setFieldValue('remark', capitalizedValue);
                }}
                onBlur={(e) => {
                  AddPlatformFormik.handleBlur(e);
                  AddPlatformFormik.setFieldValue(
                    "remark",
                    capitalizeFirstLetter(trimValue(e.target.value))
                  );
                }}
                maxLength={60}
              ></textarea>
              {AddPlatformFormik.errors.remark &&
                AddPlatformFormik.touched.remark && (
                  <Error error={AddPlatformFormik.errors.remark} />
                )}
            </div>
          </form>
          <div className="footbutton">
            <button
              type="button"
              onClick={() => {
                AddPlatformFormik.resetForm();
                setAddModal(false);
              }}
              className="cancelBtn"
            >
              Cancel
            </button>
            <button type="submit" form="addPlatformForm">
              {" "}
              {isLoading ? <ButtonLoader /> : "Add"}
            </button>
          </div>
        </ModalLayout>
      )}
    </>
  );
};
