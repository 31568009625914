import { IMAGES } from "../../assets/images";
import { ModalLayout } from "../ModalLayout";

export const NotificationModal = ({
  showModal,
  setShowModal,
  title,
  message,
  type,
  ...props
}) => {
  // console.log('pay', props.paymentResponse);
  const titleClass = type === "success" ? "modal-success" : "modal-error";

  return (
    <ModalLayout
      show={showModal}
      setShow={setShowModal}
      centered
      title={title}
      className={"notification"}
      titleClass={titleClass}
      onClose={props?.onClose}
    >
      <img
        src={type === "success" ? IMAGES.SuccessIcon : IMAGES.ErrorIcon}
        alt=""
      />

      {props?.subheading && <p>{props?.subheading}</p>}
      <div className="row text-center">{message}</div>
      {props?.paymentResponse &&
        props?.paymentResponse?.razorpay_payment_id && (
          <p>
            Your Reference ID : {props?.paymentResponse?.razorpay_payment_id}
          </p>
        )}
      {/* {props?.paymentResponse && <p>{props?.paymentResponse?.message}</p>} */}
    </ModalLayout>
  );
};
