import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import { FolderTypes, capitalizeFirstLetterEachWord, mbToBytes, timestamp, trimValue } from "../utils/helper";
import {
  checkOrgCode,
  getCategoryTypes,
  getProfile,
  profileUpdate,
} from "../store/actions/authActions";
// eslint-disable-next-line
import {
  HeaderView,
  SidebarView,
  Error,
  Label,
  ButtonLoader,
  ModalLayout,
} from "../components";
import { SVGIMAGES } from "../assets/svgImages";
import { fileUpload, profileUpload } from "../utils/S3-helper";
import loadImage from "blueimp-load-image";
import ImageCrop from "../components/ImageCrop";
const isWindowsOrLinux = /Windows|Linux/.test(navigator.userAgent);

export const ProfileView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const collapseSidebar = useSelector(({ auth }) => auth?.collapseSidebar);
  const profile = useSelector(({ auth }) => auth?.loginUser);
  // eslint-disable-next-line
  const categoryList = useSelector(({ auth }) => auth?.categoryList);
  // eslint-disable-next-line
  const [selectedCategoryType, setSelectedCategoryType] = useState({});
  // eslint-disable-next-line
  const [newCategoryTypes, setNewCategoryTypes] = useState([]);
  const [src, setSrc] = useState();
  const [showCropModal,setShowCropModal]=useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const fileSizeLimit = mbToBytes(20); //5 mb

  const REQUIRED_MIN_WIDTH = 256;
  const REQUIRED_MIN_HEIGHT = 256;
  useEffect(() => {
    dispatch(getCategoryTypes());
    dispatch(getProfile());
  }, [dispatch]);

  useEffect(() => {
    setSelectedCategoryType([profile?.categoryId]);
  }, [profile]);

  const ProfileFormik = useFormik({
    initialValues: {
      _id: profile?._id,
      name: profile?.name,
      email: profile?.email,
      password: profile?.password,
      confirm_password: profile?.password,
      logo: profile?.logo,
      access_code: profile?.accessCode,
      company_logo: "",
      categoryId: profile?.categoryId,
    },
    validationSchema: Yup.object({
      name: Yup.string()
      .trim()
      .min(2, "Name must be at least 2 characters long")
      .required("Name is required!"),
       company_logo: Yup.mixed().test(
        "company_logo",
        "Logo is required!",
        function (value) {
          return value || profile?.logo; // Check if there's a new logo or an existing one
        }
      ),
      access_code: Yup.string().trim()
      .required("Access code is required!")
      .min(6, "Access code must be at least 6 characters"),
    }),
    onSubmit: async (values) => {
      if (ProfileFormik.dirty && ProfileFormik.isValid) {
        setIsLoading(true);
        const {
          company_logo,
          access_code,
          _id,
          password,
          confirm_password,
          ...restValues
        } = values;

        try {
          let params = {
            ...restValues,
          };

          let orgCodeValid = true;

          if (access_code !== profile?.accessCode) {
            const orgCodeData = { accessCode: access_code };
            params = {
              ...restValues,
              accessCode: access_code,
            };
            try {
              await dispatch(checkOrgCode(orgCodeData)).then(
                (orgCodeResponse) => {
                  if (
                    orgCodeResponse?.status === 200 &&
                    orgCodeResponse?.data?.success
                  ) {
                    orgCodeValid = true;
                  } else {
                    toastr.error("Error", orgCodeResponse?.data?.message);
                    orgCodeValid = false;
                  }
                }
              );
            } catch (error) {
              toastr.error("Error", "Invalid organization code.");
              setIsLoading(false);
              return;
            }
          }

          if (!orgCodeValid) {
            setIsLoading(false);
            return;
          }

          if (orgCodeValid) {
             const randomNum = Math.floor(Math.random() * 1e6);
               const extension = '.png'
            if (company_logo) {
              const keyName = `${FolderTypes.logo + timestamp}_${
                randomNum+extension
              }`;
              const res = await profileUpload(keyName, company_logo);
              if (res) params.logo = res;
            }

            delete params.email;
            try {
              await dispatch(profileUpdate(params)).then((updateResponse) => {
                if (
                  updateResponse?.status === 200 &&
                  updateResponse?.data?.success
                ) {
                  toastr.success("Success", updateResponse?.data?.message);
                  navigate(-1);
                } else {
                  setIsLoading(false);
                  toastr.error("Error", updateResponse?.data?.message);
                }
              });
            } catch (error) {
              toastr.error("Error", "Update failed");
            } finally {
              setIsLoading(false);
            }
          }
        } catch (error) {
          toastr.error("Error", error.message);
          setIsLoading(false);
        }
      }
    },
  });

  const dropzone = useDropzone({
    accept: { "image/*": [] },
    minSize: 1,
    maxSize: fileSizeLimit, // 5 MB
    maxFiles: 1,
    noKeyboard: true,
    noClick: true,
    onDrop: async (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        // Load the image to get dimensions
        setSrc(file);
        setShowCropModal(true)
        // loadImage(
        //   file,
        //   (img, data) => {
        //     if (data) {
        //       const { originalWidth, originalHeight } = data;

        //       if (
        //         originalWidth >= REQUIRED_MIN_WIDTH &&
        //         originalHeight >= REQUIRED_MIN_HEIGHT
        //       ) {
        //         if (originalWidth === originalHeight) {
        //           // Image meets the size and aspect ratio requirements
        //           ProfileFormik.setFieldValue("company_logo", file);
        //         } else {
        //           toastr.error(
        //             "Error",
        //             `If the logo exceeds ${REQUIRED_MIN_WIDTH}x${REQUIRED_MIN_HEIGHT}, it must have equal width and height.`
        //           );
        //         }
        //       } else {
        //         toastr.error(
        //           "Error",
        //           `Logo must be at least ${REQUIRED_MIN_WIDTH}x${REQUIRED_MIN_HEIGHT} pixels.`
        //         );
        //       }
        //     }
        //   },
        //   { orientation: true, meta: true }
        // );
      } else {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              toastr.error("Error", `File is too large`);
            }
            if (err.code === "file-too-small") {
              toastr.error("Error", `No file selected`);
            }
          });
        });
      }
    },
  });


  useEffect(() => {
    setSelectedCategoryType([profile?.categoryId]);
  }, [profile]);

  const getCropImage = (file) => {
    ProfileFormik.setFieldValue("company_logo", file);
    setShowCropModal(false)
  };

  const closeShowModal = ()=>{
    setShowCropModal(false)
  }

  return (
    <>
      <div className={collapseSidebar ? "mainBody sortingMain" : "mainBody"}>
        <HeaderView />

        <div className="wrapper">
          <SidebarView />
          <div className="main-container">
            <div className="content">
              <div className="row mb-3">
                <div className="col-xxl-12">
                  <div className="form-body">
                    <div className="row">
                      <div className="col-xxl-12">
                        <div className="greetingsText mb-3">
                          <div className="greetingsText-heading">
                            <h3>Profile</h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 align-self-center mobile_d_none">
                        <img
                          src={SVGIMAGES.ProfileImgImg}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="col-lg-6  align-self-center">
                        <form
                          className="row g-3 changePassword"
                          id="editProfile"
                          onSubmit={ProfileFormik.handleSubmit}
                        >
                          <div className="col-lg-12">
                            <div className="addProjectlogo d-grid justify-content-start">
                              <div className="upload-img-box">
                                <div className="circle">
                                  <img
                                    src={
                                      ProfileFormik.values.company_logo
                                        ? typeof ProfileFormik.values
                                            .company_logo === "object"
                                          ? URL.createObjectURL(
                                              ProfileFormik.values.company_logo
                                            )
                                          : ProfileFormik.values.company_logo
                                        : ProfileFormik.values.logo ||
                                          SVGIMAGES.Logo
                                    }
                                    alt=""
                                  />
                                </div>

                                <div className="p-image ml-auto">
                                  <label htmlFor="logoSelect">
                                    <img src={SVGIMAGES.EditIcon} alt="" />
                                  </label>

                                  <input
                                    {...dropzone.getInputProps({
                                      className: "file-upload",
                                      id: "logoSelect",
                                      accept: "image/*",
                                      onClick: !isWindowsOrLinux ? () => dropzone.open() : undefined,
                                    })}
                                    name="company_logo"
                                    className="file-upload"
                                    type="file"
                                    accept="image/*"
                                    id="logoSelect"
                                    onBlur={ProfileFormik.handleBlur}
                                  />
                                </div>
                              </div>
                              <Label
                                label={"Add Logo"}
                                htmlFor={"company_logo"}
                                required
                              />
                              {ProfileFormik.errors.company_logo &&
                                ProfileFormik.touched.company_logo && (
                                  <Error
                                    error={ProfileFormik.errors.company_logo}
                                  />
                                )}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <Label label={"Name"} htmlFor={"name"} required />
                            <input
                              type="text"
                              className="custom-input-field"
                              id="name"
                              name="name"
                              placeholder="Enter Name or Company Name"
                              value={ProfileFormik.values.name}
                              onChange={(e) => {
                                const capitalizedValue = capitalizeFirstLetterEachWord(
                                  e.target.value
                                );
                                ProfileFormik.setFieldValue("name", capitalizedValue);
                              }}
                              onBlur={(e) => {
                                ProfileFormik.handleBlur(e);
                                ProfileFormik.setFieldValue(
                                  "name",
                                  capitalizeFirstLetterEachWord(trimValue(e.target.value))
                                );
                              }}
                              maxLength={60}
                            />
                            {ProfileFormik.errors.name &&
                              ProfileFormik.touched.name && (
                                <Error error={ProfileFormik.errors.name} />
                              )}
                          </div>
                          <div className="col-lg-12">
                            <Label label={"Email"} htmlFor={"email"} required />
                            <input
                              type="text"
                              className="custom-input-field"
                              id="email"
                              name="email"
                              disabled
                              placeholder="Enter Email"
                              value={ProfileFormik.values.email}
                              onChange={ProfileFormik.handleChange}
                              onBlur={ProfileFormik.handleBlur}
                            />
                            {ProfileFormik.errors.email &&
                              ProfileFormik.touched.email && (
                                <Error error={ProfileFormik.errors.email} />
                              )}
                          </div>

                          <div className="col-md-12">
                            <Label
                              label={
                                <span>
                                  Access Code
                                  <small>
                                    <span
                                      class="tooltip-toggleCus pro accesscodetooltip"
                                      aria-label="This access code allows you to access your portfolio and display your projects in the application, You can enter your own code, or we’ll generate one for you and send it via email upon signup completion."
                                      tabindex="0"
                                    >
                                      <img src={SVGIMAGES.infoIcon} alt="" className="infoiconmargin" />
                                    </span>
                                    &nbsp;(<span>Optional</span>)
                                  </small>
                                </span>
                              }
                              htmlFor={"org_code"}
                              className="custom-form-label-weight"
                            />
                            <input
                              type="text"
                              className="custom-input-field"
                              id="access_code"
                              name="access_code"
                              placeholder="Create Access Code"
                              value={ProfileFormik.values.access_code}
                              onChange={(e) => {
                               ProfileFormik.setFieldValue("access_code", e.target.value);
                              }}
                            
                              onBlur={(e) => {
                                ProfileFormik.handleBlur(e);
                                ProfileFormik.setFieldValue(
                                  "access_code",
                                  trimValue(e.target.value) 
                                );
                              }}
                              maxLength={30}
                            />
                            {ProfileFormik.errors.access_code &&
                              ProfileFormik.touched.access_code && (
                                <Error
                                  error={ProfileFormik.errors.access_code}
                                />
                              )}
                          </div>
                          {/* <div className="form-group">
                                                        <Label label={"Category"} required />
                                                        <div className="possionIconInput">
                                                            <CategoryDropdown
                                                                isDisabled
                                                                Formik={ProfileFormik}
                                                                categoryTypes={categoryList}
                                                                setNewCategoryTypes={setNewCategoryTypes}
                                                                sortedData={selectedCategoryType}
                                                                setSortedData={setSelectedCategoryType}
                                                            />
                                                        </div>
                                                        {ProfileFormik.touched.categoryId &&
                                                            ProfileFormik.values.categoryId?.length < 1 &&
                                                            newCategoryTypes?.length < 1 && (
                                                                <Error error={"Category is required!"} />
                                                            )}
                                                    </div> */}
                          <div className="col-md-6 mt-4">
                            <button
                              type="submit"
                              form="editProfile"
                              className="custom-btn"
                            >
                              {isLoading ? <ButtonLoader /> : "Update Profile"}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        showCropModal && 
         <ModalLayout
        show={showCropModal}
        setShow={setShowCropModal}
        centered
        title={"Crop Image"}
       
      >
        {src && (
          <ImageCrop
            src={src ? URL.createObjectURL(src) : ""}
            getCropImage={getCropImage}
            setShowCropModal={setShowCropModal}
            cancelModal ={closeShowModal}
           
           
          />
        )}
            </ModalLayout>
      }
  
    </>
  );
};
