import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import {
  FolderTypes,
  capitalizeFirstLetterEachWord,
  mbToBytes,
  passwordValidation,
  timestamp,
  trimValue,
} from "../utils/helper";
import {
  checkOrgCode,
  doAuthSignup,
  getCategoryTypes,
} from "../store/actions/authActions";
import { SVGIMAGES } from "../assets/svgImages";
import { IMAGES } from "../assets/images";
import { profileUpload } from "../utils/S3-helper";
// eslint-disable-next-line
import { CategoryDropdown, Error, Label, ModalLayout } from "../components";
import { ButtonLoader } from "../components/Loader";
import loadImage from "blueimp-load-image";
import ImageCrop from "../components/ImageCrop";
const isWindowsOrLinux = /Windows|Linux/.test(navigator.userAgent);
export const SignupView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const categoryList = useSelector(({ auth }) => auth?.categoryList);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [selectedCategoryType, setSelectedCategoryType] = useState({});
  // eslint-disable-next-line
  const [newCategoryTypes, setNewCategoryTypes] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [src, setSrc] = useState();
  const [showCropModal, setShowCropModal] = useState(false);
  const fileSizeLimit = mbToBytes(20); //5 mb
  const REQUIRED_MIN_WIDTH = 256;
  const REQUIRED_MIN_HEIGHT = 256;
  useEffect(() => {
    dispatch(getCategoryTypes());
  }, [dispatch]);

  useEffect(() => {
    if (categoryList && categoryList?.length > 0) {
      setSelectedCategoryType([categoryList[0]?._id]);
    }
  }, [categoryList]);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirm_password: "",
      org_code: "",
      company_logo: null,
    },
    validationSchema: Yup.object({
      company_logo: Yup.mixed()
        .test("fileType", "Incorrect file type", (file) => {
          if (!file?.type) {
            return true;
          }
          return ["image/png", "image/jpg", "image/jpeg"].includes(file.type);
        })
        .required("Logo is required!"),
      name: Yup.string()
        .trim()
        .min(2, "Name must be at least 2 characters long")
        .required("Name is required!"),
      email: Yup.string()
        .trim()
        .email("Please enter a valid email address!")
        .required("Email is required!"),
      password: Yup.string()
        .trim()
        .required("Password is required!")
        .min(8, "Password must be at least 8 characters long")
        .matches(
          passwordValidation,
          "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character"
        ),
      confirm_password: Yup.string()
        .trim()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required!"),
      org_code: Yup.string()
        .optional()
        .min(6, "Access code must be at least 6 characters"),
      // categoryId: Yup.array()
      //   .of(Yup.string())
      //   .min(1, "Category is required!")
      //   .required("Category is required!"),
    }),
    onSubmit: async (values) => {
    
      if (formik.isValid && formik.dirty) {
        setIsLoading(true);
        const { company_logo, email, org_code, ...restValues } = values;
        const lowercaseEmail = email.toLowerCase();
        const randomNum = Math.floor(Math.random() * 1e6);
        const extension = ".png";
        const keyName = `${FolderTypes.logo + timestamp}_${
          randomNum + extension
        }`;
        const rememberMe = localStorage.getItem("rememberMe");
        if (rememberMe) {
          localStorage.removeItem("rememberMe");
          localStorage.removeItem("email");
          localStorage.removeItem("password");
        }
        try {
          let orgCodeValid = true;

          if (org_code) {
            const orgCodeData = { accessCode: org_code };
            try {
              await dispatch(checkOrgCode(orgCodeData)).then(
                (orgCodeResponse) => {
                  if (
                    orgCodeResponse?.status === 200 &&
                    orgCodeResponse?.data?.success
                  ) {
                    // toastr.success("Success", orgCodeResponse?.data?.message);
                    orgCodeValid = true;
                  } else {
                    console.log("error0", orgCodeResponse);
                    toastr.error("Error", orgCodeResponse?.data?.message);
                    orgCodeValid = false;
                    setIsLoading(false);
                  }
                }
              );
            } catch (error) {
              console.log("error1", error);
              toastr.error("Error", "Invalid organization code.");
              setIsLoading(false);
              orgCodeValid = false;
              return;
            }
          }

          if (orgCodeValid) {
            const res = await profileUpload(keyName, company_logo);
            const params = {
              ...restValues,
              logo: res,
              accessCode: org_code,
              email: lowercaseEmail, // Ensure email is included in params
              categoryId: selectedCategoryType[0],
            };

            try {
              await dispatch(doAuthSignup(params)).then((signupResponse) => {
                if (
                  signupResponse?.status === 200 &&
                  signupResponse?.data?.success
                ) {
                  localStorage.setItem("user_email", email);
                  setIsLoading(false);
                  toastr.success("Success", signupResponse?.data?.message);
                  navigate("/signup-otp");
                } else {
                  console.log("error2", signupResponse);
                  toastr.error("Error", signupResponse?.data?.message);
                  setIsLoading(false);
                }
              });
            } catch (error) {
              console.log("error3", error);
              toastr.error("Error", "Signup failed");
              setIsLoading(false);
            }
          }
        } catch (error) {
          console.log("error4", error);
          toastr.error("Error", error.message);
          setIsLoading(false);
        }
      }
    },
  });

  const dropzone = useDropzone({
    accept: { "image/*": [] },
    minSize: 1,
    maxSize: fileSizeLimit, // 5 MB
    maxFiles: 1,
    noKeyboard: true,
    noClick: true,
    onDrop: async (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setSrc(file);
        setShowCropModal(true);
        // Load the image to get dimensions
        // loadImage(
        //   file,
        //   (img, data) => {
        //     if (data) {
        //       const { originalWidth, originalHeight } = data;

        //       if (
        //         originalWidth >= REQUIRED_MIN_WIDTH &&
        //         originalHeight >= REQUIRED_MIN_HEIGHT
        //       ) {
        //         if (originalWidth === originalHeight) {
        //           // Image meets the size and aspect ratio requirements
        //           formik.setFieldValue("company_logo", file);
        //         } else {
        //           toastr.error(
        //             "Error",
        //             `If the logo exceeds ${REQUIRED_MIN_WIDTH}x${REQUIRED_MIN_HEIGHT}, it must have equal width and height.`
        //           );
        //         }
        //       } else {
        //         toastr.error(
        //           "Error",
        //           `Logo must be at least ${REQUIRED_MIN_WIDTH}x${REQUIRED_MIN_HEIGHT} pixels.`
        //         );
        //       }
        //     }
        //   },
        //   { orientation: true, meta: true }
        // );
      } else {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              toastr.error("Error", `File is too large`);
            }
            if (err.code === "file-too-small") {
              toastr.error("Error", `No file selected`);
            }
          });
        });
      }
    },
  });

  const getCropImage = (file) => {
    console.log('file',file)
    formik.setFieldValue("company_logo", file);
    setShowCropModal(false);
  };

  const closeShowModal = () => {
    setShowCropModal(false);
  };

  return (
    <div className="loginBg">
      <div className="container-fluid">
        <div className="align-self-center pad-0 form-section">
          <div className="mainLoginbox">
            <button
              type="button"
              aria-label="Close"
              onClick={() => {
                navigate(-1);
              }}
            >
              <img
                src={SVGIMAGES.backIcon}
                width={26}
                height={26}
                alt="Close Button"
              />
            </button>
            <form onSubmit={formik.handleSubmit}>
              <div className="loogSection">
                <div className="upload-img-box">
                  <div className="circle">
                    <img
                      src={
                        formik?.values?.company_logo
                          ? formik?.values?.company_logo
                          : IMAGES.UploadLogo // Default image if company_logo is not set
                      }
                      alt="Company Logo"
                      className="cusImgMob"
                    />
                  </div>
                  <div className="p-image ml-auto">
                    <label htmlFor="profileImageSelect">
                      <img src={SVGIMAGES.CameraIcon} alt="Upload" />
                    </label>
                    <input
                      {...dropzone.getInputProps({
                        className: "file-upload",
                        id: "profileImageSelect",
                        accept: "image/*",
                        onClick: !isWindowsOrLinux ? () => dropzone.open() : undefined,
                      })}
                    
                      name="company_logo"
                      className="file-upload"
                      type="file"
                      accept="image/*"
                      id="profileImageSelect"
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <Label label={"Add Logo"} htmlFor={"company_logo"} required />
                {formik.touched.company_logo && formik.errors.company_logo && (
                  <Error error={formik.errors.company_logo} />
                )}
              </div>
              <h1 className="tilteHead">Create a new account</h1>
              <p className="paregrapSub">
                Hey, Enter your details to get sign up to your account
              </p>
              <div className="form-group">
                <Label label={"Name"} htmlFor={"name"} required />
                <div className="possionIconInput">
                  <input
                    type="text"
                    placeholder="Enter Name or Company Name"
                    className="form-control"
                    name="name"
                    value={formik.values.name}
                    maxLength={60}
                    onChange={(e) => {
                      const capitalizedValue = capitalizeFirstLetterEachWord(
                        e.target.value
                      );
                      formik.setFieldValue("name", capitalizedValue);
                    }}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      formik.setFieldValue(
                        "name",
                        capitalizeFirstLetterEachWord(trimValue(e.target.value))
                      );
                    }}
                  />

                  <img src={SVGIMAGES.UserIcon} alt="" />
                </div>
                {formik.touched.name && formik.errors.name && (
                  <Error error={formik.errors.name} />
                )}
              </div>
              <div className="form-group">
                <Label label={"Email"} htmlFor={"Email"} required />
                <div className="possionIconInput">
                  <input
                    type="text"
                    placeholder="Enter Email"
                    className="form-control"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      formik.setFieldValue("email", trimValue(e.target.value));
                    }}
                    maxLength={100}
                  />
                  <img src={SVGIMAGES.EmailIcon} alt="" />
                </div>
                {formik.touched.email && formik.errors.email && (
                  <Error error={formik.errors.email} />
                )}
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <Label label={"Password"} htmlFor={"password"} required />
                    <div className="possionIconInput">
                      <img src={SVGIMAGES.KeyIcon} alt="" />
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Password"
                        className="form-control"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          formik.setFieldValue(
                            "password",
                            trimValue(e.target.value)
                          );
                        }}
                        maxLength={30}
                      />
                      <img
                        src={
                          showPassword
                            ? SVGIMAGES.EyeIcon
                            : SVGIMAGES.EyeOffIcon
                        }
                        alt=""
                        onClick={(e) => setShowPassword(!showPassword)}
                        className="imgRightEye"
                      />
                    </div>
                    {formik.touched.password && formik.errors.password && (
                      <Error error={formik.errors.password} />
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <Label
                      label={"Confirm Password"}
                      htmlFor={"confirm_password"}
                      required
                    />
                    <div className="possionIconInput">
                      <img src={SVGIMAGES.KeyIcon} alt="" />
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Enter Confirm Password"
                        className="form-control"
                        name="confirm_password"
                        value={formik.values.confirm_password}
                        onChange={formik.handleChange}
                        maxLength={30}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          formik.setFieldValue(
                            "confirm_password",
                            trimValue(e.target.value)
                          );
                        }}
                      />
                      <img
                        src={
                          showConfirmPassword
                            ? SVGIMAGES.EyeIcon
                            : SVGIMAGES.EyeOffIcon
                        }
                        alt=""
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        className="imgRightEye"
                      />
                    </div>
                    {formik.touched.confirm_password &&
                      formik.errors.confirm_password && (
                        <Error error={formik.errors.confirm_password} />
                      )}
                  </div>
                </div>
              </div>
              <div className="form-group ">
                <Label
                  label={
                    <span>
                      Access Code
                      <small>
                        <span
                          class="tooltip-toggleCus"
                          aria-label="This access code allows you to access your portfolio and display your projects in the application, You can enter your own code, or we’ll generate one for you and send it via email upon signup completion."
                          tabindex="0"
                        >
                          <img src={SVGIMAGES.infoIcon} alt="" className="infoiconmargin" />
                        </span>
                        &nbsp;&nbsp;(<span>Optional</span>)
                      </small>
                    </span>
                  }
                  htmlFor={"org_code"}
                  className="custom-form-label-weight"
                />

                <div className="possionIconInput">
                  <img src={SVGIMAGES.CodeIcon} alt="" />
                  <input
                    type="text"
                    placeholder="Create Access Code"
                    className="form-control"
                    name="org_code"
                    value={formik.values.org_code}
                    onChange={(e) => {
                      formik.setFieldValue("org_code", e.target.value);
                    }}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      formik.setFieldValue(
                        "org_code",
                        trimValue(e.target.value)
                      );
                    }}
                    maxLength={30}
                  />
                </div>
                {formik.touched.org_code && formik.errors.org_code && (
                  <Error error={formik.errors.org_code} />
                )}
              </div>
              {/* <div className="form-group">
                <Label label={"Category"} htmlFor={"Category"} required />
                <div className="possionIconInput">
                  <CategoryDropdown
                    Formik={formik}
                    categoryTypes={categoryList}
                    setNewCategoryTypes={setNewCategoryTypes}
                    sortedData={selectedCategoryType}
                    setSortedData={setSelectedCategoryType}
                  />
                </div>
                {formik.touched.categoryId &&
                  formik.values.categoryId?.length < 1 &&
                  newCategoryTypes?.length < 1 && (
                    <Error error={"Category is required!"} />
                  )}
              </div> */}
              <div className="form-group align-self-center d-flex">
                <button
                  type="submit"
                  className="formButton"
                  disabled={
                    isLoading ||
                    formik.errors.company_logo ||
                    formik.errors.name ||
                    formik.errors.email ||
                    formik.errors.password ||
                    formik.errors.confirm_password
                  }
                >
                  {isLoading ? <ButtonLoader /> : "Sign Up"}
                </button>
              </div>
              <div className="form-group">
                <h6>
                  Already have an account?
                  <button onClick={() => navigate("/login")}>Sign In</button>
                </h6>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showCropModal && (
        <ModalLayout
          show={showCropModal}
          setShow={setShowCropModal}
          centered
          title={"Crop Image"}
         
        >
          {src && (
            <ImageCrop
              src={src ? URL.createObjectURL(src) : ""}
              getCropImage={getCropImage}
              setShowCropModal={setShowCropModal}
              cancelModal={closeShowModal}
            />
          )}
        </ModalLayout>
      )}
    </div>
  );
};
