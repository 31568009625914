import { default as UserIcon } from "./user_icon.svg";
import { default as CameraIcon } from "./camera_icon.svg";
import { default as EyeIcon } from "./eye_icon.svg";
import { default as EmailIcon } from "./email_icon.svg";
import { default as KeyIcon } from "./key_icon.svg";
import { default as CodeIcon } from "./code_icon.svg";
import { default as EyeOffIcon } from "./eyeOff_icon.svg";
import {default as ChangePasswordImg} from "./change_password.svg";
import {default as EditIcon} from "./edit_icon.svg";
import {default as ProfileImgImg} from "./profileImg.svg";
import {default as ChangePasswordAdImg} from "./chnage-passwordAd.svg";
import {default as refreshImage} from "./refresh.svg";
import {default as PlusIcon} from "./plus_icon.svg";
import {default as DeleteIcon} from "./delete_icon.svg"
import {default as NoDataFoundImg} from "./no-data-found.svg";
import {default as CloseIcon} from "./close_icon.svg"
import {default as VideoPlayIcon} from "./video_paly_icon.svg"
import {default as DeleteModaleIcon} from "./deleteModal_icon.svg"
import {default as ProjectIcon} from "./project_icon.svg"
import {default as DomainIcon} from "./domian_icon.svg"
import {default as PlatformIcon} from "./platform_icon.svg"
import {default as TechnologyIcon} from "./technology_icon.svg"
import {default as MenuToggleImg} from "./menuToggle.svg"
import {default as DropDownIcon} from "./dropDown.svg"
import {default as CloseButton} from "./closeButton.svg"
import {default as PreviousIcon} from "./previous_icon.svg"
import {default as NextIcon} from "./next_icon.svg"
import {default as FreePlanIcon} from "./freePlan_icon.svg"
import {default as BasicPlanIcon} from "./basicPlan_icon.svg"
import {default as PremiumPlanIcon} from "./premiumPlan_icon.svg"
import {default as LogoutModaleIcon} from "./logoutModal_icon.svg"
import {default as ActivateProject} from "./activate_project.svg"
import {default as DeactivateProject} from "./deactive_project.svg"
import {default as infoIcon} from "./info_icon.svg"
import {default as backIcon} from "./back_icon.svg"
import {default as Logo} from "./auth_logo.svg"
import {default as SharedPortfolioIcon} from "./shared_portfolio.svg"
import {default as sideBarIcon} from "./sideBarLogo.svg"
import {default as coppyIcon} from "./copyIcon.svg"
import {default as coppyGreenIcon} from "./copyGreenIcon.svg"
import {default as contactUsIcon} from "./contact_icon.svg"

export const SVGIMAGES = {
  UserIcon,
  CameraIcon,
  EyeIcon,
  EmailIcon,
  KeyIcon,
  CodeIcon,
  EyeOffIcon,
  Logo,
  ChangePasswordImg,
  EditIcon,
  ProfileImgImg,
  ChangePasswordAdImg,
  refreshImage,
  PlusIcon,
  DeleteIcon,
  NoDataFoundImg,
  CloseIcon,
  VideoPlayIcon,
  DeleteModaleIcon,
   ProjectIcon,
   DomainIcon,
   PlatformIcon,
   TechnologyIcon,
   MenuToggleImg,
   DropDownIcon,
   CloseButton,
   PreviousIcon,
   NextIcon,
   FreePlanIcon,
   BasicPlanIcon,
   PremiumPlanIcon,
   LogoutModaleIcon,
   ActivateProject,
   DeactivateProject,
   infoIcon,
   backIcon,
   SharedPortfolioIcon,
   sideBarIcon,
   coppyIcon,
   coppyGreenIcon,
   contactUsIcon,
};
