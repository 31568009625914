import { useEffect, useState } from "react";
import {
  ButtonLoader,
  DataLoader,
  HeaderView,
  ItemsPerPageDropdown,
  ModalLayout,
  SidebarView,
  Switch,
} from "../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Label, Error } from "../components";

import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import Pagination from "../components/Pagination/Pagination";
import { SVGIMAGES } from "../assets/svgImages";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { capitalizeFirstLetter, capitalizeFirstLetterEachWord, trimValue } from "../utils/helper";
import { createUser, deleteUser, getuserList } from "../store/actions/userActions";

export const UsersView = () => {
  const dispatch = useDispatch();
  const collapseSidebar = useSelector(({ auth }) => auth?.collapseSidebar);
  const usersList = useSelector(({ users }) => users?.usersList);
  const totalItems = useSelector(({ users }) => users?.totalItems);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmModal, setConfimModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [selectedUserData, setSelectedUserData] = useState("");
  const [userId, setUserId] = useState("");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  useEffect(() => {
    const params = { pageLimit, currentPage, search };
    if (search === "" || search?.length >= 2) {
      setIsLoading(true);
      dispatch(getuserList(params)).finally(() => setIsLoading(false));
    }
  }, [search, currentPage, pageLimit, dispatch]);

  const AddUserFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
      remark: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
      .trim()
      .min(2, "Name must be at least 2 characters long")
      .required("Name is required!"),
      email: Yup.string()
        .trim()
        .email("Please enter a valid email address!")
        .required("Email is required!"),
        remark: Yup.string()
        .trim()
        .min(10, "Remark must be at least 10 characters long")
        .required("Remark is required!"),
    }),
    onSubmit: async (values) => {
      if (AddUserFormik.isValid && AddUserFormik.dirty) {
        const { email } = values;

        try {
          setIsLoading(true);
          await dispatch(createUser({ ...values, email: email.toLowerCase() })).then((res) => {
            if (res?.status === 200 && res?.data?.success) {
              AddUserFormik.resetForm();
              const params = { pageLimit, currentPage, search };
              dispatch(getuserList(params));
              toastr.success("Success", res?.data?.message);
              setAddModal(false);
            } else {
              toastr.error("Error", res?.data?.message);
            }
          });
        } catch (error) {
          toastr.error("Error", error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }
    },
  });

  const EditUserFormik = useFormik({
    initialValues: {
      id: "",
      name: "",
      email: "",
      remark: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
      .trim()
      .min(2, "Name must be at least 2 characters long")
      .required("Name is required!"),
      email: Yup.string()
        .trim()
        .email("Please enter a valid email address!")
        .required("Email is required!"),
        remark: Yup.string()
        .trim()
        .min(10, "Remark must be at least 10 characters long")
        .required("Remark is required!"),
    }),
    onSubmit: async (values) => {
      if (EditUserFormik.isValid && EditUserFormik.dirty) {
        const { email, ...other } = values
        console.log('other', other)

        try {
          setIsLoading(true);
          await dispatch(createUser(values)).then((res) => {
            if (res?.status === 200 && res?.data?.success) {
              EditUserFormik.resetForm();
              const params = { pageLimit, currentPage, search };
              dispatch(getuserList(params));
              toastr.success("Success", res?.data?.message);
              setEditModal(false);
            } else {
              toastr.error("Error", res?.data?.message);
            }
          });
        } catch (error) {
          toastr.error("Error", error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }
    },
  });
  const openEditModal = (user) => {
    setSelectedUserData(user);
    setEditModal(true);
  };
  useEffect(() => {
    if (selectedUserData && editModal) {
      EditUserFormik.setValues({
        id: selectedUserData?._id,
        name: selectedUserData?.name,
        email: selectedUserData?.email,
        remark: selectedUserData?.remark,
      });
    }
    // eslint-disable-next-line
  }, [selectedUserData, editModal]);

  const userDelete = async () => {
    setDeleteModal(false);
    try {
      await dispatch(deleteUser(userId)).then((res) => {
        if (res?.status === 200 && res?.data?.success) {
          const params = {
            pageLimit,
            currentPage:
              usersList?.length === 1 && currentPage > 1
                ? currentPage - 1
                : currentPage,
            search,

          };
          dispatch(getuserList(params));
          toastr.success("Success", res?.data?.message);
        } else {
          toastr.error("Error", res?.data?.message);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleStatusChange = async (id, status) => {
    setConfimModal(false);

    try {
      const params = {
        id: id || userId,
        status: status === "active" ? "inactive" : "active",
      };

      await dispatch(createUser(params)).then((response) => {
        if (response?.status === 200 && response?.data?.success) {
          toastr.success("Success", response?.data?.message);
          const params = {
            pageLimit,
            currentPage,
            search,

          };
          dispatch(getuserList(params));
        } else {
          toastr.error("Error", response?.data?.message);
        }
      });
    } catch (error) {
      toastr.error("Error", "Failed to update project status");
    }
  };

  return (
    <>
      <div className={collapseSidebar ? "mainBody sortingMain" : "mainBody"}>
        <HeaderView />

        <div className="wrapper">
          <SidebarView />
          <div className="main-container">
            <div className="content">
              <div className="row mb-3">
                <div className="col-xxl-12 custTabsCss">
                  <div className="row justify-content-between align-items-center">
                    <div className="col-xl-3 col-lg-4">
                      <div className="greetingsText">
                        <div className="greetingsText-heading">
                          <h3>Users
                            <small>
                              <span
                                class="tooltip-toggleuser pro tooltip-toggleuserpage"
                                aria-label="This access code allows you to access your portfolio and display your projects in the application, You can enter your own code, or we’ll generate one for you and send it via email upon signup completion."
                                tabindex="0"
                              >
                                <img src={SVGIMAGES.infoIcon} alt="" />
                              </span>
                            </small>
                          </h3>

                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-lg-5 d-flex">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id="button-tooltip-2">Add User</Tooltip>
                        }
                      >
                        <div className="buttons d-flex" variant="light">
                          <button
                            className="ctr-btn"
                            onClick={() => setAddModal(true)}
                          >
                            <img src={SVGIMAGES.PlusIcon} alt="" />
                          </button>
                        </div>
                      </OverlayTrigger>
                      <input
                        type="text"
                        className="custom-input-field"
                        placeholder="Search User"
                        onChange={(e) => {
                          setCurrentPage(1);
                          const s = e.currentTarget?.value
                            ?.replace(/[^\w\s]|_/g, "")
                            .replace(/\s{2,}/g, " ");
                          setSearch(s);
                        }}
                        value={search}
                      />
                    </div>
                  </div>
                  {usersList?.length > 0 &&
                  <div className="col-xl-3 col-lg-4 mb-3">
                    <ItemsPerPageDropdown
                      pageLimit={pageLimit}
                      setPageLimit={setPageLimit}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>}

                  {isLoading ? (
                    <DataLoader width={100} />
                  ) : (
                    <div className="row">
                      <div className="col-xxl-12">
                        {usersList?.length > 0 ? (
                          <>
                            <div className="table-responsive">
                              <table className="custom-table">
                                <thead>
                                  <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Remark</th>
                                    <th scope="col" className="text-center">Action</th>
                                    <th scope="col">Active</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {usersList?.length > 0 ? (
                                    usersList?.map((users) => (
                                      <tr key={usersList?._id}>
                                        <td
                                          onClick={() => openEditModal(users)}
                                        >
                                          {users?.name}
                                        </td>
                                        <td
                                          onClick={() => openEditModal(users)}
                                          className="w-descriptin"
                                        >
                                          {users?.email}
                                        </td>
                                        <td
                                          onClick={() => openEditModal(users)}
                                          className="w-descriptin"
                                        >
                                          {users?.remark}
                                        </td>
                                        <td className="text-center">
                                          <div className="action-btn">
                                            <button
                                              onClick={() => {
                                                setUserId(users?._id);
                                                setDeleteModal(true);
                                              }}
                                            >
                                              <img
                                                src={SVGIMAGES.DeleteIcon}
                                                alt=""
                                              />
                                            </button>
                                          </div>
                                        </td>
                                        <td>
                                          <Switch
                                            id={users?._id}
                                            checked={
                                              users?.status === "active"
                                            }
                                            onChange={() => {
                                              setUserId(users?._id);
                                              setCurrentStatus(users?.status);

                                              if (users?.status === "active") {
                                                setConfimModal(true);
                                              } else {
                                                handleStatusChange(
                                                  users?._id,
                                                  users?.status
                                                );
                                              }
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td className="text-center" colSpan={5}>
                                        No technology records found
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            <div className="cusPagination">
                              <Pagination
                                currentPage={currentPage}
                                totalCount={totalItems}
                                pageSize={pageLimit}
                                onPageChange={(page) => setCurrentPage(page)}
                              />
                            </div>
                          </>
                        ) : (
                          <div className="nodataFileBox">
                            <img src={SVGIMAGES.NoDataFoundImg} alt="" className="img-fluid" />
                            <h1>No Data Found</h1>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {deleteModal && (
        <ModalLayout show={deleteModal} setShow={setDeleteModal} centered>
          <img
            src={SVGIMAGES.DeleteModaleIcon}
            alt=""
            className="mainIconModal"
          />
          <h2>Delete User</h2>
          <p>Are you sure you want to Delete User?</p>
          <div className="footbutton">
            <button
              type="button"
              onClick={() => {
                setDeleteModal(false);
              }}
              className="cancelBtn"
            >
              Cancel
            </button>
            <button
              type="button"
              className="confirmBtn"
              onClick={() => {
                userDelete();
              }}
            >
              Confirm
            </button>
          </div>
        </ModalLayout>
      )}

      {editModal && (
        <ModalLayout
          show={editModal}
          setShow={setEditModal}
          centered
          title={"Edit User"}
          formik={EditUserFormik}
        >
          <form
            className="row g-3"
            id="editTechnologyForm"
            onSubmit={EditUserFormik.handleSubmit}
          >
            <div className="col-lg-12">
              <Label label={"Name"} htmlFor={"name"} required />
              <input
                type="text"
                className="custom-input-field"
                id="name"
                placeholder="Enter name"
                name="name"
                value={EditUserFormik.values.name}
                onChange={(e) => {
                  const capitalizedValue = capitalizeFirstLetterEachWord(
                    e.target.value
                  );
                  EditUserFormik.setFieldValue("name", capitalizedValue);
                }}
                onBlur={(e) => {
                  EditUserFormik.handleBlur(e);
                  EditUserFormik.setFieldValue(
                    "name",
                    capitalizeFirstLetterEachWord(trimValue(e.target.value))
                  );
                }}
                maxLength={60}

              />

              {EditUserFormik.errors.name && EditUserFormik.touched.name && (
                <Error error={EditUserFormik.errors.name} />
              )}
            </div>
            <div className="col-lg-12">
              <Label label={"Email"} htmlFor={"email"} required />
              <input
                type="text"
                className="custom-input-field"
                id="email"
                placeholder="Enter Email"
                name="email"
                value={EditUserFormik.values.email}
                onChange={EditUserFormik.handleChange}
                onBlur={EditUserFormik.handleBlur}
                disabled
              />

              {EditUserFormik.errors.email && EditUserFormik.touched.email && (
                <Error error={EditUserFormik.errors.email} />
              )}
            </div>
            <div className="col-lg-12">
              <Label label={"Remark"} htmlFor={"remark"} required />
              <textarea
                type="text"
                className="custom-input-field"
                id="remark"
                name="remark"
                placeholder="Enter Remark"
                rows="5"
                value={EditUserFormik.values.remark}
                onChange={(e) => {
                  const capitalizedValue = capitalizeFirstLetter(
                    e.target.value
                  );
                  EditUserFormik.setFieldValue("remark", capitalizedValue);
                }}
                onBlur={(e) => {
                  EditUserFormik.handleBlur(e);
                  EditUserFormik.setFieldValue(
                    "remark",
                    capitalizeFirstLetter(trimValue(e.target.value))
                  );
                }}
                maxLength={60}
              ></textarea>
              {EditUserFormik.errors.remark &&
                EditUserFormik.touched.remark && (
                  <Error error={EditUserFormik.errors.remark} />
                )}
            </div>
          </form>
          <div className="footbutton">
            <button
              type="button"
              onClick={() => {
                setEditModal(false);
                EditUserFormik.resetForm();
              }}
              className="cancelBtn"
            >
              Cancel
            </button>
            <button type="submit" form="editTechnologyForm">
              {isLoading ? <ButtonLoader /> : "Update"}
            </button>
          </div>
        </ModalLayout>
      )}

      {addModal && (
        <ModalLayout
          show={addModal}
          setShow={setAddModal}
          centered
          title={"Add User"}
          formik={AddUserFormik}
        >
          <form
            className="row g-3"
            id="addUserForm"
            method="POST"
            onSubmit={AddUserFormik.handleSubmit}
          >
            <div className="col-lg-12">
              <Label label={"Name"} htmlFor={"name"} required />
              <input
                type="text"
                className="custom-input-field"
                id="name"
                placeholder="Enter Name"
                name="name"
                value={AddUserFormik.values.name}
                onChange={(e) => {
                  const capitalizedValue = capitalizeFirstLetterEachWord(
                    e.target.value
                  );
                  AddUserFormik.setFieldValue("name", capitalizedValue);
                }}
                onBlur={(e) => {
                  AddUserFormik.handleBlur(e);
                  AddUserFormik.setFieldValue(
                    "name",
                    capitalizeFirstLetter(trimValue(e.target.value))
                  );
                }}
                maxLength={60}
              />

              {AddUserFormik.errors.name && AddUserFormik.touched.name && (
                <Error error={AddUserFormik.errors.name} />
              )}
            </div>
            <div className="col-lg-12">
              <Label label={"Email"} htmlFor={"email"} required />
              <input
                type="text"
                className="custom-input-field"
                id="email"
                placeholder="Enter Email"
                name="email"
                value={AddUserFormik.values.email}
                onChange={AddUserFormik.handleChange}
                onBlur={(e) => {
                  AddUserFormik.handleBlur(e);
                  AddUserFormik.setFieldValue(
                    "email",
                   trimValue(e.target.value)
                  );
                }}
                maxLength={100}
              />

              {AddUserFormik.errors.email && AddUserFormik.touched.email && (
                <Error error={AddUserFormik.errors.email} />
              )}
            </div>
            <div className="col-lg-12">
              <Label label={"Remark"} htmlFor={"remark"} required />
              <textarea
                type="text"
                className="custom-input-field"
                id="remark"
                name="remark"
                placeholder="Enter Remark"
                rows="5"
                value={AddUserFormik.values.remark}
                onChange={(e) => {
                  const capitalizedValue = capitalizeFirstLetter(
                    e.target.value
                  );
                  AddUserFormik.setFieldValue("remark", capitalizedValue);
                }}
                onBlur={(e) => {
                  AddUserFormik.handleBlur(e);
                  AddUserFormik.setFieldValue(
                    "remark",
                    capitalizeFirstLetter(trimValue(e.target.value))
                  );
                }}
                maxLength={60}
              ></textarea>
              {AddUserFormik.errors.remark && AddUserFormik.touched.remark && (
                <Error error={AddUserFormik.errors.remark} />
              )}
            </div>
          </form>
          <div className="footbutton">
            <button
              type="button"
              onClick={() => {
                setAddModal(false);
                AddUserFormik.resetForm();
              }}
              className="cancelBtn"
            >
              Cancel
            </button>
            <button type="submit" form="addUserForm">
              {isLoading ? <ButtonLoader /> : "Add"}
            </button>
          </div>
        </ModalLayout>
      )}

      {confirmModal && (
        <ModalLayout show={confirmModal} setShow={setConfimModal} centered>
          <img
            src={
              currentStatus
                ? SVGIMAGES.DeactivateProject
                : SVGIMAGES.ActivateProject
            }
            alt=""
            className="mainIconModal"
          />
          <h2>{currentStatus ? "Deactivate" : "Activate"} User</h2>
          <p className="w-100">
            Are you sure you want to deactivate this user?
          </p>
          <div className="footbutton">
            <button
              type="button"
              onClick={() => {
                setConfimModal(false);
              }}
              className="cancelBtn"
            >
              Cancel
            </button>
            <button
              type="button"
              className="confirmBtn"
              onClick={() => {
                handleStatusChange(userId, currentStatus);
              }}
            >
              Confirm
            </button>
          </div>
        </ModalLayout>
      )}
    </>
  );
};
