import { authActions,sharedPortfolioActions } from "../actions/actionTypes"



const intialState=()=>({
    portFolioList:[],
    totalPages:0,
    totalItems:0,
})

export const portFolioReducer=(state=intialState,action)=>{
  
    switch (action.type) {
        case sharedPortfolioActions.GET_SHAREDPORTFOLIO_LIST:
         return {
            ...state,
            portFolioList :action?.payload?.data,
            totalPages:action?.payload?.totalPages,
            totalItems:action?.payload?.totalItems
         };
         case authActions.AUTH_LOGOUT:
         return{
            ...state,
            portFolioList:[],
            totalPages:0,
            totalItems:0,
         };
        default:
            return state
    }
}