import React from "react";
import classnames from "classnames";

import "./Pagination.css";

import { DOTS, usePagination } from "./usePagination";
import { SVGIMAGES } from "../../assets/svgImages";

const Pagination = (props) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
    } = props;
    // console.log(totalCount);

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
    });
   
    if (currentPage === 0 || paginationRange?.length < 1 || (totalCount && !totalCount)) {
        return <h6 className="text-center py-3">No record found</h6>;
    }

    const onNext = () => {
        if (currentPage < paginationRange[paginationRange.length - 1]) {
            onPageChange(currentPage + 1);
        }
    };

    const onPrevious = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    let lastPage = paginationRange && paginationRange[paginationRange?.length - 1];
    // console.log(lastPage);
    return (
        <>
          {/* <div className="footsorting">

            <div className="col-xl-6 paginavition"> */}
              <ul
                // className={classnames("pagination-container", {
                //   [className]: className,
                // })}
              >
                <li
                  key="arrow-left"
                  className={classnames("pagination-item", {
                    disabled: currentPage === 1,
                  })}
                  onClick={onPrevious}
                >
                  {/* <div className="arrow left" /> */}
                  <button className="previousBtn"  onClick={onPrevious}  style={currentPage === 1 ? { color: 'gray' } : {}} >
                    <img src={SVGIMAGES.PreviousIcon} alt="Previous"
                   fill="#000" stroke="#000" 

                    />
                    {/* <span className="d-none d-xl-block">{constants.PREVIOUS}</span> */}
                    <span className="d-none d-xl-block d-lg-block">Previous</span>
                  </button>
                </li>
                {paginationRange?.map((pageNumber, index) => {
                      
                  if (pageNumber === DOTS) {
                  
                    return (
                      <li key={index} className="pagination-item dots">
                        &#8230;
                      </li>
                    );
                  }

                  return (
                    <li
                      key={`paginationKey-${pageNumber}`}
                      className={classnames("pagination-item", {
                        selected: pageNumber === currentPage,
                      })}
                      onClick={() => onPageChange(pageNumber)}
                    >
                      <button type="button" className={pageNumber === currentPage ? "active" : ""}>{pageNumber}</button>
                    </li>
                  );
                })}
                <li
                  key="arrow-right"
                  className={classnames("pagination-item", {
                    disabled: currentPage === lastPage,
                  })}
                  onClick={onNext}
                >
                  {/* <div className="arrow right" /> */}
                  <button  className="nextBtn" onClick={onNext} 
                  style={currentPage === lastPage ? { color: 'gray' } : {}}
                  >
                  <span className="d-none d-xl-block d-lg-block">Next</span>
                    {/* <span className="d-none d-xl-block">{constants.NEXT}</span>{" "} */}
                    <img src={SVGIMAGES.NextIcon} alt="Next" 
                   
                    />
                  </button>
                </li>
              </ul>
            {/* </div>
          </div> */}
        </>
       
    );
};

export default Pagination;


