import { Instance } from "../apiServices";
import { projectActions } from "./actionTypes";
import { ProjectApiEndpoints } from "../apiEndpoints";
import { RECORD_TYPES } from "../../constants/Enums";
import { refreshToken } from "./authActions";

export const getDomainTypes = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "GET", //method
      ProjectApiEndpoints.GET_TYPES_BY_RECORD_TYPES, //api-endpoint
      params, //data/params
      true //isAuth
    );

    if (response?.status === 200 && response?.data?.success) {
      dispatch({
        type: projectActions.DOMAIN_TYPES,
        payload: response?.data,
      });
      return response;
    } else {
      return response;
    }
  };
};

export const getPlatformTypes = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "GET", //method
      ProjectApiEndpoints.GET_TYPES_BY_RECORD_TYPES + RECORD_TYPES.PLATFORM, //api-endpoint
      params, //data/params
      true //isAuth
    );
    if (response?.status === 200 && response?.data?.success) {
      dispatch({
        type: projectActions.PLATFORM_TYPES,
        payload: response?.data?.data,
      });
      return response;
    } else {
      return response.response;
    }
  };
};

export const getTechnologyTypes = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "GET", //method
      ProjectApiEndpoints.GET_TYPES_BY_RECORD_TYPES + RECORD_TYPES.TECHNOLOGY, //api-endpoint
      params, //data/params
      true //isAuth
    );
    if (response?.status === 200 && response?.data?.success) {
      dispatch({
        type: projectActions.TECHNOLOGY_TYPES,
        payload: response?.data?.data,
      });
      return response;
    } else {
      return response.response;
    }
  };
};

export const createProject = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "POST", //method
      ProjectApiEndpoints.ADD_PROJECT_API, //api-endpoint
      params, //data/params
      true, //isAuth
      false
    );
    if (response?.status === 200 && response?.data?.success) {
      return response;
    } else if (
      response?.response?.status === 403 &&
      !response?.response?.data?.success
    ) {
      const res = await dispatch(refreshToken());
      if (res?.data?.success && res?.status === 200) {
       dispatch(createProject(params));
      }
    } else {
      return response.response;
    }
  };
};

export const updateProject = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "PUT", //method
      ProjectApiEndpoints.UPDATE_PROJECT_API, //api-endpoint
      params, //data/params
      true, //isAuth
      false
    );

    if (response?.status === 200 && response?.data?.success) {
      return response;
    } else if (
      response?.response?.status === 403 &&
      !response?.response?.data?.success
    ) {
      const res = await dispatch(refreshToken());
      if (res?.data?.success && res?.status === 200) {
        dispatch(updateProject(params));
      }
    } else {
      return response.response;
    }
  };
};
export const deleteProject = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "DELETE", //method
      ProjectApiEndpoints.DELETE_PROJECT_API + params, //api-endpoint
      params, //data/params
      true, //isAuth
      true
    );

    if (response?.status === 200 && response?.data?.success) {
      return response;
    } else if (
      response?.response?.status === 403 &&
      !response?.response?.data?.success
    ) {
      const res = await dispatch(refreshToken());
      if (res?.data?.success && res?.status === 200) {
         dispatch(deleteProject(params));
      }
    }else {
      return response.response;
    }
  };
};

export const getProjectList = (params) => {
  const search = params?.search;
  const pageNumber = params?.currentPage;
  const limit = params?.pageLimit;
  const searchParam = search ? `&name=${search}` : "";

  const domainId = params?.selectedDomainType[0];

  const domainFilter = domainId ? `&domainId=${domainId}` : "";
  const platformId = params?.selectedPlatformType;

  const technologyId = params?.selectedTechnologyType;

  var platformFilter = "";
  if (platformId?.length) {
    for (let i = 0; i < platformId.length; i++) {
      platformFilter += `&platformId=${platformId[i]}`;
    }
  }
  var technologyFilter = "";
  if (technologyId?.length) {
    for (let i = 0; i < technologyId.length; i++) {
      technologyFilter += `&technologyId=${technologyId[i]}`;
    }
  }

  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "GET", //method
      ProjectApiEndpoints.GET_PROJECT_LIST_API +
        `?page=${pageNumber}&limit=${limit}` +
        searchParam +
        domainFilter +
        platformFilter +
        technologyFilter, //api-endpoint
      null, //data/params
      true //isAuth
    );

    if (response?.status === 200 && response?.data?.success) {
      dispatch({
        type: projectActions.GET_PROJECT_LIST,
        payload: response?.data,
      });
      return response;
    } else {
      return response.response;
    }
  };
};
