import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const role = "Organization";

export const AdminAuthRoutes = () => {
  const auth = useSelector((state) => state?.auth?.accessToken);
  const userRoleType = useSelector((state) => state?.auth?.userType);
  const isAuthorized = auth?.length > 0 && userRoleType === role;

  return isAuthorized ? <Outlet /> : <Navigate to="/" />;
};

export const PrivateRoutes = () => {
  const auth = useSelector((state) => state?.auth?.accessToken);
  const userRoleType = useSelector((state) => state?.auth?.userType);
  const isAuthorized = auth?.length > 0 && userRoleType === role;

  return isAuthorized ? <Navigate to="/projects" /> : <Outlet />;
};
