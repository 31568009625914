import { authActions, domainActions } from "../actions/actionTypes";

const intialState=()=>({
    domainList:[],
    totalPages:0,
    totalItems:0,
})

export const domainReducer=(state=intialState,action)=>{
    switch (action.type) {
        case domainActions.GET_DOMAIN_LIST:
         return {
            ...state,
            domainList :action.payload?.data,
            totalPages:action?.payload?.totalPages,
            totalItems:action?.payload?.totalItems
         }
         case authActions.AUTH_LOGOUT:
            return {
                ...state,
                domainList:[],
                totalPages:0,
                totalItems:0
            }
    
        default:
            return state
    }
}