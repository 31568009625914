import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toastr } from "react-redux-toastr";
import { useDispatch, useSelector } from "react-redux";
import {
  HeaderView,
  Label,
  Error,
  ModalLayout,
  SidebarView,
  ButtonLoader,
  DataLoader,
  ItemsPerPageDropdownTab,
} from "../components";

import {
  createDomain,
  getDomainList,
  deleteDomain,
} from "../store/actions/domainActions";
import { RECORD_TYPES } from "../constants/Enums";
import Pagination from "../components/Pagination/Pagination";
import { SVGIMAGES } from "../assets/svgImages";
import { capitalizeFirstLetter, trimValue } from "../utils/helper";

export const DomainView = () => {
  const dispatch = useDispatch();
  const collapseSidebar = useSelector(({ auth }) => auth?.collapseSidebar);
  const domainList = useSelector(({ domain }) => domain?.domainList);
  const totalItems = useSelector(({ domain }) => domain?.totalItems);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDomainData, setSelectedDomainData] = useState("");
  const [domainId, setDomainId] = useState("");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [getListBy, setGetListBy] = useState("organization");

  useEffect(() => {
    const params = { pageLimit, currentPage, search, getListBy };
    if (search === "" || search?.length >= 2) {
      setIsLoading(true);
      dispatch(getDomainList(params)).finally(() => setIsLoading(false));
    }
  }, [currentPage, search, getListBy, pageLimit, dispatch]);

  const AddDomainFormik = useFormik({
    initialValues: {
      title: "",
      remark: "",
      recordType: RECORD_TYPES.DOMAIN,
    },
    validationSchema: Yup.object({
      title: Yup.string()
      .trim()
      .min(2, "Domain must be at least 2 characters long")
      .required("Domain name is required!"),
      remark: Yup.string()
      .trim()
      .min(10, "Remark must be at least 10 characters long")
      .required("Remark is required!"),
      
    }),
    onSubmit: async (values) => {
      if (AddDomainFormik.isValid && AddDomainFormik.dirty) {
        try {
          setIsLoading(true);
          await dispatch(createDomain(values)).then((res) => {
            if (res?.status === 200 && res?.data?.success) {
              AddDomainFormik.resetForm();
              const params = { pageLimit, currentPage, search, getListBy };
              dispatch(getDomainList(params));
              toastr.success("Success", res?.data?.message);
              setAddModal(false);
            } else {
              toastr.error("Error", res?.data?.message);
            }
          });
        } catch (error) {
          toastr.error("Error", error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }
    },
  });

  const EditDomainFormik = useFormik({
    initialValues: {
      id: "",
      title: "",
      remark: "",
      recordType: RECORD_TYPES.DOMAIN,
    },
    validationSchema: Yup.object({
      title: Yup.string()
      .trim()
      .min(2, "Domain must be at least 2 characters long")
      .required("Domain name is required!"),
      remark: Yup.string()
      .trim()
      .min(10, "Remark must be at least 10 characters long")
      .required("Remark is required!"),
    }),
    onSubmit: async (values) => {
      if (EditDomainFormik.isValid && EditDomainFormik.dirty) {
        try {
          setIsLoading(true);
          await dispatch(createDomain(values)).then((res) => {
            if (res?.status === 200 && res?.data?.success) {
              EditDomainFormik.resetForm();
              const params = { pageLimit, currentPage, search, getListBy };
              dispatch(getDomainList(params));
              toastr.success("Success", res?.data?.message);
              setEditModal(false);
            } else {
              toastr.error("Error", res?.data?.message);
            }
          });
        } catch (error) {
          toastr.error("Error", error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }
    },
  });

  const openEditModal = (domain) => {
    setSelectedDomainData(domain);
    setEditModal(true);
  };

  useEffect(() => {
    if (selectedDomainData && editModal) {
      EditDomainFormik.setValues({
        id: selectedDomainData._id,
        title: selectedDomainData.title,
        remark: selectedDomainData.remark,
        recordType: RECORD_TYPES.DOMAIN,
      });
    }
    // eslint-disable-next-line
  }, [selectedDomainData, editModal]);

  const domainDelete = async () => {
    setDeleteModal(false);
    try {
      await dispatch(deleteDomain(domainId)).then((res) => {
        if (res?.status === 200 && res?.data?.success) {
          const params = {
            pageLimit,
            currentPage:
              domainList?.length === 1 && currentPage > 1
                ? currentPage - 1
                : currentPage,
            search,
            getListBy,
          };
          dispatch(getDomainList(params));

          toastr.success("Success", res?.data?.message);
        } else {
          toastr.error("Error", res?.data?.message);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div className={collapseSidebar ? "mainBody sortingMain" : "mainBody"}>
        <HeaderView />

        <div className="wrapper">
          <SidebarView />
          <div className="main-container">
            <div className="content">
              <div className="row mb-3">
                <div className="col-xxl-12 custTabsCss">
                  <div className="row justify-content-between align-items-center">
                    <div className="col-xl-3 col-lg-4">
                      <div className="greetingsText">
                        <div className="greetingsText-heading">
                          <h3>Domain
                            <small>
                              <span
                                class="tooltip-toggleuser pro"
                                aria-label="The Domains section allows you to view your list of domains, including the default domain created by Folio4u. You can add new domains by clicking the plus button. These domains can be used when adding projects, and domains added by you will be visible only to you."
                                tabindex="0"
                              >
                                <img src={SVGIMAGES.infoIcon} alt="" />
                              </span>
                            </small>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-5 d-flex">
                      {getListBy === "organization" && (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="button-tooltip-2">Add Domain</Tooltip>
                          }
                        >
                          <div className="buttons d-flex" variant="light">
                            <button
                              className="ctr-btn"
                              onClick={() => setAddModal(true)}
                            >
                              <img src={SVGIMAGES.PlusIcon} alt="" />
                            </button>
                          </div>
                        </OverlayTrigger>
                      )}
                      <input
                        type="text"
                        className="custom-input-field"
                        placeholder="Search Domain"
                        value={search}
                        onChange={(e) => {
                          setCurrentPage(1);
                          const s = e.currentTarget?.value
                            ?.replace(/[^\w\s]|_/g, "")
                            .replace(/\s{2,}/g, " ");
                          setSearch(s);
                        }}
                      />
                    </div>
                  </div>
                  {domainList?.length > 0 &&
                  <div className="col-xl-12">
                    <ItemsPerPageDropdownTab
                      pageLimit={pageLimit}
                      setPageLimit={setPageLimit}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>}
                  <Tabs
                    defaultActiveKey="created_by_me"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    onSelect={(key) => {
                      if (key === "created_by_me") {
                        setCurrentPage(1)
                        setGetListBy("organization");
                      } else if (key === "default") {
                        setGetListBy("admin");
                        setCurrentPage(1)
                      }
                    }}
                  >
                    <Tab eventKey="created_by_me" title="Created by me">
                      {isLoading ? (
                        <DataLoader width={100} />
                      ) : (
                        <div className="row">
                          <div className="col-xxl-12">
                            {domainList?.length > 0 ? (
                              <>
                                <div className="table-responsive">
                                  <table className="custom-table">
                                    <thead>
                                      <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Remark</th>
                                        <th scope="col">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {domainList?.length > 0 ? (
                                        domainList?.map((domain) => (
                                          <tr key={domain?._id}>
                                            <td
                                              onClick={() =>
                                                domain?.createdBy ===
                                                "organization" &&
                                                openEditModal(domain)
                                              }
                                              className="w-descriptin"
                                            >
                                              {domain?.title}
                                            </td>
                                            <td
                                              onClick={() =>
                                                domain?.createdBy ===
                                                "organization" &&
                                                openEditModal(domain)
                                              }
                                              className="w-descriptin"
                                            >
                                              {domain?.remark}
                                            </td>
                                            <td>
                                              {domain?.createdBy ===
                                                "organization" && (
                                                  <div className="action-btn">
                                                    <button
                                                      onClick={() => {
                                                        setDomainId(domain?._id);
                                                        setDeleteModal(true);
                                                      }}
                                                    >
                                                      <img
                                                        src={SVGIMAGES.DeleteIcon}
                                                        alt=""
                                                      />
                                                    </button>
                                                  </div>
                                                )}
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <td
                                            className="text-center"
                                            colSpan={5}
                                          >
                                            No Domain records found
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <div className="cusPagination">
                                  <Pagination
                                    currentPage={currentPage}
                                    totalCount={totalItems}
                                    pageSize={pageLimit}
                                    onPageChange={(page) =>
                                      setCurrentPage(page)
                                    }
                                  />
                                </div>
                              </>
                            ) : (
                              <div className="nodataFileBox">
                                <img src={SVGIMAGES.NoDataFoundImg} alt="" className="img-fluid" />
                                <h1>No Data Found</h1>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Tab>
                    <Tab eventKey="default" title="Default">
                      {isLoading ? (
                        <DataLoader width={100} />
                      ) : (
                        <div className="row">
                          <div className="col-xxl-12">
                            {domainList?.length > 0 ? (
                              <>
                                <div className="table-responsive">
                                  <table className="custom-table">
                                    <thead>
                                      <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Remark</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {domainList?.length > 0 ? (
                                        domainList?.map((domain) => (
                                          <tr key={domain?._id}>
                                            <td
                                              onClick={() =>
                                                domain?.createdBy ===
                                                "organization" &&
                                                openEditModal(domain)
                                              }
                                            >
                                              {domain?.title}
                                            </td>
                                            <td
                                              onClick={() =>
                                                domain?.createdBy ===
                                                "organization" &&
                                                openEditModal(domain)
                                              }
                                              className="w-descriptin"
                                            >
                                              {domain?.remark}
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <td
                                            className="text-center"
                                            colSpan={5}
                                          >
                                            No Domain records found
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <div className="cusPagination">
                                  <Pagination
                                    currentPage={currentPage}
                                    totalCount={totalItems}
                                    pageSize={pageLimit}
                                    onPageChange={(page) =>
                                      setCurrentPage(page)
                                    }
                                  />
                                </div>
                              </>
                            ) : (
                              <div className="nodataFileBox">
                                <img src={SVGIMAGES.NoDataFoundImg} alt="" />
                                <h1>No Data Found</h1>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {deleteModal && (
        <ModalLayout show={deleteModal} setShow={setDeleteModal} centered>
          <img
            src={SVGIMAGES.DeleteModaleIcon}
            alt=""
            className="mainIconModal"
          />
          <h2>Delete Domain</h2>
          <p>Are you sure you want to Delete Domain?</p>
          <div className="footbutton">
            <button
              type="button"
              onClick={() => {
                setDeleteModal(false);
              }}
              className="cancelBtn"
            >
              Cancel
            </button>
            <button
              type="button"
              className="confirmBtn"
              onClick={() => {
                domainDelete();
              }}
            >
              Confirm
            </button>
          </div>
        </ModalLayout>
      )}

      {editModal && (
        <ModalLayout
          show={editModal}
          setShow={setEditModal}
          centered
          title={"Edit Domain"}
          formik={EditDomainFormik}
        >
          <form
            className="row g-3"
            id="EditDomainForm"
            onSubmit={EditDomainFormik.handleSubmit}
          >
            <div className="col-lg-12">
              <Label label={"Domain"} htmlFor={"domainName"} required />
              <input
                type="text"
                className="custom-input-field"
                id="domainName"
                placeholder="Enter Domain"
                name="title"
                value={EditDomainFormik.values.title}
                onChange={(e) => {
                  const capitalizedValue = capitalizeFirstLetter(
                    e.target.value
                  );
                  EditDomainFormik.setFieldValue("title", capitalizedValue);
                }}
                onBlur={(e) => {
                  EditDomainFormik.handleBlur(e);
                  EditDomainFormik.setFieldValue(
                    "title",
                    capitalizeFirstLetter(trimValue(e.target.value))
                  );
                }}
                maxLength={60}
              />

              {EditDomainFormik.errors.title &&
                EditDomainFormik.touched.title && (
                  <Error error={EditDomainFormik.errors.title} />
                )}
            </div>
            <div className="col-lg-12">
              <Label label={"Remark"} htmlFor={"remark"} required />
              <textarea
                type="text"
                className="custom-input-field"
                id="remark"
                name="remark"
                placeholder="Enter Remark"
                rows="5"
                value={EditDomainFormik.values.remark}
                onChange={(e) => {
                  const capitalizedValue = capitalizeFirstLetter(
                    e.target.value
                  );
                  EditDomainFormik.setFieldValue("remark", capitalizedValue);
                }}
                onBlur={(e) => {
                  EditDomainFormik.handleBlur(e);
                  EditDomainFormik.setFieldValue(
                    "remark",
                    capitalizeFirstLetter(trimValue(e.target.value))
                  );
                }}
                maxLength={60}
              ></textarea>
              {EditDomainFormik.errors.remark &&
                EditDomainFormik.touched.remark && (
                  <Error error={EditDomainFormik.errors.remark} />
                )}
            </div>
          </form>
          <div className="footbutton">
            <button
              type="button"
              onClick={() => {
                setEditModal(false);
                EditDomainFormik.resetForm();
              }}
              className="cancelBtn"
            >
              Cancel
            </button>
            <button type="submit" form="EditDomainForm">
              {" "}
              {isLoading ? <ButtonLoader /> : "Update"}
            </button>
          </div>
        </ModalLayout>
      )}

      {addModal && (
        <ModalLayout
          show={addModal}
          setShow={setAddModal}
          centered
          title={"Add Domain"}
          formik={AddDomainFormik}
        >
          <form
            className="row g-3"
            id="addDomainForm"
            method="POST"
            onSubmit={AddDomainFormik.handleSubmit}
          >
            <div className="col-lg-12">
              <Label label={"Domain"} htmlFor={"domainName"} required />
              <input
                type="text"
                className="custom-input-field"
                id="domainName"
                placeholder="Enter Domain"
                name="title"
                value={AddDomainFormik.values.title}
                onChange={(e) => {
                  const capitalizedValue = capitalizeFirstLetter(
                    e.target.value
                  );
                  AddDomainFormik.setFieldValue("title", capitalizedValue);
                }}
                onBlur={(e) => {
                  AddDomainFormik.handleBlur(e);
                  AddDomainFormik.setFieldValue(
                    "title",
                    capitalizeFirstLetter(trimValue(e.target.value))
                  );
                }}
                maxLength={60}
              />

              {AddDomainFormik.errors.title &&
                AddDomainFormik.touched.title && (
                  <Error error={AddDomainFormik.errors.title} />
                )}
            </div>
            <div className="col-lg-12">
              <Label label={"Remark"} htmlFor={"remark"} required />
              <textarea
                type="text"
                className="custom-input-field"
                id="remark"
                name="remark"
                placeholder="Enter Remark"
                rows="5"
                value={AddDomainFormik.values.remark}
                // onChange={AddDomainFormik.handleChange}
                onChange={(e) => {
                  const capitalizedValue = capitalizeFirstLetter(
                    e.target.value
                  );
                  AddDomainFormik.setFieldValue("remark", capitalizedValue);
                }}
                onBlur={(e) => {
                  AddDomainFormik.handleBlur(e);
                  AddDomainFormik.setFieldValue(
                    "remark",
                    capitalizeFirstLetter(trimValue(e.target.value))
                  );
                }}
                maxLength={60}
              ></textarea>
              {AddDomainFormik.errors.remark &&
                AddDomainFormik.touched.remark && (
                  <Error error={AddDomainFormik.errors.remark} />
                )}
            </div>
          </form>
          <div className="footbutton">
            <button
              type="button"
              onClick={() => {
                AddDomainFormik.resetForm();
                setAddModal(false);
              }}
              className="cancelBtn"
            >
              Cancel
            </button>
            <button type="submit" form="addDomainForm">
              {isLoading ? <ButtonLoader /> : "Add"}
            </button>
          </div>
        </ModalLayout>
      )}
    </>
  );
};
