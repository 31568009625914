import { useSelector } from "react-redux";
import { convertTimestampToDate } from "../../utils/helper";
import { ModalLayout } from "../ModalLayout";

export const CurrentPlan = ({
  showSubscriptionModal,
  setShowSubscriptionModal,
  planDetails,
  ...props
}) => {

    const currentSubscription = useSelector(
        ({ subscription }) => subscription?.currentSubscription
      );
  return (
    <ModalLayout
      show={showSubscriptionModal}
      setShow={setShowSubscriptionModal}
      onClose={props?.onClose}
      centered
      title={"Current Plan"}
      className={"notification"}
    >
      <div className="row justify-content-center">
        <div className="">
          <div className={"currentSP"}>
            <ul>
                <li>
                    <span className="title">Plan</span>
                    <span className="titleDetails">Gold Plan</span>
                </li>
                <li>
                    <span className="title">Price</span>
                    <span className="titleDetails">$ {planDetails?.price}</span>
                </li>
                <li>
                    <span className="title">Projects</span>
                    <span className="titleDetails">Unlimited</span>
                </li>
                <li>
                    <span className="title">Expires On</span>
                    <span className="titleDetails">{convertTimestampToDate(currentSubscription?.endDateOfSub)?.toString()}</span>
                </li>
            </ul>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
