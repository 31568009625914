
import React, { useEffect, useState } from "react";
import Select from "react-select";

export const CountryDropdown = ({ setClientCountry, Formik, clientSelectedCountry }) => {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
      });
  }, []);

  const handleChange = (selectedOption) => {
   const countryName = selectedOption?.label?.replace(/^[^\s]+\s/, '');
    setSelectedCountry(selectedOption)
    setClientCountry(countryName)
    Formik?.setFieldValue('clientCountry',countryName)
  }

  useEffect(() => {
     if (clientSelectedCountry && countries?.length > 0) {
      const matchingOption = countries?.find(option => option?.label?.replace(/^[^\s]+\s/, '') === clientSelectedCountry);
     setSelectedCountry(matchingOption || null);
    } else {
      setSelectedCountry(null);
    }
  }, [clientSelectedCountry, countries]);

  return (
    <Select
      options={countries}
      value={selectedCountry}
      onChange={(selectedOption) => handleChange(selectedOption)}
      placeholder="Select Country"
    />
  );
};
