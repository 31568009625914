import { UsersActions, authActions } from "../actions/actionTypes";

const intialState = () => ({
  usersList: [],
  totalPages: 0,
  totalItems: 0,
});

export const usersReducer = (state = intialState, action) => {
  switch (action.type) {
    case UsersActions.GET_USERS_LIST:
      return {
        ...state,
        usersList: action?.payload?.data,
        totalPages: action?.payload?.totalPages,
        totalItems: action?.payload?.totalItems,
      };
    case authActions.AUTH_LOGOUT:
      return {
        ...state,
        usersList: [],
        totalPages: 0,
        totalItems: 0,
      };
    default:
      return state;
  }
};
