import axios from "axios";
// for getting default header
const getInstanceHeaders = (isAuth, token, isFormData) => {
  let headers = {
    "Content-Type": "application/json",
    // "ngrok-skip-browser-warning": "your-custom-value",
  };

  if (isAuth) {
    headers["Authorization"] = "Bearer " + token;
  }
  if (isFormData) {
    headers["Content-Type"] = "multipart/form-data";
  }
  return headers;
};
// for initiating api call from redux-action

export const Instance = async (
  getState,
  method = "GET",
  URL,
  params = {},
  isAuth = false,
  isFormData
) => {
  let state = getState();
  const token = state?.auth?.accessToken;
  // const token = localStorage.getItem('accessToken');

  const headers = getInstanceHeaders(isAuth, token, isFormData);

  try {
    let config = {
      method: method,
      url: URL,
      headers: headers,
    };

    if (method === "GET") {
      config = { ...config };
    } else if (method === "DELETE") {
      config.data = params ? JSON.stringify(params) : new FormData();
    } else if (method === "PUT") {
      config.data = params;
    } else if (method === "PATCH") {
      config.data = params ? params : new FormData();
    } else {
      config.data = params ? params : new FormData();
    }

    const response = await axios(config);
    return response;
  } catch (err) {
    return err;
  }
};
