import React, { useState } from "react";

import { Formik } from "formik";
import * as Yup from "yup";
// import { loadingState } from "../../common/store/actions/auth-action";
// import { sendContactUsMail } from "../../common/store/actions/super-actions";
// import { useDispatch, useSelector } from "react-redux";
// import { toastr } from "react-redux-toastr";
// import { NETWORK_ERROR } from "../../common/constants";
// import SpinnerLoading from "../../common/components/spinner";
import { useNavigate } from "react-router-dom";
import { WebFooterView } from "./web-footer";
import { WebHeaderView } from "./web-header";
import { ContactUs } from "../../store/actions/authActions";

import { toastr } from "react-redux-toastr";
import { useDispatch } from "react-redux";
import { CountryCodeDropdown } from "../../components";
import { capitalizeFirstLetter } from "../../utils/helper";
export const ContactView = () => {
  const dispatch = useDispatch();
  const [successMessage] = useState(false);
  // const isLoading = useSelector(({ auth }) => auth?.loadingState);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [resetDropdown, setResetDropdown] = useState(false);
  const navigate = useNavigate();
  const MapStyle = {
    width: "100%",
    height: 600,
  };

  return (
    <>
      <WebHeaderView />

      <div className="cusSlider contactSlide">
        <div className="container-fluid">
          <ul>
            <li>
              <button onClick={() => navigate("/")}>Home</button>
            </li>
            <li>|</li>
            <li>Contact Us</li>
          </ul>
          <h1>Contact Us</h1>
        </div>
      </div>

      <div className="contactPage">
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            <div className="col-md-5">
              <h1>
                Get <span className="gradient-text">In Touch</span>
              </h1>
              <p>
                Please email us at the following address if you're searching on
                our website
              </p>
              <div className="eMailBx">
                <h2>Contact:</h2>
                <h4>(+91) 96993 56148</h4>
              </div>
              <div className="eMailBx">
                <h2>Email:</h2>
                <h6>
                  We will provide you with the best service within 24 hours of
                  receiving your details.
                </h6>
                <p className="text-left">support@folio4u.com</p>
              </div>
              <div className="eMailBx">
                <h2>Address:</h2>
                <h4>
                  601, Shekhar central, 4-5, Manoramaganj, Near Palasia Square,
                  Indore, Madhya Pradesh 452001 - (India)
                </h4>
              </div>
            </div>
            <div className="col-md-5">
              <div className="contactForm">
                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    countryCode: "",
                    email: "",
                    phone: "",
                    message: "",
                  }}
                  validationSchema={Yup.object().shape({
                    firstName: Yup.string().trim(),
                    lastName: Yup.string().trim(),
                    email: Yup.string()
                      .trim()
                      .email("Please enter an valid email address!")
                      .required("Email is required!"),
                    phone: Yup.string().trim(),
                    message: Yup.string()
                      .trim()
                      .required("Message is required"),
                  })}
                  onSubmit={(values, { resetForm }) => {
                    try {
                      const {
                        firstName,
                        lastName,
                        email,
                        countryCode,
                        phone,
                        message,
                      } = values;
                      const countryCodeStr = countryCode.toString();
                      const phoneStr = phone.toString();
                      const lowercaseemail = email.toLowerCase();
                      let formattedPhone = phoneStr;
                      if (phoneStr.startsWith(countryCodeStr)) {
                        formattedPhone = phoneStr.slice(countryCodeStr.length);
                      }

                      const params = {
                        firstName: firstName,
                        lastName: lastName,
                        phoneNumber: formattedPhone,
                        countryCode: countryCode,
                        message: message,
                        email: lowercaseemail,
                      };

                      dispatch(ContactUs(params)).then((res) => {
                        if (res?.status === 200 && res?.data?.success) {
                          toastr.success("Success", res?.data?.message);
                          resetForm();
                          setPhoneNumber(""); // Clear phone number
                          setResetDropdown(true); // Trigger reset
                          setTimeout(() => setResetDropdown(false), 0);
                        } else {
                          toastr.error("Error", res?.data?.message);
                        }
                      });
                    } catch (error) {
                      console.log("catch");
                      toastr.error("Error", error);
                    } finally {
                    }
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      {successMessage && (
                        <h5 className="text-success">{successMessage}</h5>
                      )}
                      <h2>Send Us A Message</h2>
                      <div className="row">
                        <div className="col-lg-6">
                          <div class="form-group  formBxCus">
                            <div class="group">
                              <input
                                type="text"
                                name="firstName"
                                placeholder="First Name"
                                // onChange={handleChange}
                                onChange={(e) => {
                                  const capitalizedValue =
                                    capitalizeFirstLetter(e.target.value);
                                  handleChange({
                                    target: {
                                      name: "firstName",
                                      value: capitalizedValue,
                                    },
                                  });
                                }}
                                onBlur={handleBlur}
                                value={values.firstName}
                                
                              />
                              {errors.firstName && touched.firstName && (
                                <p className="validationweb">
                                  {errors.firstName}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div class="form-group  formBxCus">
                            <div class="group">                          
                              <input
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                                onChange={(e) => {
                                  const capitalizedValue =
                                    capitalizeFirstLetter(e.target.value);
                                  handleChange({
                                    target: {
                                      name: "lastName",
                                      value: capitalizedValue,
                                    },
                                  });
                                }}
                                onBlur={handleBlur}
                                value={values.lastName}
                              />
                             

                              {errors.lastName && touched.lastName && (
                                <p className="validationweb">
                                  {errors.lastName}
                                </p>
                              )}
                            </div>
                          </div>
                          {/* <div className="form-group">
                            <label>
                              Last Name <span className="ColorRed">*</span>{" "}
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Last name"
                              className="form-control"
                              name="lastName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.lastName}
                            />
                            {errors.lastName && touched.lastName && (
                              <p className="validationweb">{errors.lastName}</p>
                            )}
                          </div> */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group formBxCus">
                            <div class="group">
                              <input
                                type="text"
                                placeholder="Email *"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />

                              {errors.email && touched.email && (
                                <p className="validationweb">{errors.email}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ---------------for country code */}
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group formBxCus">
                            <div class="group">
                              <CountryCodeDropdown
                                onChangeValue={(value, countryCode) => {
                                  handleChange({
                                    target: {
                                      name: "countryCode",
                                      value: countryCode?.dialCode,
                                    },
                                  });
                                  handleChange({
                                    target: { name: "phone", value },
                                  });
                                  setPhoneNumber(value);
                                }}
                                phoneNumber={phoneNumber}
                                reset={resetDropdown}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ---------------for country code */}
                      {/* <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group formBxCus">
                            <div class="group">
                              <input
                                type="text"
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                              />
                              <span class="bar"></span>
                              <label>Phone Number </label>

                              {errors.phone && touched.phone && (
                                <p className="validationweb">{errors.phone}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group formBxCus">
                            <div class="group">
                              <textarea
                                type="text"
                               placeholder="Message *"
                                name="message"
                                rows="6"
                                onChange={(e) => {
                                  const capitalizedValue =
                                    capitalizeFirstLetter(e.target.value);
                                  handleChange({
                                    target: {
                                      name: "message",
                                      value: capitalizedValue,
                                    },
                                  });
                                }}
                                onBlur={handleBlur}
                                value={values.message}
                              ></textarea>

                              {errors.message && touched.message && (
                                <p className="validationweb">
                                  {errors.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-6">
                          <div className="form-group">
                            <button
                              // disabled={isLoading}
                              type="submit"
                              className="position-relative"
                            >
                              Submit
                              {/* {isLoading && <SpinnerLoading />} */}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14720.444743087555!2d75.886468!3d22.7241084!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd914a0060bd%3A0xbe92ef9af11413e2!2sByteCipher%20Private%20Limited!5e0!3m2!1sen!2sin!4v1711694554941!5m2!1sen!2sin"
          width={MapStyle.width}
          height={MapStyle.height}
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
          title="ByteCipher Private Limited"
        />
      </div>
      <WebFooterView />
    </>
  );
};
