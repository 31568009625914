// import React, { useCallback, useEffect, useMemo, useState } from "react";
// import CreatableSelect from "react-select/creatable";
// import { components } from "react-select";

// const CustomMultiValue = (props) => {
//   const MAX_DISPLAY = 1;
//   const { index, getValue } = props;
//   const selectedValues = getValue();

//   if (index < MAX_DISPLAY) {
//     return <components.MultiValue {...props} />;
//   } else if (index === MAX_DISPLAY) {
//     return (
//       <components.MultiValue {...props}>
//         <span style={{ marginLeft: 4, fontSize: "0.875em", color: "#999" }}>
//           {`+ ${selectedValues.length - MAX_DISPLAY} more`}
//         </span>
//       </components.MultiValue>
//     );
//   } else {
//     return null;
//   }
// };

// // Custom styles for ReactSelect
// const customStyles = {
//   option: (base, state) => ({
//     ...base,
//     backgroundColor: state.isSelected
//       ? "#d0e0fc"
//       : state.isFocused
//       ? "#f0f8ff"
//       : "#fff",
//     color: state.isSelected ? "#0a58ca" : "#333",
//     ":active": {
//       backgroundColor: state.isSelected ? "#d0e0fc" : "#f0f8ff",
//     },
//     margin: "4px 0", // Add vertical margin between list items
//     padding: "8px 12px", // Adjust padding for better spacing
//   }),
// };

// export const TechnologyDropdown = ({
//   sortedData,
//   setSortedData,
//   technologyTypes,
//   setNewTechnologyTypes,
//   Formik,
// }) => {
//   const [technologyFilter, setTechnologyFilter] = useState([]);

//   const sortSelectedToTop = useCallback((options, selectedValues) => {
//     if (!selectedValues || selectedValues.length === 0) return options;
//     const selectedValuesSet = new Set(selectedValues.map((item) => item.value));
//     const selectedOptions = options.filter(
//       (option) => option.value !== "all" && selectedValuesSet.has(option.value)
//     );
//     const unselectedOptions = options.filter(
//       (option) => option.value !== "all" && !selectedValuesSet.has(option.value)
//     );
//     const allOption = options.find((option) => option.value === "all");
//     return allOption
//       ? [allOption, ...selectedOptions, ...unselectedOptions]
//       : [...selectedOptions, ...unselectedOptions];
//   }, []);

//   // Memoize technology options
//   const technologyOptions = useMemo(() => {
//     return technologyTypes?.length > 0
//       ? [
//           { value: "all", label: "All" },
//           ...technologyTypes.map((technology) => ({
//             value: technology?._id,
//             label: technology?.title,
//           })),
//         ]
//       : []; // Default to just "All" if no technologyTypes
//   }, [technologyTypes]);

//   const mappedTechnologyIds = (data) => {
//     return data
//       ?.filter((value) => !value?.__isNew__)
//       .map((technology) => technology?.value);
//   };

//   const mappedNewOptions = (data) => {
//     return data
//       ?.filter((value) => value?.__isNew__)
//       .map((option) => ({ title: option?.value?.toLowerCase(), remark: "" }));
//   };

//   const handleChange = (selectedOptions) => {
//     const validOptions = selectedOptions.filter(
//       (option) => option?.value && option?.label
//     );
//     let optionsToSet = validOptions;
//     if (validOptions.some((option) => option.value === "all")) {
//       optionsToSet = technologyOptions?.filter(
//         (option) => option.value !== "all"
//       );
//     }

//     if (validOptions.length > 0) {
//       setTechnologyFilter(optionsToSet);
//       setSortedData(mappedTechnologyIds(optionsToSet));
//       setNewTechnologyTypes(mappedNewOptions(optionsToSet));
//       Formik?.setFieldValue(
//         "technologyDetails",
//         mappedNewOptions(optionsToSet)
//       );
//       Formik?.setFieldValue(
//         "technologyId",
//         mappedTechnologyIds(optionsToSet) || []
//       );
//     } else {
//       // Reset when no valid options selected
//       setTechnologyFilter([]);
//       setSortedData([]);
//       setNewTechnologyTypes([]);
//       Formik?.setFieldValue("technologyId", []);
//     }
//   };

//   useEffect(() => {
//     if (sortedData && sortedData.length > 0) {
//       // Find matching options based on sortedData
//       const matchingOptions = technologyOptions.filter((option) =>
//         sortedData.includes(option.value)
//       );

//       setTechnologyFilter(matchingOptions);
//     } else {
//       // Reset to initial state
//       setTechnologyFilter([]);
//     }
//   }, [sortedData, technologyOptions]);

//   const sortedOptions = useMemo(
//     () => sortSelectedToTop(technologyOptions, technologyFilter),
//     [technologyOptions, technologyFilter, sortSelectedToTop]
//   );

//   useEffect(() => {
//     setTechnologyFilter(technologyFilter);
//   }, [technologyFilter]);
//   return (
//     <CreatableSelect
//       isMulti
//       isSearchable
//       isClearable
//       className="react-select-container"
//       placeholder="Select Technology"
//       classNamePrefix="react-select"
//       onChange={handleChange}
//       value={technologyFilter}
//       options={sortedOptions}
//       components={{ MultiValue: CustomMultiValue }}
//       styles={customStyles} // Apply custom styles
//       hideSelectedOptions={false}
//     />
//   );
// };

import React, { useCallback, useEffect, useMemo, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";

const CustomMultiValue = (props) => {
  const MAX_DISPLAY = 1;
  const { index, getValue } = props;
  const selectedValues = getValue();

  if (index < MAX_DISPLAY) {
    return <components.MultiValue {...props} />;
  } else if (index === MAX_DISPLAY) {
    return (
      <components.MultiValue {...props}>
        <span style={{ marginLeft: 4, fontSize: "0.875em", color: "#999" }}>
          {`+ ${selectedValues.length - MAX_DISPLAY} more`}
        </span>
      </components.MultiValue>
    );
  } else {
    return null;
  }
};

// Custom styles for ReactSelect
const customStyles = {
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? "#d0e0fc"
      : state.isFocused
      ? "#f0f8ff"
      : "#fff",
    color: state.isSelected ? "#0a58ca" : "#333",
    ":active": {
      backgroundColor: state.isSelected ? "#d0e0fc" : "#f0f8ff",
    },
    margin: "4px 0", // Add vertical margin between list items
    padding: "8px 12px", // Adjust padding for better spacing
  }),
};

export const TechnologyDropdown = ({
  sortedData,
  setSortedData,
  technologyTypes,
  setNewTechnologyTypes,
  Formik,
}) => {
  const [technologyFilter, setTechnologyFilter] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const sortSelectedToTop = useCallback((options, selectedValues) => {
    if (!selectedValues || selectedValues.length === 0) return options;
    const selectedValuesSet = new Set(selectedValues.map((item) => item.value));
    const selectedOptions = options.filter(
      (option) => option.value !== "all" && selectedValuesSet.has(option.value)
    );
    const unselectedOptions = options.filter(
      (option) => option.value !== "all" && !selectedValuesSet.has(option.value)
    );
    const allOption = options.find((option) => option.value === "all");
    return allOption
      ? [allOption, ...selectedOptions, ...unselectedOptions]
      : [...selectedOptions, ...unselectedOptions];
  }, []);

  // Memoize technology options
  const technologyOptions = useMemo(() => {
    return technologyTypes?.length > 0
      ? [
          { value: "all", label: "All" },
          ...technologyTypes.map((technology) => ({
            value: technology?._id,
            label: technology?.title,
          })),
        ]
      : []; // Default to just "All" if no technologyTypes
  }, [technologyTypes]);

  const mappedTechnologyIds = (data) => {
    return data
      ?.filter((value) => !value?.__isNew__)
      .map((technology) => technology?.value);
  };

  const mappedNewOptions = (data) => {
    return data
      ?.filter((value) => value?.__isNew__)
      .map((option) => ({ title: option?.value?.toLowerCase(), remark: "" }));
  };

  const handleChange = (selectedOptions) => {
    const validOptions = selectedOptions.filter(
      (option) => option?.value && option?.label
    );
    let optionsToSet = validOptions;
    if (validOptions.some((option) => option.value === "all")) {
      optionsToSet = technologyOptions?.filter(
        (option) => option.value !== "all"
      );
    }

    if (validOptions.length > 0) {
      setTechnologyFilter(optionsToSet);
      setSortedData(mappedTechnologyIds(optionsToSet));
      setNewTechnologyTypes(mappedNewOptions(optionsToSet));
      Formik?.setFieldValue(
        "technologyDetails",
        mappedNewOptions(optionsToSet)
      );
      Formik?.setFieldValue(
        "technologyId",
        mappedTechnologyIds(optionsToSet) || []
      );
    } else {
      // Reset when no valid options selected
      setTechnologyFilter([]);
      setSortedData([]);
      setNewTechnologyTypes([]);
      Formik?.setFieldValue("technologyId", []);
      Formik?.setFieldValue("technologyDetails", []);
    }
  };

  const handleInputChange = (newValue) => {
    if (newValue.length <= 30) {
      setInputValue(newValue);
    }
  };

  const handleCreate = (inputValue) => {
    if (inputValue.length <= 30) {
      const newOption = { value: inputValue, label: inputValue, __isNew__: true };
      handleChange([...technologyFilter, newOption]);
    }
  };

  useEffect(() => {
    if (sortedData && sortedData.length > 0) {
      // Find matching options based on sortedData
      const matchingOptions = technologyOptions.filter((option) =>
        sortedData.includes(option.value)
      );

      setTechnologyFilter(matchingOptions);
    } else {
      // Reset to initial state
      setTechnologyFilter([]);
    }
  }, [sortedData, technologyOptions]);

  const sortedOptions = useMemo(
    () => sortSelectedToTop(technologyOptions, technologyFilter),
    [technologyOptions, technologyFilter, sortSelectedToTop]
  );

  useEffect(() => {
    setTechnologyFilter(technologyFilter);
  }, [technologyFilter]);

  return (
    <CreatableSelect
      isMulti
      isSearchable
      isClearable
      className="react-select-container"
      placeholder="Select Technology"
      classNamePrefix="react-select"
      onChange={handleChange}
      onInputChange={handleInputChange}
      onCreateOption={handleCreate}
      inputValue={inputValue}
      value={technologyFilter}
      options={sortedOptions}
      components={{ MultiValue: CustomMultiValue }}
      styles={customStyles} // Apply custom styles
      hideSelectedOptions={false}
    />
  );
};
