import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ButtonLoader, Error, Label } from "../components";
import { doResetCode } from "../store/actions/authActions";
import { SVGIMAGES } from "../assets/svgImages";
import { trimValue } from "../utils/helper";

export const ForgotPasswordView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const emailFromState = location.state?.email || "";
  const formik = useFormik({
    initialValues: {
      email: emailFromState,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .trim()
        .email("Please enter a valid email address!")
        .required("Email is required!"), // Changed to Yup.string()
    }),
    onSubmit: async (values) => {
      const { email } = values;

      try {
        setIsLoading(true);
        await dispatch(doResetCode({ email: values.email.toLowerCase() })).then(
          (res) => {
            console.log("otpres", res);
            if (res?.status === 200 && res?.data?.success) {
              localStorage.setItem("email", email);
              toastr.success("Success", res?.data?.message);
              navigate("/forgot-password-otp");
            } else {
              toastr.error("Error", res?.data?.message);
            }
          }
        );
      } catch (error) {
        toastr.error("Error", error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    },
  });
  return (
    <div className="loginBg">
      <div className="container-fluid">
        <div className="align-self-center pad-0 form-section">
          <div className="mainLoginbox">
            <form onSubmit={formik.handleSubmit}>
              <div className="loogSection">
                <img src={SVGIMAGES.Logo} alt="" />
              </div>
              <h1 className="tilteHead">Forgot Password</h1>
              <p className="paregrapSub">Enter your registered email below</p>
              <div className="form-group">
                <Label label={"Email Address"} htmlFor={"email"} required />
                <div className="possionIconInput">
                  <input
                    type="text"
                    placeholder="Enter Email"
                    className="form-control"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      formik.setFieldValue("email", trimValue(e.target.value));
                    }}
                    maxLength={100}
                  />
                  
                  <img src={SVGIMAGES.EmailIcon} alt="" />
                </div>
                {formik.touched.email && formik.errors.email && (
                  <Error error={formik.errors.email} />
                )}
              </div>

              <div className="form-group align-self-center d-flex">
                <button type="submit" className="formButton">
                  {isLoading ? <ButtonLoader /> : "Reset Password"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
