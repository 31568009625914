import { useEffect, useState } from "react";
import ReactSelect from "react-select";


export const TeamSizeDropdown = ({ Formik }) => {
  const [TeamOptions] = useState([
    { value: "1_to_5_members", label: "1 to 5 members" },
    { value: "6_to_10_members", label: "6 to 10 members" },
    { value: "10_or_more_members", label: "10 or more members" },
   
  ]);

  const [selectedTeam, setSelectedTeam] = useState(null);

  useEffect(() => {
    if (Formik.values.teamSize) {
      const matchedOption = TeamOptions?.find(option => option.label === Formik.values.teamSize);
      setSelectedTeam(matchedOption || null);
    }
  }, [Formik.values.teamSize, TeamOptions]);

  const handleChange = (selectedOption) => {
    setSelectedTeam(selectedOption);
    Formik.setFieldValue('teamSize', selectedOption ? selectedOption.label : '');
  };

  return (
   
     
      <ReactSelect
        className="react-select-container"
        classNamePrefix="react-select"
        placeholder="Enter Team Size"
        options={TeamOptions}
        value={selectedTeam}
        onChange={handleChange}
        // onBlur={() => Formik.setFieldTouched('teamSize', true)}
        isClearable
        isSearchable={false}
      />
      
   
  );
};
