import { organizationRatingActions} from "../actions/actionTypes"
const intialState=()=>({
    rating_customerCount:{},
   
})

export const ratingCustomerCountReducer=(state=intialState,action)=>{
  
    switch (action.type) {
        case organizationRatingActions.GET_RATING_CUSTOMER_COUNT:
         return {
            ...state,
            rating_customerCount :action?.payload?.data,
           
         };
        
        default:
            return state
    }
}