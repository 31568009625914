import { authActions, projectActions } from "../actions/actionTypes";

const getInitialState = () => ({
  domainTypes: [],
  platformTypes: [],
  technologyTypes: [],
  projectList: [],
  selectedProject:{},
  totalProjectPages:0,
  totalProjectItems:0,
});

export const projectReducer = (state = getInitialState(), action) => {
 
  switch (action.type) {
    case projectActions.DOMAIN_TYPES:
      return {
        ...state,
        domainTypes: action.payload?.data?.domainData,
        technologyTypes: action.payload?.data?.technologyData,
        platformTypes: action.payload?.data?.PlatformData,
      };

    case projectActions.PLATFORM_TYPES:
      return {
        ...state,
        platformTypes: action.payload,
      };

    case projectActions.TECHNOLOGY_TYPES:
      return {
        ...state,
        technologyTypes: action.payload,
      };

    case projectActions.GET_PROJECT_LIST:
      return {
        ...state,
        projectList: action?.payload?.data,
        totalProjectPages:action?.payload?.totalPages,
        totalProjectItems:action?.payload?.totalItems,
      };
      case projectActions.GET_SELECTED_PROJECT_DATA:
        return {
          ...state,
          selectedProject: action.payload,
        };

    case authActions.AUTH_LOGOUT:
      return {
        ...state,
        domainTypes: [],
        platformTypes: [],
        technologyTypes: [],
        projectList:[],
        totalProjectPages:0,
        totalProjectItems:0,
      };

    default:
      return state;
  }
};
