import { useEffect, useState } from "react";
import {
  ButtonLoader,
  DataLoader,
  HeaderView,
  ItemsPerPageDropdownTab,
  ModalLayout,
  SidebarView,
} from "../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Label, Error } from "../components";
import {
  createTechnology,
  deleteTechnology,
  getTechnologyList,
} from "../store/actions/technologyActions";
import { useDispatch, useSelector } from "react-redux";
import { RECORD_TYPES } from "../constants/Enums";
import { toastr } from "react-redux-toastr";
import Pagination from "../components/Pagination/Pagination";
import { SVGIMAGES } from "../assets/svgImages";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Tab, Tabs } from "react-bootstrap";
import { capitalizeFirstLetter, trimValue } from "../utils/helper";

export const TechnologyView = () => {
  const dispatch = useDispatch();
  const collapseSidebar = useSelector(({ auth }) => auth?.collapseSidebar);
  const technologyList = useSelector(
    ({ technology }) => technology?.technologyList
  );
  const totalItems = useSelector(({ technology }) => technology?.totalItems);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTechnologyData, setSelectedTechnologyData] = useState("");
  const [technologyId, setTechnologyId] = useState("");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [getListBy, setGetListBy] = useState("organization");
  useEffect(() => {
    const params = { pageLimit, currentPage, search, getListBy };
    if (search === "" || search?.length >= 2) {
      setIsLoading(true);
      dispatch(getTechnologyList(params)).finally(() => setIsLoading(false));
    }
  }, [search, currentPage, getListBy, pageLimit, dispatch]);

  const AddTechnologyFormik = useFormik({
    initialValues: {
      title: "",
      remark: "",
      recordType: RECORD_TYPES.TECHNOLOGY,
    },
    validationSchema: Yup.object({
      title: Yup.string()
      .trim()
      .min(2, "Technology must be at least 2 characters long")
      .required("Technology name is required!"),
      remark: Yup.string()
      .trim()
      .min(10, "Remark must be at least 10 characters long")
      .required("Remark is required!"),
    }),
    onSubmit: async (values) => {
      if (AddTechnologyFormik.isValid && AddTechnologyFormik.dirty) {
        try {
          setIsLoading(true);
          await dispatch(createTechnology(values)).then((res) => {
            if (res?.status === 200 && res?.data?.success) {
              AddTechnologyFormik.resetForm();
              const params = { pageLimit, currentPage, search, getListBy };
              dispatch(getTechnologyList(params));
              toastr.success("Success", res?.data?.message);
              setAddModal(false);
            } else {
              toastr.error("Error", res?.data?.message);
            }
          });
        } catch (error) {
          toastr.error("Error", error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }
    },
  });

  const EditTechnologyFormik = useFormik({
    initialValues: {
      id: "",
      title: "",
      remark: "",
      recordType: RECORD_TYPES.TECHNOLOGY,
    },
    validationSchema: Yup.object({
      title: Yup.string()
      .trim()
      .min(2, "Technology must be at least 2 characters long")
      .required("Technology name is required!"),
      remark: Yup.string()
      .trim()
      .min(10, "Remark must be at least 10 characters long")
      .required("Remark is required!"),
    }),
    onSubmit: async (values) => {
      if (EditTechnologyFormik.isValid && EditTechnologyFormik.dirty) {
        try {
          setIsLoading(true);
          await dispatch(createTechnology(values)).then((res) => {
            if (res?.status === 200 && res?.data?.success) {
              EditTechnologyFormik.resetForm();
              const params = { pageLimit, currentPage, search, getListBy };
              dispatch(getTechnologyList(params));
              toastr.success("Success", res?.data?.message);
              setEditModal(false);
            } else {
              toastr.error("Error", res?.data?.message);
            }
          });
        } catch (error) {
          toastr.error("Error", error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }
    },
  });
  const openEditModal = (technology) => {
    setSelectedTechnologyData(technology);
    setEditModal(true);
  };
  useEffect(() => {
    if (selectedTechnologyData && editModal) {
      EditTechnologyFormik.setValues({
        id: selectedTechnologyData?._id,
        title: selectedTechnologyData?.title,
        remark: selectedTechnologyData?.remark,
        recordType: RECORD_TYPES.TECHNOLOGY,
      });
    }
    // eslint-disable-next-line
  }, [selectedTechnologyData, editModal]);

  const technologyDelete = async () => {
    setDeleteModal(false);
    try {
      await dispatch(deleteTechnology(technologyId)).then((res) => {
        if (res?.status === 200 && res?.data?.success) {
          const params = {
            pageLimit,
            currentPage:
              technologyList?.length === 1 && currentPage > 1
                ? currentPage - 1
                : currentPage,
            search,
            getListBy,
          };
          dispatch(getTechnologyList(params));
          toastr.success("Success", res?.data?.message);
        } else {
          toastr.error("Error", res?.data?.message);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div className={collapseSidebar ? "mainBody sortingMain" : "mainBody"}>
        <HeaderView />

        <div className="wrapper">
          <SidebarView />
          <div className="main-container">
            <div className="content">
              <div className="row mb-3">
                <div className="col-xxl-12 custTabsCss">
                  <div className="row justify-content-between align-items-center mb-3">
                    <div className="col-xl-3 col-lg-4">
                      <div className="greetingsText">
                        <div className="greetingsText-heading">
                          <h3>Technology
                            <small>
                              <span
                                class="tooltip-toggleuser pro tooltip-technology"
                                aria-label="The Technologies section allows you to view your list of technologies, including the default technology created by Folio4u. You can add new technologies by clicking the plus button. These technologies can be used when adding projects, and technologies added by you will be visible only to you."
                                tabindex="0"
                              >
                                <img src={SVGIMAGES.infoIcon} alt="" />
                              </span>
                            </small>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-lg-5 d-flex">
                      {getListBy === 'organization' &&
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="button-tooltip-2">
                              Add Technology
                            </Tooltip>
                          }
                        >
                          <div className="buttons d-flex" variant="light">
                            <button
                              className="ctr-btn"
                              onClick={() => setAddModal(true)}
                            >
                              <img src={SVGIMAGES.PlusIcon} alt="" />
                            </button>
                          </div>
                        </OverlayTrigger>}
                      <input
                        type="text"
                        className="custom-input-field"
                        placeholder="Search Technology"
                        onChange={(e) => {
                          setCurrentPage(1);
                          const s = e.currentTarget?.value
                            ?.replace(/[^\w\s]|_/g, "")
                            .replace(/\s{2,}/g, " ");
                          setSearch(s);
                        }}
                        value={search}
                      />
                    </div>
                  </div>
                  {technologyList?.length > 0 &&
                  <div className="col-xl-12">
                    <ItemsPerPageDropdownTab
                      pageLimit={pageLimit}
                      setPageLimit={setPageLimit}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>}
                  <Tabs
                    defaultActiveKey="created_by_me"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    onSelect={(key) => {
                      if (key === "created_by_me") {
                        setCurrentPage(1)
                        setGetListBy("organization");
                      } else if (key === "default") {
                        setCurrentPage(1)
                        setGetListBy("admin");
                      }
                    }}
                  >
                    <Tab eventKey="created_by_me" title="Created by me">
                      {isLoading ? (
                        <DataLoader width={100} />
                      ) : (
                        <div className="row">
                          <div className="col-xxl-12">
                            {technologyList?.length > 0 ? (
                              <>
                                <div className="table-responsive">
                                  <table className="custom-table">
                                    <thead>
                                      <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Remark</th>
                                        <th scope="col">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {technologyList?.length > 0 ? (
                                        technologyList?.map((technology) => (
                                          <tr key={technology?._id}>
                                            <td
                                              onClick={() =>
                                                technology?.createdBy ===
                                                "organization" &&
                                                openEditModal(technology)
                                              }
                                            >
                                              {technology?.title}
                                            </td>
                                            <td
                                              onClick={() =>
                                                technology?.createdBy ===
                                                "organization" &&
                                                openEditModal(technology)
                                              }
                                              className="w-descriptin"
                                            >
                                              {technology?.remark}
                                            </td>
                                            <td>
                                              {technology?.createdBy ===
                                                "organization" && (
                                                  <div className="action-btn">
                                                    <button
                                                      onClick={() => {
                                                        setTechnologyId(
                                                          technology?._id
                                                        );
                                                        setDeleteModal(true);
                                                      }}
                                                    >
                                                      <img
                                                        src={SVGIMAGES.DeleteIcon}
                                                        alt=""
                                                      />
                                                    </button>
                                                  </div>
                                                )}
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <td
                                            className="text-center"
                                            colSpan={5}
                                          >
                                            No technology records found
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <div className="cusPagination">
                                  <Pagination
                                    currentPage={currentPage}
                                    totalCount={totalItems}
                                    pageSize={pageLimit}
                                    onPageChange={(page) =>
                                      setCurrentPage(page)
                                    }
                                  />
                                </div>
                              </>
                            ) : (
                              <div className="nodataFileBox">
                                <img src={SVGIMAGES.NoDataFoundImg} alt="" className="img-fluid" />
                                <h1>No Data Found</h1>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Tab>
                    <Tab eventKey="default" title="Default">
                      {isLoading ? (
                        <DataLoader width={100} />
                      ) : (
                        <div className="row">
                          <div className="col-xxl-12">
                            {technologyList?.length > 0 ? (
                              <>
                                <div className="table-responsive">
                                  <table className="custom-table">
                                    <thead>
                                      <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Remark</th>

                                      </tr>
                                    </thead>
                                    <tbody>
                                      {technologyList?.length > 0 ? (
                                        technologyList?.map((technology) => (
                                          <tr key={technology?._id}>
                                            <td
                                              onClick={() =>
                                                technology?.createdBy ===
                                                "organization" &&
                                                openEditModal(technology)
                                              }
                                              className="w-descriptin"
                                            >
                                              {technology?.title}
                                            </td>
                                            <td
                                              onClick={() =>
                                                technology?.createdBy ===
                                                "organization" &&
                                                openEditModal(technology)
                                              }
                                              className="w-descriptin"
                                            >
                                              {technology?.remark}
                                            </td>

                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <td
                                            className="text-center"
                                            colSpan={5}
                                          >
                                            No technology records found
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <div className="cusPagination">
                                  <Pagination
                                    currentPage={currentPage}
                                    totalCount={totalItems}
                                    pageSize={pageLimit}
                                    onPageChange={(page) =>
                                      setCurrentPage(page)
                                    }
                                  />
                                </div>
                              </>
                            ) : (
                              <div className="nodataFileBox">
                                <img src={SVGIMAGES.NoDataFoundImg} alt="" />
                                <h1>No Data Found</h1>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {deleteModal && (
        <ModalLayout show={deleteModal} setShow={setDeleteModal} centered>
          <img
            src={SVGIMAGES.DeleteModaleIcon}
            alt=""
            className="mainIconModal"
          />
          <h2>Delete Technology</h2>
          <p>Are you sure you want to Delete Technology?</p>
          <div className="footbutton">
            <button
              type="button"
              onClick={() => {
                setDeleteModal(false);
              }}
              className="cancelBtn"
            >
              Cancel
            </button>
            <button
              type="button"
              className="confirmBtn"
              onClick={() => {
                technologyDelete();
              }}
            >
              Confirm
            </button>
          </div>
        </ModalLayout>
      )}

      {editModal && (
        <ModalLayout
          show={editModal}
          setShow={setEditModal}
          centered
          title={"Edit Technology"}
          formik={EditTechnologyFormik}
        >
          <form
            className="row g-3"
            id="editTechnologyForm"
            onSubmit={EditTechnologyFormik.handleSubmit}
          >
            <div className="col-lg-12">
              <Label label={"Technology"} htmlFor={"TechnologyName"} required />
              <input
                type="text"
                className="custom-input-field"
                id="TechnologyName"
                placeholder="Enter Technology"
                name="title"
                value={EditTechnologyFormik.values.title}
                onChange={(e) => {
                  const capitalizedValue = capitalizeFirstLetter(e.target.value);
                  EditTechnologyFormik.setFieldValue('title', capitalizedValue);
                }}
                onBlur={(e) => {
                  EditTechnologyFormik.handleBlur(e);
                  EditTechnologyFormik.setFieldValue(
                    "title",
                    capitalizeFirstLetter(trimValue(e.target.value))
                  );
                }}
                maxLength={60}
              />

              {EditTechnologyFormik.errors.title &&
                EditTechnologyFormik.touched.title && (
                  <Error error={EditTechnologyFormik.errors.title} />
                )}
            </div>
            <div className="col-lg-12">
              <Label label={"Remark"} htmlFor={"remark"} required />
              <textarea
                type="text"
                className="custom-input-field"
                id="remark"
                name="remark"
                placeholder="Enter Remark"
                rows="5"
                value={EditTechnologyFormik.values.remark}
                onChange={(e) => {
                  const capitalizedValue = capitalizeFirstLetter(e.target.value);
                  EditTechnologyFormik.setFieldValue('remark', capitalizedValue);
                }}
                onBlur={(e) => {
                  EditTechnologyFormik.handleBlur(e);
                  EditTechnologyFormik.setFieldValue(
                    "remark",
                    capitalizeFirstLetter(trimValue(e.target.value))
                  );
                }}
                maxLength={60}
              ></textarea>
              {EditTechnologyFormik.errors.remark &&
                EditTechnologyFormik.touched.remark && (
                  <Error error={EditTechnologyFormik.errors.remark} />
                )}
            </div>
          </form>
          <div className="footbutton">
            <button
              type="button"
              onClick={() => {
                setEditModal(false);
                EditTechnologyFormik.resetForm();
              }}
              className="cancelBtn"
            >
              Cancel
            </button>
            <button type="submit" form="editTechnologyForm">
              {isLoading ? <ButtonLoader /> : "Update"}
            </button>
          </div>
        </ModalLayout>
      )}

      {addModal && (
        <ModalLayout
          show={addModal}
          setShow={setAddModal}
          centered
          title={"Add Technology"}
          formik={AddTechnologyFormik}
        >
          <form
            className="row g-3"
            id="addTechnologyForm"
            method="POST"
            onSubmit={AddTechnologyFormik.handleSubmit}
          >
            <div className="col-lg-12">
              <Label label={"Technology"} htmlFor={"TechnologyName"} required />
              <input
                type="text"
                className="custom-input-field"
                id="TechnologyName"
                placeholder="Enter Technology"
                name="title"
                value={AddTechnologyFormik.values.title}
                onChange={(e) => {
                  const capitalizedValue = capitalizeFirstLetter(e.target.value);
                  AddTechnologyFormik.setFieldValue('title', capitalizedValue);
                }}
                onBlur={(e) => {
                  AddTechnologyFormik.handleBlur(e);
                  AddTechnologyFormik.setFieldValue(
                    "title",
                    capitalizeFirstLetter(trimValue(e.target.value))
                  );
                }}
                maxLength={60}
              />

              {AddTechnologyFormik.errors.title &&
                AddTechnologyFormik.touched.title && (
                  <Error error={AddTechnologyFormik.errors.title} />
                )}
            </div>
            <div className="col-lg-12">
              <Label label={"Remark"} htmlFor={"remark"} required />
              <textarea
                type="text"
                className="custom-input-field"
                id="remark"
                name="remark"
                placeholder="Enter Remark"
                rows="5"
                value={AddTechnologyFormik.values.remark}
                onChange={(e) => {
                  const capitalizedValue = capitalizeFirstLetter(e.target.value);
                  AddTechnologyFormik.setFieldValue('remark', capitalizedValue);
                }}
                onBlur={(e) => {
                  AddTechnologyFormik.handleBlur(e);
                  AddTechnologyFormik.setFieldValue(
                    "remark",
                    capitalizeFirstLetter(trimValue(e.target.value))
                  );
                }}
                maxLength={60}
              ></textarea>
              {AddTechnologyFormik.errors.remark &&
                AddTechnologyFormik.touched.remark && (
                  <Error error={AddTechnologyFormik.errors.remark} />
                )}
            </div>
          </form>
          <div className="footbutton">
            <button
              type="button"
              onClick={() => {
                setAddModal(false);
                AddTechnologyFormik.resetForm();
              }}
              className="cancelBtn"
            >
              Cancel
            </button>
            <button type="submit" form="addTechnologyForm">
              {isLoading ? <ButtonLoader /> : "Add"}
            </button>
          </div>
        </ModalLayout>
      )}
    </>
  );
};
