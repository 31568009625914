import React from "react";
import {
  ProgressBar,
  RotatingLines,
  ThreeDots,
} from "react-loader-spinner";

export const ButtonLoader = ({ color, width }) => {
  return (
    <>
      <RotatingLines
        strokeColor={color ? color : "white"}
        strokeWidth="5"
        animationDuration="0.75"
        width={width ? width : "25"}
        visible={true}
      />
    </>
  );
};

export const DataLoader = ({ color, width, height }) => {
  return (
    <div className="d-flex justify-content-center align-items-center ">
      <ThreeDots
        height={height ? height : "25"}
        width={width ? width : "50"}
        radius="9"
        color={color ? color : "#f9896b"}
        ariaLabel="three-dots-loading"
        wrapperStyle={{ marginTop: 200 }}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );
};

export const ModalDataLoader = () => {
  return (
    <div className="d-flex justify-content-center">
      <ProgressBar
        height="60"
        width="60"
        ariaLabel="progress-bar-loading"
        wrapperStyle={{}}
        wrapperClass="progress-bar-wrapper"
        borderColor="#19be6f"
        barColor="#19be6f"
      />
    </div>
  );
};

export const ImageLoader = ({ color, width, height }) => {
  return (
    <div className="d-flex justify-content-center align-items-center ">
      <ThreeDots
        height={height ? height : "25"}
        width={width ? width : "50"}
        radius="9"
        color={color ? color : "#f9896b"}
        ariaLabel="three-dots-loading"
        wrapperClassName=""
        visible={true}
      />
    </div>
  );
};
