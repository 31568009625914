import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  HeaderView,
  Label,
  SidebarView,
  ButtonLoader,
  Switch,
  ModalLayout,
  DataLoader,
  ItemsPerPageDropdown,
} from "../components";
import { SVGIMAGES } from "../assets/svgImages";
import { toastr } from "react-redux-toastr";
import {
  deletePortfolio,
  getPortfolioList,
  updatePortfolio,
} from "../store/actions/sharedPortfolioActions";
import { useSelector } from "react-redux";
import { usePortfolioExpiration } from "../components/Hooks/usePortfolioExpiration";
import { formatRemainingTime } from "../utils/helper";
import Pagination from "../components/Pagination/Pagination";
import { IMAGES } from "../assets/images";

export const SharedPortfolioView = () => {
  const [copycus, setCopycus] = useState(false);
  const dispatch = useDispatch();
  const collapseSidebar = useSelector(({ auth }) => auth?.collapseSidebar);
  const portFolioList = useSelector(
    ({ portfolio }) => portfolio?.portFolioList
  );

  // console.log("portFolioList", portFolioList);
  const totalItems = useSelector(({ portfolio }) => portfolio?.totalItems);
  const [isLoading, setIsLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [confirmModal, setConfimModal] = useState(false);
  const [portFolioId, setPortFolioId] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [portFolioDetails, setPortFolioDetails] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [copiedLinkId, setCopiedLinkId] = useState(null);
  useEffect(() => {
    const params = {
      pageLimit,
      currentPage,
    };
    setIsLoading(true);
    dispatch(getPortfolioList(params)).finally(() => setIsLoading(false));
  }, [dispatch, currentPage, pageLimit]);

  const remainingTimes = usePortfolioExpiration(portFolioList);




  const handleStatusChange = async (id, status) => {
    setConfimModal(false);
    setEditModal(false);

    try {
      const params = {
        id: id || portFolioId,
        status: status === "active" ? "inactive" : "active",
      };

      await dispatch(updatePortfolio(params)).then((response) => {
        if (response?.status === 200 && response?.data?.success) {
          toastr.success("Success", response?.data?.message);
          const params = {
            pageLimit,
            currentPage,
          };
          dispatch(getPortfolioList(params));
        } else {
          toastr.error("Error", response?.data?.message);
        }
      });
    } catch (error) {
      toastr.error("Error", "Failed to update portfolio status");
    }
  };

  const portFolioDelete = async () => {
    setDeleteLoading(true);
    try {
      await dispatch(deletePortfolio(portFolioId)).then((res) => {
        if (res?.status === 200 && res?.data?.success) {
          const params = {
            pageLimit,
            currentPage:
              portFolioList?.length === 1 && currentPage > 1
                ? currentPage - 1
                : currentPage,
          };
          dispatch(getPortfolioList(params));
          toastr.success("Success", res?.data?.message);
        } else {
          toastr.error("Error", res?.data?.message);
        }
      });
    } catch (err) {
      console.log("error", err);
    } finally {
      setPortFolioId("");
      setDeleteLoading(false);
      setDeleteModal(false);
    }
  };


  const handleCopyIconClick = (id, link) => {
    setCopiedLinkId(id);
    navigator.clipboard.writeText(link).then(() => {
      toastr.success("Success", "Link copied to clipboard");
    }).catch((err) => {
      toastr.error("Error", "Failed to copy link");
    });
  };

  return (
    <>
      <div className={collapseSidebar ? "mainBody sortingMain" : "mainBody"}>
        <HeaderView />

        <div className="wrapper">
          <SidebarView />
          <div className="main-container">
            <div className="content">
              <div className="row mb-3">
                <div className="col-xxl-12 custTabsCss">
                  <div className="row justify-content-between align-items-center ">
                    <div className="col-xl-3 col-lg-4">
                      <div className="greetingsText">
                        <div className="greetingsText-heading">
                          <h3>Shared Portfolio
                            <small>
                              <span
                                class="tooltip-toggleuser pro tooltip-shared"
                                aria-label="The Shared Portfolio section allows you to view your list of shared portfolio links created in the Folio4u app. In this section, you can manage the links by activating, deactivating, or deleting them. You can also see the expiration time of each link and track the count of visits to each link."
                                tabindex="0"
                              >
                                <img src={SVGIMAGES.infoIcon} alt="" />
                              </span>
                            </small>
                          </h3>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-xl-4 col-lg-5 d-flex">
                      <input
                        type="text"
                        className="custom-input-field"
                        placeholder="Search Link"
                      />
                    </div> */}
                  </div>
                  {portFolioList?.length > 0 &&
                  <div className="col-xl-3 col-lg-4 mb-3">
                    <ItemsPerPageDropdown
                      pageLimit={pageLimit}
                      setPageLimit={setPageLimit}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>}

                  <div className="row">
                    {isLoading ? (
                      <DataLoader width={100} />
                    ) : (
                      <div className="col-xxl-12">
                        {portFolioList?.length > 0 ? (
                          <>
                            <div className="table-responsive">
                              <table className="custom-table">
                                <thead>
                                  <tr>
                                    <th scope="col">Link</th>
                                    <th scope="col">Expiry Time</th>
                                    <th scope="col" className="text-center">
                                      View Count
                                    </th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {portFolioList?.length > 0 ? (
                                    portFolioList?.map((portfolio) => (
                                      <tr>
                                        <td className="sharepagetable">
                                          <span className="w-descriptincus"  onClick={() => {
                                            setPortFolioDetails(portfolio);
                                            setEditModal(true);
                                          }}>
                                            {portfolio?.exploreLink}
                                            </span>
                                            <img 
                                             src={copiedLinkId === portfolio?._id ? SVGIMAGES.coppyGreenIcon : SVGIMAGES.coppyIcon} 
                                              alt="" 
                                              onClick={() => handleCopyIconClick(portfolio?._id, portfolio?.exploreLink)} 
                                            /> 
                                        </td>
                                        <td>
                                          {(portfolio?.status === "expired" || formatRemainingTime(
                                                remainingTimes[portfolio._id]) === 0 )
                                               ? (
                                            <span className="expierdBox_section">
                                              {formatRemainingTime(
                                                remainingTimes[portfolio._id]
                                              )}
                                            </span>
                                          ) : (
                                            <span className="activeBox_section">
                                              {formatRemainingTime(
                                                remainingTimes[portfolio._id]
                                              )}
                                            </span>
                                          )}
                                        </td>
                                        <td className="text-center">
                                          <img src={IMAGES.EyeIcon} alt="" /> &nbsp;
                                          {portfolio?.linkHitCount}
                                        </td>
                                        <td>
                                          {(portfolio?.status === "expired" ||  formatRemainingTime(
                                                remainingTimes[portfolio._id]) === 0 ) ? (
                                            <div className="action-btn">
                                              <button
                                                onClick={() => {
                                                  setPortFolioId(
                                                    portfolio?._id
                                                  );
                                                  setDeleteModal(true);
                                                }}
                                              >
                                                <img
                                                  src={SVGIMAGES.DeleteIcon}
                                                  alt=""
                                                />
                                              </button>
                                            </div>
                                          ) : (
                                            <Switch
                                              id={portfolio?._id}
                                              checked={
                                                portfolio?.status === "active"
                                              }
                                              onChange={() => {
                                                setPortFolioId(portfolio?._id);
                                                setCurrentStatus(
                                                  portfolio.status
                                                );

                                                if (
                                                  portfolio?.status === "active"
                                                ) {
                                                  setConfimModal(true);
                                                } else {
                                                  handleStatusChange(
                                                    portfolio?._id,
                                                    portfolio?.status
                                                  );
                                                }
                                              }}
                                            />
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td className="text-center" colSpan={5}>
                                        No shared portfolio records found
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            <div className="cusPagination">
                              <Pagination
                                currentPage={currentPage}
                                totalCount={totalItems}
                                pageSize={pageLimit}
                                onPageChange={(page) => setCurrentPage(page)}
                              />
                            </div>
                          </>
                        ) : (
                          <div className="nodataFileBox">
                            <img src={SVGIMAGES.NoDataFoundImg} alt="" className="img-fluid" />
                            <h1>No Data Found</h1>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {editModal && (
        <ModalLayout
          show={editModal}
          setShow={setEditModal}
          centered
          title={"View Detail"}
        >
          <form className="row g-3">
            <div className="col-lg-12">
              <Label label={"Link"} />
              <p className="w-100 text-start">
                {portFolioDetails?.exploreLink} &nbsp;
                <img 
                  src={copiedLinkId === portFolioDetails?._id ? SVGIMAGES.coppyGreenIcon : SVGIMAGES.coppyIcon} 
                  alt="" 
                  onClick={() => handleCopyIconClick(portFolioDetails?._id, portFolioDetails?.exploreLink)} 
                /> 
              </p>
            </div>
            <div className="col-lg-12">
              <Label label={"Expiry Time"} />
              <p className="w-100 text-start">
                {formatRemainingTime(remainingTimes[portFolioDetails?._id])}
              </p>
            </div>
            <div className="col-lg-12">
              <Label label={"View Count"} />
              <p className="w-100 text-start">
              <img src={IMAGES.EyeIcon} alt="" /> &nbsp;
                {portFolioDetails?.linkHitCount}
              </p>
            </div>
            {portFolioDetails?.status !== "expired" && (
              <div className="col-lg-12">
                <Label label={"Action"} />
                <p className="w-100 d-flex align-items-left mt-2">
                  {/* <Switch className="w-100 ml-0" /> */}
                  <Switch
                    id={portFolioDetails?._id}
                    checked={portFolioDetails?.status === "active"}
                    onChange={() => {
                      setPortFolioId(portFolioDetails?._id);
                      setCurrentStatus(portFolioDetails.status);

                      if (portFolioDetails?.status === "active") {
                        setConfimModal(true);
                      } else {
                        handleStatusChange(
                          portFolioDetails?._id,
                          portFolioDetails?.status
                        );
                      }
                    }}
                  />
                </p>
              </div>
            )}
          </form>
        </ModalLayout>
      )}

      {confirmModal && (
        <ModalLayout show={confirmModal} setShow={setConfimModal} centered>
          <img
            src={
              currentStatus
                ? SVGIMAGES.DeactivateProject
                : SVGIMAGES.ActivateProject
            }
            alt=""
            className="mainIconModal"
          />
          <h2>{currentStatus ? "Deactivate" : "Activate"} Link</h2>
          <p className="w-100">Are you sure you want to deactivate this link</p>
          <div className="footbutton">
            <button
              type="button"
              onClick={() => {
                setConfimModal(false);
              }}
              className="cancelBtn"
            >
              Cancel
            </button>
            <button
              type="button"
              className="confirmBtn"
              onClick={() => {
                handleStatusChange(portFolioId, currentStatus);
              }}
            >
              Confirm
            </button>
          </div>
        </ModalLayout>
      )}

      {deleteModal && (
        <ModalLayout show={deleteModal} setShow={setDeleteModal} centered>
          <img
            src={SVGIMAGES.DeleteModaleIcon}
            alt=""
            className="mainIconModal"
          />
          <h2>Delete Link</h2>
          <p>Are you sure you want to Delete this link?</p>
          <div className="footbutton">
            <button
              type="button"
              onClick={() => {
                setDeleteModal(false);
              }}
              className="cancelBtn"
            >
              Cancel
            </button>
            <button
              type="button"
              className="confirmBtn"
              disabled={deleteLoading}
              onClick={() => {
                portFolioDelete();
              }}
            >
              {deleteLoading ? <ButtonLoader /> : "Confirm"}
            </button>
          </div>
        </ModalLayout>
      )}
    </>
  );
};
