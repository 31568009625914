import React, { useState } from "react";
import { Navigation } from "./routes";
import ReduxToastr from "react-redux-toastr";




function App() {
  // const [src,setSrc]=useState();
  // useEffect(() => {
  //   const decryptCredentials = async (encryptedCredentials) => {
  //     try {
  //       const decryptedCredentials = {};
  //       for (const [key, value] of Object.entries(encryptedCredentials)) {
  //         decryptedCredentials[key] = await sharedCryptoLib?.decrypt(value);
  //       }
  //       return decryptedCredentials;
  //     } catch (error) {
  //       console.error("Error decrypting credentials:", error);
  //       throw error;
  //     }
  //   };

  //   const fetchAndDecryptCredentials = async () => {
  //     try {
  //       const response = await dispatch(getAwsCredentials({ keyId }));
  //       if (response?.status === 200 && response?.data?.success) {
  //         const decryptedCredentials = await decryptCredentials(response?.data?.data);
  //         dispatch(sendAWSCredentials(decryptedCredentials));
  //       }
  //     } catch (error) {
  //       console.error("Error fetching or decrypting credentials:", error);
  //     }
  //   };

  //   fetchAndDecryptCredentials();
  // }, [dispatch, keyId]);
  return (
    <div>
      <Navigation />
      <ReduxToastr
        timeOut={4000}
        newestOnTop={true}
        preventDuplicates
        position="top-right"
        getState={(state) => state.toastr}
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar={false}
        closeOnToastrClick={true}
        className="customToaster"
     />
      
    </div>
  );
}

export default App;
