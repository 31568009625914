import { useState, useEffect } from 'react';

export const useExpirationCheck = (linkExpiredTime, projectIds) => {
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);

  useEffect(() => {
    if (!projectIds || projectIds.length === 0) return;

    const checkExpiration = () => {
      const currentTime = new Date().getTime();
      const timeRemaining = linkExpiredTime - currentTime;

      if (timeRemaining <= 0) {
        setIsLinkExpired(true);
        setRemainingTime(0);
      } else {
        setIsLinkExpired(false);
        setRemainingTime(timeRemaining);
      }
    };

    // Check immediately in case the link is already expired
    checkExpiration();

    // Set up a timer to check expiration and update the remaining time periodically
    const intervalId = setInterval(checkExpiration, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [linkExpiredTime, projectIds]);

  return { isLinkExpired, remainingTime };
};



  //old
  // useEffect(() => {
  //   if (!exportProjects?.projectIds || exportProjects?.projectIds?.length === 0)
  //     return;

  //   const checkExpiration = () => {
  //     const currentTime = new Date().getTime();
  //     const linkExpiredTime = exportProjects?.linkExpiredTime;
  //     if (currentTime >= linkExpiredTime) {
  //       setIsLinkExpired(true);
  //     }
  //   };

  //   // Check immediately in case the link is already expired
  //   checkExpiration();

  //   // Set up a timer to check expiration periodically
  //   const intervalId = setInterval(checkExpiration, 1000);

  //   // Cleanup interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, [exportProjects?.linkExpiredTime, exportProjects?.projectIds]);