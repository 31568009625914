import React from "react";
import { WebHeaderView } from "./web-header";
import { WebFooterView } from "./web-footer";
import { useNavigate } from "react-router-dom";
import { IMAGES } from "../../assets/images";


export const AboutUsView = () => {
  const navigate = useNavigate();
  return (
    <>
      <WebHeaderView />
      <div className="cusSlider aboutusSlide">
        <div className="container-fluid">
          <ul>
            <li><button onClick={()=> navigate("/")}>Home</button></li>
            <li>|</li>
            <li>About Us</li>
          </ul>
          <h1>About Us</h1>
        </div>
      </div>

      <div className="aboutSectionbg">
        <div className="container-fluid"> 
          <div className="row align-items-center">
              <div className="col-md-6">
                <img src={IMAGES.AboutUs} alt="" className="img-fluid  p-5" />
              </div>
              <div className="col-md-6">
                <h2>About <span>Folio4u</span></h2>
                <h6>
                  Folio4u, a product of ByteCipher Private Limited, is a comprehensive portfolio management and presentation tool. We cater to professionals, freelancers, and businesses across a variety of industries, including IT, design, and more. Our platform enables users to create, manage, and showcase their projects with ease, offering a seamless way to present their professional work in a visually appealing manner.
                </h6>
                <h5>
                  <img src={IMAGES.MissionIcon} alt="" />
                  At Folio4u, our mission is to empower individuals and businesses to effectively communicate their skills and achievements. We are committed to providing a user-friendly and efficient tool that meets the diverse needs of our users, helping them to stand out in their respective fields.
                </h5> 
                <h5>
                  <img src={IMAGES.TeamIcon} alt="" />
                  Our team is dedicated to continuous improvement and innovation, ensuring that Folio4u remains a leading solution in the market. We value our users and welcome feedback to further enhance our services.
                </h5> 
                <p>For more information, visit our website at <button onClick={()=> navigate('/')}>https://folio4u.com</button></p>
              </div>
          </div>          
          
        </div>
      </div>      

      <WebFooterView />
    </>
  );
};
