import { ProgressBar } from "react-bootstrap";

export const UplaodProgress = ({ progress, ...props }) => {
    return (
        <ProgressBar
            max={100}
            min={0}
            striped
            variant={progress > 99 ? "success" : ""}
            label={progress !== 100 ? `${(progress !== 'NaN' || progress !== undefined) ? progress : 0}%` : `Done`}
            now={progress}
            className="progressBar"
        />
    );
};
