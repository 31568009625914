import { Modal, ModalBody } from "react-bootstrap"
import { SVGIMAGES } from "../../assets/svgImages"

export const ModalLayout = ({ className, show, setShow, children, title, formik = false, closeButton = true, ...props }) => {

    return (
        <Modal
            className={`common_modal align-content-center ${className}`}
            show={show}
            props
        >
            {props?.message && <>
                <h6 className="text-center upgradewarnigMsg">{props?.message}</h6>
            </>}
            <Modal.Header>
                {title && <h5 className={`modal-title ${props?.titleClass} `}>{title}</h5>}

                <button
                    type="button"
                    className={`btn-close`}
                    aria-label="Close"
                    onClick={() => {
                        setShow && setShow(false);
                        formik && formik?.resetForm();
                        props?.onClose && props?.onClose()
                    }}
                >
                    {closeButton && <img src={SVGIMAGES.CloseButton} alt="Close Button" />}
                </button>
            </Modal.Header>
            <ModalBody>
                {children}
            </ModalBody>
        </Modal>
    )
}